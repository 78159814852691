import { useEffect, useRef, useState } from "react";
import {
  useGetCountriesForBusiness,
  useGetStoreLocations,
} from "../../../hooks/api/queries/useBusiness";
import { useLocation, useParams } from "react-router";
import { cleanObject } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { setMenuId } from "../../../store/modules/menuCart";

const useLocationchange = () => {
  const { pathname } = useLocation();
  const splitedPath = pathname?.split("/");
  const { id } = useParams();
  const dispatch = useDispatch();
  const businessId = id?.split("-")?.[0];
  const { menuId } = useSelector((state) => state.menuCartReducer);
  const [selectedCountry, setSelectedCountry] = useState(
    splitedPath?.[3] || null,
  );
  const [selectedState, setSelectedState] = useState(splitedPath?.[4] || null);
  const [selectedCity, setSelectedCity] = useState(splitedPath?.[5] || null);
  const [queryParams, setQueryParams] = useState(
    cleanObject({
      id: businessId,
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
    }),
  );
  const stateInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const { data: countries, isLoading: isLoadingCountries } =
    useGetCountriesForBusiness(businessId);
  const { data: storeLocations, isLoading: isLoadingStoreLocations } =
    useGetStoreLocations(queryParams);

  useEffect(() => {
    if (storeLocations && !menuId)
      dispatch(setMenuId(storeLocations?.default_menu_data?.id));
  }, [storeLocations]);

  useEffect(() => {
    if (countries?.length === 1 && !selectedCountry) {
      setSelectedCountry(countries?.[0]?.iso2);
      handleFilterByLocation({
        key: "country",
        value: countries?.[0]?.iso2,
        clearState: true,
        clearCity: true,
      });
    }
  }, [countries]);

  const handleFilterByLocation = ({
    key,
    value,
    clearState = false,
    clearCity = false,
  }) => {
    const newQueryParams = { ...queryParams };
    delete newQueryParams.page;
    setQueryParams((prev) =>
      cleanObject({
        ...prev,
        state: clearState ? undefined : queryParams?.state,
        city: clearCity ? undefined : queryParams?.city,
        [key]: value,
      }),
    );
  };

  return {
    countries: countries || [],
    isLoadingCountries,
    selectedCountry,
    selectedState,
    selectedCity,
    isLoadingStoreLocations,
    storeLocations: storeLocations?.menus,
    location: storeLocations?.location,
    stateInputRef,
    cityInputRef,
    businessId,
    setSelectedCity,
    setSelectedState,
    setSelectedCountry,
    handleFilterByLocation,
  };
};

export default useLocationchange;
