import styles from "./ProductCard.module.scss";
import { findPricingViaIdHelper, formatCurrency } from "../../../../helpers";
import primaryComponents from "../../../primaryComponents";
import { useState } from "react";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { ProductCardEmptyIcon } from "../../../../assets/svgIcons";
import useItemTracking from "../ItemTrackingSelection/useItemTracking";
import ItemTrackingSelection from "../ItemTrackingSelection";
import secondaryComponents from "../..";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

const ProductCard = ({
  product,
  cartDetails = undefined,
  handleAddToCart,
  handleReduceCartItem,
  pricingType,
}) => {
  const { store_currency } = useStoreCurrency();
  const [isService] = useState(product?.is_service);
  const [isComposite] = useState(product?.is_composite);
  const productTypeName = isService
    ? "store_service_properties"
    : "store_product_properties";
  const pricingDetails = findPricingViaIdHelper(
    pricingType,
    product?.[productTypeName]?.[0]?.pricings,
  );
  const hasItemTracking = product?.[productTypeName]?.[0]?.has_inventory_items;
  const { productProperties, setProductProperties, closeModal } =
    useItemTracking();

  return (
    <>
      {pricingDetails ? (
        <div className={styles.productCard}>
          <p className={globalStyles.prodouctTypeTag}>
            {isComposite ? "Composite" : "Single Product"}
          </p>

          <div
            className={`${styles.productCard__top}  ${
              cartDetails !== undefined ? "active" : ""
            }`}
          >
            <div className={styles.left}>
              <div className={styles.productImage}>
                {product?.media_files?.[0]?.url ? (
                  <img src={product?.media_files?.[0]?.url} alt="" />
                ) : (
                  <ProductCardEmptyIcon />
                )}
              </div>
              <div style={{ width: "100%" }}>
                <p className={`dark--text mb-1 ${styles.productName}`}>
                  {product?.name}
                </p>

                {/* desktop view */}
                <div>
                  <div className={styles.middle}>
                    <p className="d-flex align-items-center">
                      <span
                        className="error mr-1"
                        style={{
                          width: "5px",
                          height: "5px",
                          borderRadius: "5px",
                        }}
                      ></span>
                      <span className="font-weight-semibold purple--text">
                        {formatCurrency({
                          value: (
                            pricingDetails?.price -
                            (pricingDetails?.price *
                              pricingDetails?.discount_rate) /
                              100
                          ).toFixed(2),
                          currencyCode: store_currency,
                          decimal: 0,
                        })}
                      </span>
                    </p>

                    {isService ? (
                      <p className={styles.stock}>Services</p>
                    ) : isComposite &&
                      product?.composite_product_type === "ON_DEMAND" ? (
                      <p className={styles.stock}>On Demand</p>
                    ) : (
                      <p
                        className={`${styles.stock} ${
                          product?.[productTypeName]?.[0]?.stock_quantity <=
                            product?.[productTypeName]?.[0]
                              ?.minimum_stock_quantity && styles.lowQuantity
                        }`}
                      >
                        <span>
                          {product?.[productTypeName]?.[0]?.stock_quantity} in
                          stock
                        </span>
                      </p>
                    )}

                    <p className="dark--text">
                      <span>Expiry: </span>{" "}
                      <span className="font-weight-semibold">
                        {product?.[productTypeName]?.[0]?.expiry_date || "N/A"}
                      </span>
                    </p>
                  </div>

                  {pricingDetails?.discount_rate > 0 ? (
                    <span
                      className={"error--text"}
                      style={{ fontSize: "10px" }}
                    >
                      <span style={{ textDecoration: "line-through" }}>
                        {formatCurrency({
                          value: pricingDetails?.price,
                          currencyCode: store_currency,
                        })}
                      </span>{" "}
                      {pricingDetails?.discount_rate}% OFF
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            {product?.[productTypeName]?.[0]?.stock_quantity > 0 ||
            product?.[productTypeName]?.[0]?.stock_quantity === undefined ||
            (isComposite && product?.composite_product_type === "ON_DEMAND") ? (
              <div className={styles.right}>
                {cartDetails !== undefined ? (
                  <div
                    className={styles.product__actions}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        if (!hasItemTracking)
                          return handleReduceCartItem({
                            quantity: Number(cartDetails?.quantity) - 1,
                            product,
                          });
                        setProductProperties({
                          product,
                          store_properties: product?.[productTypeName]?.[0],
                        });
                      }}
                      className={styles.decrement}
                    >
                      -
                    </button>

                    <primaryComponents.CartInputField
                      defaultValue={cartDetails?.quantity || 0}
                      updateCart={(val) => {
                        handleAddToCart({ quantity: val, product });
                      }}
                      disabled={hasItemTracking}
                      isProductCard={true}
                    />

                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        if (!hasItemTracking)
                          return handleAddToCart({
                            quantity: Number(cartDetails?.quantity) + 1,
                            product,
                          });
                        setProductProperties({
                          product,
                          store_properties: product?.[productTypeName]?.[0],
                        });
                      }}
                      className={styles.increment}
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <primaryComponents.Button
                    classNames={`btn btn--primary ${styles.addToCartBtn}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!hasItemTracking)
                        return handleAddToCart({
                          quantity:
                            product?.[productTypeName]?.[0]?.stock_quantity >
                              0 &&
                            product?.[productTypeName]?.[0]?.stock_quantity < 1
                              ? product?.[productTypeName]?.[0]?.stock_quantity
                              : 1,
                          product,
                        });

                      setProductProperties({
                        product,
                        store_properties: product?.[productTypeName]?.[0],
                      });
                    }}
                  >
                    Add to cart
                  </primaryComponents.Button>
                )}
              </div>
            ) : (
              <div className={styles.right}></div>
            )}
          </div>
        </div>
      ) : null}

      <secondaryComponents.Modal
        isActive={productProperties !== null}
        width={550}
        closeModal={closeModal}
      >
        <ItemTrackingSelection
          closeModal={closeModal}
          productProperties={productProperties}
          cartDetails={cartDetails}
          handleAddToCart={handleAddToCart}
          handleReduceCartItem={handleReduceCartItem}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default ProductCard;
