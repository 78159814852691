import styles from "./ProductGridVariantCard.module.scss";
import sharedStyles from "../ProductGridCard/ProductGridCard.module.scss";
import {
  findPricingViaIdHelper,
  formatCurrency,
  truncateString,
} from "../../../../helpers";
import primaryComponents from "../../../primaryComponents";
import React, { useState } from "react";
import secondaryComponents from "../..";
import { motion, AnimatePresence } from "framer-motion";
import { ProductCardEmptyIcon, VariantIcon } from "../../../../assets/svgIcons";
import ItemTrackingSelection from "../ItemTrackingSelection";
import useItemTracking from "../ItemTrackingSelection/useItemTracking";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

const ProductGridVariantCard = ({
  product,
  cartItems = undefined,
  handleAddToCart,
  handleReduceCartItem,
  pricingType,
}) => {
  const { store_currency } = useStoreCurrency();
  const [variantOpened, setVariantOpened] = useState(false);
  const getCartItemsViaVariant = (variantId) => {
    const fountCartItem = cartItems.find(
      (item) => `${item?.id}` === `${product?.id}${variantId}`,
    );
    return fountCartItem;
  };
  const getPricingDetails = (variantPricings) => {
    const pricingDetails = findPricingViaIdHelper(pricingType, variantPricings);
    return pricingDetails;
  };
  const isHasPricingForSelectedPricingType = () => {
    for (let index = 0; index < product?.variants?.length; index++) {
      const pricingDetails = getPricingDetails(
        product?.variants?.[index]?.store_variant_properties?.[0]?.pricings,
      );
      if (pricingDetails) return true;
    }
    return false;
  };
  const { productProperties, setProductProperties, closeModal } =
    useItemTracking();

  return (
    <>
      {isHasPricingForSelectedPricingType() ? (
        <secondaryComponents.ToolTipV2 tipText={"Click to select variant"}>
          <div
            className={sharedStyles.productGridCard}
            onClick={() => setVariantOpened(true)}
          >
            <AnimatePresence>
              <motion.div
                className={sharedStyles.productImage}
                initial={{ y: -50 }}
                animate={{
                  y: 0,
                  transition: { duration: 0.1 },
                }}
                exit={{ y: -150, transition: { duration: 0.2 } }}
              >
                {product?.media_files?.[0]?.url ? (
                  <img src={product?.media_files?.[0]?.url} alt="" />
                ) : (
                  <ProductCardEmptyIcon />
                )}
                <div className={sharedStyles.variantIcon}>
                  <VariantIcon />
                </div>
              </motion.div>
            </AnimatePresence>

            <p className={"small-text-size dark--text mb-0"}>
              {truncateString(product?.name, 40)}
            </p>
            <div className="mt-auto d-flex flex-column" style={{ gap: "8px" }}>
              <div className={sharedStyles.rowItem}>
                <p className={sharedStyles.stock}>
                  {product?.variants?.reduce(
                    (partialSum, a) =>
                      partialSum +
                      a?.store_variant_properties?.[0]?.stock_quantity,
                    0,
                  )}
                </p>

                <p className="d-flex align-items-center">
                  <span
                    className="error mr-1"
                    style={{
                      width: "4px",
                      height: "4px",
                      borderRadius: "4px",
                    }}
                  ></span>
                  <span className="font-weight-semibold dark--text">
                    {product?.variants?.length}{" "}
                    {product?.variants?.length > 1 ? "Variants" : "Variant"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </secondaryComponents.ToolTipV2>
      ) : null}

      <secondaryComponents.Modal
        isActive={variantOpened}
        closeModal={() => setVariantOpened(false)}
        width="750px"
      >
        <div className={styles.variantSection}>
          <div className={styles.variantSection__header}>
            <h6 className={`${styles.variantHeader} mb-0`}>
              Variants{" "}
              <span className="font-weight-normal">
                ({truncateString(product?.name, 150)})
              </span>
            </h6>

            <primaryComponents.Button
              classNames={"ml-auto btn btn--success smallBtn"}
              onClick={() => setVariantOpened(false)}
            >
              Continue shopping
            </primaryComponents.Button>
          </div>

          {/* body section */}
          <div className={styles.variantBody}>
            {product?.variants?.map((item, index) => {
              const pricingDetails = getPricingDetails(
                item?.store_variant_properties?.[0]?.pricings,
              );
              const hasItemTracking =
                item?.store_variant_properties?.[0]?.has_inventory_items;

              return (
                <React.Fragment key={index}>
                  {pricingDetails ? (
                    <div className={styles.productSubCard}>
                      <div
                        className={`${styles.left} d-flex flex-row`}
                        style={{ gap: "10px" }}
                      >
                        {item?.image_url ? (
                          <div className={styles.variantImg}>
                            <img src={item?.image_url} alt="variant" />
                          </div>
                        ) : null}
                        <div>
                          <p>
                            <span>{item?.option}: </span>
                            <span className="dark--text">{item?.value}</span>
                          </p>
                          {/* destop view */}
                          <div>
                            <div className={styles.middle}>
                              <p className="d-flex align-items-center">
                                <span
                                  className="error mr-1"
                                  style={{
                                    width: "5px",
                                    height: "5px",
                                    borderRadius: "5px",
                                  }}
                                ></span>
                                <span className="font-weight-semibold purple--text">
                                  {formatCurrency({
                                    value: (
                                      pricingDetails?.price -
                                      (pricingDetails?.price *
                                        pricingDetails?.discount_rate) /
                                        100
                                    ).toFixed(2),
                                    currencyCode: store_currency,
                                    decimal: 0,
                                  })}
                                </span>
                              </p>
                              <p
                                className={`${styles.stock} ${
                                  item?.store_variant_properties?.[0]
                                    ?.stock_quantity <=
                                    product?.store_variant_properties?.[0]
                                      ?.minimum_stock_quantity ||
                                  (item?.store_variant_properties?.[0]
                                    ?.stock_quantity <= 0 &&
                                    styles.lowQuantity)
                                }`}
                              >
                                <span>
                                  {
                                    item?.store_variant_properties?.[0]
                                      ?.stock_quantity
                                  }{" "}
                                  in stock
                                </span>
                              </p>

                              <p className="dark--text">
                                <span className="small-text-size">
                                  Expiry:{" "}
                                </span>{" "}
                                <span className="small-text-size font-weight-semibold">
                                  {item?.store_variant_properties?.[0]
                                    ?.expiry_date || "N/A"}
                                </span>
                              </p>
                            </div>
                            {pricingDetails?.discount_rate > 0 ? (
                              <span
                                className={"error--text"}
                                style={{ fontSize: "10px" }}
                              >
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  {formatCurrency({
                                    value: pricingDetails?.price,
                                    currencyCode: store_currency,
                                    decimal: 0,
                                  })}
                                </span>{" "}
                                {pricingDetails?.discount_rate}% OFF
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {item?.store_variant_properties?.[0]?.stock_quantity >
                      0 ? (
                        <div className={styles.right}>
                          {getCartItemsViaVariant(item?.id) !== undefined ? (
                            <div className={styles.product__actions}>
                              <primaryComponents.Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (!hasItemTracking)
                                    return handleReduceCartItem({
                                      product,
                                      quantity:
                                        Number(
                                          getCartItemsViaVariant(item?.id)
                                            ?.quantity,
                                        ) - 1,
                                      variant: item,
                                    });
                                  setProductProperties({
                                    product,
                                    variant: item,
                                    store_properties:
                                      item?.store_variant_properties?.[0],
                                  });
                                }}
                                classNames={"btn btn--outline"}
                                style={{
                                  width: "calc(33.3% - 5.33px)",
                                  height: "40px",
                                }}
                              >
                                -
                              </primaryComponents.Button>

                              <primaryComponents.CartInputField
                                defaultValue={
                                  getCartItemsViaVariant(item?.id)?.quantity ||
                                  0
                                }
                                updateCart={(val) =>
                                  handleAddToCart({
                                    product,
                                    quantity: val,
                                    variant: item,
                                  })
                                }
                                disabled={hasItemTracking}
                                isProductCard={true}
                                style={{
                                  width: "calc(33.3% - 5.33px)",
                                  height: "40px",
                                }}
                              />

                              <primaryComponents.Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (!hasItemTracking)
                                    return handleAddToCart({
                                      product,
                                      quantity:
                                        Number(
                                          getCartItemsViaVariant(item?.id)
                                            ?.quantity,
                                        ) + 1,
                                      variant: item,
                                    });
                                  setProductProperties({
                                    product,
                                    variant: item,
                                    store_properties:
                                      item?.store_variant_properties?.[0],
                                  });
                                }}
                                classNames={"btn btn--outline"}
                                style={{
                                  width: "calc(33.3% - 5.33px)",
                                  height: "40px",
                                }}
                              >
                                +
                              </primaryComponents.Button>
                            </div>
                          ) : (
                            <primaryComponents.Button
                              styles={{ height: "40px" }}
                              classNames={"btn btn--primary"}
                              onClick={(event) => {
                                event.stopPropagation();
                                if (!hasItemTracking)
                                  return handleAddToCart({
                                    product,
                                    quantity:
                                      item?.store_variant_properties?.[0]
                                        ?.stock_quantity > 0 &&
                                      item?.store_variant_properties?.[0]
                                        ?.stock_quantity < 1
                                        ? item?.store_variant_properties?.[0]
                                            ?.stock_quantity
                                        : 1,
                                    variant: item,
                                  });
                                setProductProperties({
                                  product,
                                  variant: item,
                                  store_properties:
                                    item?.store_variant_properties?.[0],
                                });
                              }}
                            >
                              Add to cart
                            </primaryComponents.Button>
                          )}
                        </div>
                      ) : (
                        <div className={styles.right}></div>
                      )}
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        isActive={productProperties !== null}
        width={550}
        closeModal={closeModal}
      >
        <ItemTrackingSelection
          closeModal={closeModal}
          productProperties={productProperties}
          cartDetails={getCartItemsViaVariant(productProperties?.variant?.id)}
          handleAddToCart={handleAddToCart}
          handleReduceCartItem={handleReduceCartItem}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default ProductGridVariantCard;
