import styles from "./MainDashboard.module.scss";
import React from "react";
import Icon from "../../../assets/icons";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import MainDashboardLogic from "./MainDashBoardLogic";
import { useNavigate, Link } from "react-router-dom";
import Images from "../../../assets/images";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { pathConstants } from "../../../routes/pathContants";
import { formatCurrency } from "../../../helpers";
import useCountryChecker from "../../../hooks/useCountryChecker";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";

const MainDashboard = () => {
  const {
    dataNotifications,
    first_name,
    startDate,
    endDate,
    storeList,
    currentStore,
    business_role,
    isFetchingTutorials,
    isFetchingAnalytics,
    tutorials,
    setupGuideCategories,
    analyticsData,
    activeModals,
    showNotificationModal,
    setActiveModals,
    openModal,
    closeModal,
    setDateFilter,
    setCurrentStore,
    filterByStoreId,
    setShowNotificationModal,
  } = MainDashboardLogic();
  const navigate = useNavigate();
  const { isNigeria } = useCountryChecker();
  const { storeHasMultipleCurrency } = useStoreCurrency();

  return (
    <>
      <div className={`${styles.dashboard} ${globalStyles.pagePaddings}`}>
        <div className={styles.headingContainer}>
          <h3 className={`${styles.title} mb-2`}>Welcome {first_name},</h3>
          <div className={styles.noticationContainer}>
            <div
              onClick={() => setShowNotificationModal(true)}
              className={styles.notificationBellContainer}
            >
              <img src={Icon.notificationBell} alt="" />
              {dataNotifications &&
                dataNotifications?.filter((item) => !item.is_read)?.length >
                  0 && (
                  <span>
                    {dataNotifications?.filter((item) => !item.is_read)?.length}
                  </span>
                )}
            </div>
            <div className={styles.notificationText}>Notification</div>
          </div>
        </div>

        <div>
          <div className={styles.titleSection}>
            <img width={22} height={22} src={Icon.star} alt="quick actions" />
            <span className="small-text-size">Quick Actions</span>
          </div>
          <div className={styles.dashboard__quickActions}>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_add_products"
            >
              <primaryComponents.Button
                classNames="btn btn--primary"
                onClick={() => openModal("isProductWidget")}
              >
                Add Product
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>

            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_stores"
            >
              <primaryComponents.Button
                onClick={() => navigate(pathConstants.STORES)}
                classNames="btn btn--outline"
              >
                Sell products
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>

            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Expense"}
              permissionChildKey="can_create_expense"
            >
              <primaryComponents.Button
                onClick={() => navigate(pathConstants.EXPENSES_LIST)}
                classNames="btn btn--outline"
              >
                Create expense
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>

            <secondaryComponents.CountryItemPermission>
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Payments"}
                permissionChildKey="can_request_transfer"
              >
                <primaryComponents.Button
                  onClick={() => openModal("openTransferWidget")}
                  classNames="btn btn--outline"
                >
                  {business_role === "BUSINESS MANAGER"
                    ? "Request Transfer"
                    : "Transfer Funds"}
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            </secondaryComponents.CountryItemPermission>

            {/* <primaryComponents.Button
              onClick={() => setShowDropdown(!showDropdown)}
              classNames="btn btn--outline"
            >
              <FontAwesomeIcon icon="angle-down" style={{ fontSize: "18px" }} />
            </primaryComponents.Button> */}

            <secondaryComponents.MainDasboardDropdown openModal={openModal} />
          </div>
        </div>

        <hr />

        <div>
          <div className={styles.titleSection}>
            <img width={22} height={22} src={Icon.setup} alt="setup" />
            <span className="small-text-size">Setup</span>
          </div>

          <div className={styles.dashboard__setup}>
            <div>
              <h4>Let us help you to get started.</h4>
              <p className="big-text-size">
                Set up and get familiar with the Lumi tools
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className={styles.barWrap}>
                <p className="small-text-size pl-4 m-1">
                  You are {setupGuideCategories?.overall_percentage}% setup
                </p>

                <div className={styles.progressBar}>
                  <div
                    className={styles.progressBar__progressive}
                    style={{
                      width:
                        setupGuideCategories?.overall_percentage + "%" ||
                        0 + "%",
                    }}
                  ></div>
                </div>
              </div>

              <primaryComponents.Button
                onClick={() =>
                  setActiveModals((prev) => ({
                    ...prev,
                    isGetStartedVideo: {
                      isActive: true,
                      title: "Get Started with Lumi Business",
                      url: "3O8FRXajWfk",
                    },
                  }))
                }
                classNames="btn btn--primary smallBtn mt-3  align-items-center"
              >
                {setupGuideCategories?.overall_percentage === 0
                  ? "Get Started"
                  : "Continue"}
              </primaryComponents.Button>
            </div>
          </div>
        </div>

        <hr />
        <secondaryComponents.NavigateWithPermission
          permissionChildKey={"can_view_analytics"}
          permissionParentKey={"Inventory Management"}
        >
          <div>
            <div
              className="d-flex align-items-end justify-content-between flex-wrap mb-4"
              style={{ gap: "10px" }}
            >
              <div className={`${styles.titleSection} mb-0`}>
                <img
                  width={22}
                  height={22}
                  src={Icon.activity}
                  alt="activity"
                />
                <span className="small-text-size">Analytics</span>
              </div>

              <div
                className="d-flex align-items-center justify-content-between flex-wrap"
                style={{ gap: "10px" }}
              >
                <div style={{ width: "200px" }}>
                  <primaryComponents.SelectField
                    options={
                      storeHasMultipleCurrency
                        ? [...storeList]
                        : [
                            {
                              name: "All stores",
                              id: "ALL_STORES",
                            },
                            ...storeList,
                          ]
                    }
                    idKey="id"
                    nameKey="name"
                    onChange={(value) => {
                      setCurrentStore(value);
                      filterByStoreId(value);
                    }}
                    value={
                      storeList?.find(
                        (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                      )?.name || "All stores"
                    }
                    floatError={false}
                    classNames="store-select"
                  />
                </div>

                <primaryComponents.DateRangeInput
                  startDate={startDate}
                  endDate={endDate}
                  onChange={setDateFilter}
                  showPlaceholder={true}
                />
              </div>
            </div>

            <div className={styles.dashboard__analytics}>
              <secondaryComponents.MainDashboardAnalyticsCard
                isFetchingAnalytics={isFetchingAnalytics}
                value={formatCurrency({
                  value: analyticsData?.revenue || 0,
                  currencyCode: analyticsData?.store_currency_code,
                })}
                title="Gross Sales"
                color="2"
              />

              <secondaryComponents.MainDashboardAnalyticsCard
                isFetchingAnalytics={isFetchingAnalytics}
                value={formatCurrency({
                  value: analyticsData?.gross_profit || 0,
                  currencyCode: analyticsData?.store_currency_code,
                })}
                title="Gross Profit "
                color="3"
              />

              <secondaryComponents.MainDashboardAnalyticsCard
                isFetchingAnalytics={isFetchingAnalytics}
                value={analyticsData?.total_sales_count || "-"}
                title="No. of Sales"
                color="1"
              />
              <secondaryComponents.MainDashboardAnalyticsCard
                isFetchingAnalytics={isFetchingAnalytics}
                value={formatCurrency({
                  value: analyticsData?.average_sale || 0,
                  currencyCode: analyticsData?.store_currency_code,
                })}
                title="Average Sales"
                color="4"
              />
              <secondaryComponents.MainDashboardAnalyticsCard
                isFetchingAnalytics={isFetchingAnalytics}
                value={formatCurrency({
                  value: analyticsData?.credit_amount,
                  currencyCode: analyticsData?.store_currency_code,
                })}
                title="Customer Debts"
                color="5"
              />
              <secondaryComponents.MainDashboardAnalyticsCard
                isFetchingAnalytics={isFetchingAnalytics}
                value={analyticsData?.no_of_customers}
                title="No. of Customers"
                color="6"
              />
            </div>
          </div>
          <hr />
        </secondaryComponents.NavigateWithPermission>

        <div className={styles.dashboard__analytics} style={{ rowGap: "70px" }}>
          <div style={{ height: "320px" }}>
            <div className={styles.titleSection}>
              <img width={22} height={22} src={Icon.setting} alt="" />
              <span className="small-text-size">Request In-Store Setup</span>
            </div>
            <secondaryComponents.MainDashboardSetUpCard
              image={Images.iStock}
              subtitle="Schedule an in-person visit or online training with our team "
              actionText="Request Setup"
              link="https://forms.monday.com/forms/9031ac08823bf0ad8e8caf8461f6591b?r=use1"
            />
          </div>
          <div style={{ height: "320px" }}>
            <div className={styles.titleSection}>
              <img width={22} height={22} src={Icon.shopBag} alt="" />
              <span className="small-text-size">Shop Hardwares</span>
            </div>
            <secondaryComponents.MainDashboardSetUpCard
              image={Images.hardwares}
              subtitle="Streamline your business operations with our range of hardwares"
              actionText="Shop Hardware"
              link={
                isNigeria
                  ? "https://app.lumibusiness.io/storefront/17-lumi-business-store-lumi-business"
                  : "https://linktr.ee/mk_enlumi/store"
              }
            />
          </div>
          <div style={{ height: "320px" }}>
            <div className={styles.titleSection}>
              <img width={22} height={22} src={Icon.lecture} alt="" />
              <span className="small-text-size">Tutorial</span>
            </div>

            <div className={styles.card}>
              <secondaryComponents.LoaderHelper
                classNames={"mt-4"}
                isLoading={isFetchingTutorials}
              >
                {tutorials?.map((tutorialsItems) => (
                  <React.Fragment key={tutorialsItems?.id}>
                    <Link
                      onClick={() =>
                        setActiveModals((prev) => ({
                          ...prev,
                          isGetStartedVideo: {
                            isActive: true,
                            isGetStarted: false,
                            title: tutorialsItems?.title,
                            url: tutorialsItems?.video_url
                              ?.split("=")?.[1]
                              ?.replace("&", "?"),
                          },
                        }))
                      }
                      className={styles.card__item}
                    >
                      <img
                        src={Images.thumbnail}
                        alt="card-icon"
                        className={styles.card__image}
                      />
                      <p className="small-text-size">{tutorialsItems?.title}</p>
                    </Link>
                    <hr />
                  </React.Fragment>
                ))}
              </secondaryComponents.LoaderHelper>
            </div>
          </div>
        </div>
      </div>

      {/* get started video */}
      <secondaryComponents.Modal
        isActive={activeModals?.isGetStartedVideo?.isActive}
        width="850px"
        closeModal={() =>
          setActiveModals((prev) => ({
            ...prev,
            isGetStartedVideo: {
              isActive: false,
              title: "",
              url: "",
              isGetStarted: false,
            },
          }))
        }
      >
        <secondaryComponents.GetStartedVideo
          url={activeModals?.isGetStartedVideo?.url}
          title={activeModals?.isGetStartedVideo?.title}
          isGetStarted={activeModals?.isGetStartedVideo?.isGetStarted}
          setupGuideCategories={setupGuideCategories}
          closeWidget={() =>
            setActiveModals((prev) => ({
              ...prev,
              isGetStartedVideo: {
                isActive: false,
                title: "",
                url: "",
                isGetStarted: false,
              },
            }))
          }
        />
      </secondaryComponents.Modal>

      {/* product widget */}
      <secondaryComponents.Modal
        isActive={activeModals?.isProductWidget}
        width="800px"
        closeModal={() => closeModal("isProductWidget")}
      >
        <secondaryComponents.AddProductWidget
          closeModal={() => closeModal("isProductWidget")}
        />
      </secondaryComponents.Modal>

      {/* transfer widget */}
      <secondaryComponents.SideModal
        isActive={activeModals?.openTransferWidget}
        closeWidget={() => closeModal("openTransferWidget")}
      >
        <secondaryComponents.FundTransfer
          closeWidget={() => closeModal("openTransferWidget")}
        />
      </secondaryComponents.SideModal>

      {/* export end of day widget */}
      <secondaryComponents.SideModal
        isActive={activeModals?.isEndofday}
        closeModal={() => closeModal("isEndofday")}
      >
        <secondaryComponents.SalesEndOfDay
          closeWidget={() => closeModal("isEndofday")}
        />
      </secondaryComponents.SideModal>

      {/* return stock sidebar */}
      <secondaryComponents.SideModal
        width={600}
        isActive={activeModals?.isReturnProduct}
        closeModal={() => closeModal("isReturnProduct")}
      >
        <secondaryComponents.ReturnStockWidget
          closeWidget={() => closeModal("isReturnProduct")}
        />
      </secondaryComponents.SideModal>

      {/* transfer stock sidebar */}
      <secondaryComponents.SideModal
        width={600}
        isActive={activeModals?.isTransferProduct}
        closeModal={() => closeModal("isTransferProduct")}
      >
        <secondaryComponents.TransferStockWidget
          closeWidget={() => closeModal("isTransferProduct")}
        />
      </secondaryComponents.SideModal>

      {/* notifications sidebar */}
      <secondaryComponents.SideModal
        width={600}
        isActive={showNotificationModal}
        closeModal={() => setShowNotificationModal(false)}
      >
        <secondaryComponents.Notifications
          setShowNotificationModal={setShowNotificationModal}
        />
      </secondaryComponents.SideModal>
    </>
  );
};

export default MainDashboard;
