import { useParams, useSearchParams } from "react-router-dom";
import { useGetCustomerDetails } from "../../../../../hooks/api/queries/useCustomers";
import { useEffect, useState } from "react";
import { getUrlQuerysection } from "../../../../../helpers";
import { useGetCreditLogDetails } from "../../../../../hooks/api/queries/useInventory";

const useCreditLogDetails = () => {
  const { customerId } = useParams();
  const [addDebt, setAddDebt] = useState(false);
  const [showUpdateCredit, setShowUpdateCredit] = useState(false);
  const [showUpdateCreditLimit, setShowUpdateCreditLimit] = useState(false);
  const [showUpdateCreditReminder, setShowUpdateCreditReminder] =
    useState(false);
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    if (!search.get("customer_id")) {
      search.set("customer_id", customerId);
      setSearch(search, { replace: true });
    }
  }, []);

  const {
    isLoading: isFetchingCustomer,
    data: customer,
    refetch: fetchCustomer,
  } = useGetCustomerDetails(customerId);

  const {
    isLoading: isFetchingCredits,
    data: credits,
    refetch: refetchCredits,
  } = useGetCreditLogDetails(queries);

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  return {
    addDebt,
    showUpdateCreditLimit,
    setShowUpdateCreditLimit,
    showUpdateCredit,
    showUpdateCreditReminder,
    setShowUpdateCreditReminder,
    isFetchingCustomer,
    customer: customer || {},
    metaDetails: credits?.meta || {},
    isFetchingCredits,
    credits: credits?.data || [],
    refetchCredits,
    fetchCustomer,
    setShowUpdateCredit,
    setAddDebt,
    gotoPage,
  };
};
export default useCreditLogDetails;
