import { Fragment, useEffect, useMemo, useState } from "react";
import { useExpanded, useTable, useRowSelect } from "react-table";
import { formatCurrency } from "../../../helpers";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import styles from "./FoodMenuTable.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOuterClick from "../../../hooks/useOuterClick";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";

const ProductCheckBox = ({ row, setSelectAllItems }) => {
  const [checked, setChecked] = useState(false);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <primaryComponents.Checkbox
        name={`${row?.original?.product_id}`}
        id={`${row?.original?.product_id}`}
        isChecked={checked}
        onChange={(value) => {
          setChecked(!checked);
          setSelectAllItems(value);
        }}
      />
      <span style={{ marginLeft: 10 }}>Product Name</span>
    </div>
  );
};

const SellingPrice = ({
  row,
  currency_code,
  setProductsList,
  setSelectedMenuItems,
}) => {
  const [showPricings, setShowPricings] = useState(false);
  const [selected, setSelected] = useState(
    row.original.pricings && row.original.pricings.length > 0
      ? row.original.pricings[0]
      : null,
  );

  const outterClickRef = useOuterClick(() => {
    setShowPricings(false);
  });

  useEffect(() => {
    if (
      row.original.pricing_type_id &&
      row.original.pricings &&
      row.original.pricings.length > 0
    ) {
      const itemPrice = row.original.pricings.find(
        (item) => item?.pricing_type?.id === row.original.pricing_type_id,
      );

      if (itemPrice) {
        setSelected(itemPrice);
      } else {
        setSelected(row.original.pricings[0]);
      }
    }
  }, [row]);

  const handleClick = (item) => {
    setSelected(item);
    setProductsList((prev) =>
      prev.map((productItem) =>
        productItem.product_id === row.original.product_id
          ? {
              ...row.original,
              selling_price: item.price,
              pricing_type_id: item?.pricing_type?.id,
            }
          : productItem,
      ),
    );
    setSelectedMenuItems((prev) => {
      if (
        prev[
          `${row.original.product_id}${
            row.original.variant_id ? row.original.variant_id : ""
          }`
        ]
      ) {
        return {
          ...prev,
          [`${row.original.product_id}${
            row.original.variant_id ? row.original.variant_id : ""
          }`]: {
            ...prev[
              `${row.original.product_id}${
                row.original.variant_id ? row.original.variant_id : ""
              }`
            ],
            selling_price: item.price,
            pricing_type_id: item?.pricing_type?.id,
          },
        };
      }
      return { ...prev };
    });
    setShowPricings(false);
  };

  return (
    <div ref={outterClickRef} className={styles.sellingPrice}>
      {row.original.pricings && row.original.pricings.length > 0 && (
        <div
          onClick={() => {
            if (row.original.pricings.length > 1) {
              setShowPricings((prev) => !prev);
            }
          }}
          className={styles.changePrice}
          style={{
            cursor: row.original.pricings.length > 1 ? "pointer" : "default",
          }}
        >
          <span className={styles.priceType}>
            {selected?.pricing_type?.label}
          </span>
          <span
            className={styles.changePriceIcon}
            style={{ opacity: row.original.pricings.length > 1 ? 1 : 0.2 }}
          >
            <FontAwesomeIcon
              icon={showPricings ? "angle-up" : "angle-down"}
              color="#7647ee"
            />
          </span>
        </div>
      )}
      {showPricings && (
        <div className={styles.pricingsContainer}>
          <h6>{row.original.name}</h6>
          <hr />
          <h5>Select Pricing Type</h5>
          <div className={styles.pricingsTable}>
            <span>Pricing Type</span>
            <span>Pricing</span>
            <span>Actions</span>
          </div>
          <hr />
          {row.original.pricings.map((item, index) => (
            <Fragment key={index}>
              <div onClick={() => handleClick(item)} className={styles.prices}>
                <span>{item.pricing_type?.label}</span>
                <span>
                  {currency_code} {item.price.toLocaleString()}
                </span>
                {selected?.pricing_type?.label === item.pricing_type?.label ? (
                  <div className={styles.checkedContainer}>
                    <div className={styles.checkedInside} />
                  </div>
                ) : (
                  <div className={styles.unchecked} />
                )}
              </div>
              <hr />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export const useFoodMenuTable = ({
  menuItems,
  menuProperties,
  selectedMenuItems,
  setSelectedMenuItems,
  selectedPricingType,
}) => {
  const { store_currency } = useStoreCurrency();

  const [selectAllItems, setSelectAllItems] = useState(false);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    if (selectedPricingType && productsList.length > 0) {
      const updatedProductsList = productsList.map((item) => {
        const hasSelectedPricingType = item.pricings.find(
          (pric) => pric.pricing_type?.id === selectedPricingType.id,
        );
        return hasSelectedPricingType
          ? { ...item, pricing_type_id: selectedPricingType.id }
          : { ...item, pricing_type_id: 1 };
      });

      // Check if the new list is different from the current list
      const hasChanges =
        JSON.stringify(updatedProductsList) !== JSON.stringify(productsList);
      if (hasChanges) {
        setProductsList(updatedProductsList);
      }
    }
  }, [selectedPricingType, productsList]);

  useEffect(() => {
    if (selectAllItems) {
      setProductsList((prev) =>
        prev.map((item) => ({ ...item, isSelected: true })),
      );
      productsList.map((item) =>
        setSelectedMenuItems((prev) => ({
          ...prev,
          [item.product_id]: { ...item, isSelected: true },
        })),
      );
    } else {
      setProductsList((prev) =>
        prev.map((item) => ({ ...item, isSelected: false })),
      );
      setSelectedMenuItems({});
    }
  }, [selectAllItems]);

  useEffect(() => {
    if (isInitialRender) return;
    const newProductList = [...productsList];
    for (
      let index = newProductList?.length;
      index < menuItems?.length;
      index++
    ) {
      // check to confirm if item is among checked items
      if (
        selectedMenuItems?.[
          `${menuItems?.[index]?.product_id}${
            menuItems?.[index]?.variant_id || ""
          }`
        ] === undefined
      )
        newProductList.push(menuItems?.[index]);
    }
    setProductsList(newProductList);
  }, [menuItems]);

  useEffect(() => {
    if (!isInitialRender || menuItems === undefined) return;
    const newMenuItems = [...menuItems];
    const prevSelectedProducts = {};
    menuProperties?.menu_items?.forEach((item) => {
      prevSelectedProducts[`${item?.product_id}${item?.variant_id || ""}`] =
        item;
      updateChanges(`${item?.product_id}${item?.variant_id || ""}`, item);
    });
    const filterNewMenuItems = newMenuItems?.filter(
      (item) =>
        prevSelectedProducts[`${item?.product_id}${item?.variant_id || ""}`] ===
        undefined,
    );
    if (menuProperties?.menu_items)
      setProductsList([...menuProperties?.menu_items, ...filterNewMenuItems]);
    else setProductsList([...filterNewMenuItems]);
    setIsInitialRender(false);
  }, [menuItems]);

  useEffect(() => {
    if (!isInitialRender || menuItems === undefined) return;
  }, [menuItems]);

  const updateChanges = (position, newData) => {
    setSelectedMenuItems((prevState) => ({
      ...prevState,
      [position]: newData,
    }));
  };

  const removeCheckedItem = (position) => {
    setSelectedMenuItems((prevState) => {
      const copy = { ...prevState };
      delete copy[position];
      return copy;
    });
  };

  const columns = useMemo(
    () => [
      {
        id: "id",
        accessor: "name",
        Header: ({ row }) => {
          return (
            <ProductCheckBox row={row} setSelectAllItems={setSelectAllItems} />
          );
        },
        Cell: ({ cell: { value }, row }) => {
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <primaryComponents.Checkbox
                id={`${row?.original?.product_id}${
                  row?.original?.variant_id || ""
                }`}
                isChecked={row?.original?.isSelected}
                onChange={(val) => {
                  const newProductList = [...instance?.data];
                  newProductList[row?.index].isSelected = val;
                  setProductsList(newProductList);
                  val
                    ? updateChanges(
                        `${row?.original?.product_id}${
                          row?.original?.variant_id || ""
                        }`,
                        newProductList?.[row?.index],
                      )
                    : removeCheckedItem(
                        `${row?.original?.product_id}${
                          row?.original?.variant_id || ""
                        }`,
                      );
                }}
              />
              <label
                htmlFor={`${row?.original?.product_id}${
                  row?.original?.variant_id || ""
                }`}
                style={{ cursor: "pointer" }}
              >
                <secondaryComponents.ConcatenateWithTooltip
                  value={value}
                  noOfCharacters="50"
                />
              </label>
            </div>
          );
        },
      },
      {
        Header: "Product Type",
        accessor: "is_service",
        Cell: ({ cell: { value } }) => (
          <span>{value ? "Service" : "Product"}</span>
        ),
      },
      {
        Header: "Selling Price",
        accessor: "selling_price",
        Cell: ({ cell: { value }, row }) =>
          formatCurrency({
            value:
              row.original.pricing_type_id &&
              row.original.pricings &&
              row.original.pricings.length > 0
                ? row.original.pricings.find(
                    (item) =>
                      item.pricing_type?.id === row.original.pricing_type_id,
                  )?.price
                : value
                  ? value
                  : row.original.pricings && row.original.pricings.length > 0
                    ? row.original.pricings[0].price
                    : 0,
            currencyCode:
              menuProperties?.store?.currency_code || store_currency,
            decimal: 0,
          }) || "N/A",
      },
      {
        Header: "Pricing Type",
        Cell: ({ cell: { value }, row }) => (
          <SellingPrice
            row={row}
            currency_code={
              menuProperties?.store?.currency_code || store_currency
            }
            setProductsList={setProductsList}
            setSelectedMenuItems={setSelectedMenuItems}
          />
        ),
      },
      {
        Header: "Quantity",
        accessor: "stock_quantity",
        Cell: ({ cell: { value, row } }) => {
          if (value !== null && value !== undefined)
            return (
              <span
                style={{
                  color:
                    value > row.original["minimum_stock_quantity"]
                      ? "#19AE57"
                      : "red",
                  backgroundColor:
                    value > row.original["minimum_stock_quantity"]
                      ? "#E6FFF0"
                      : "#FFEEE9",
                  borderRadius: "4px",
                  padding: "4px",
                  height: "26px",
                  fontSize: "12px",
                }}
              >
                {value}
              </span>
            );

          if (!value) return "N/A";
        },
      },
      {
        Header: "Category",
        accessor: "category_name",
        Cell: ({ cell: { value } }) => {
          return (
            <span style={{ textTransform: "uppercase" }}>{value || "N/A"}</span>
          );
        },
      },
    ],
    [store_currency],
  );

  const instance = useTable(
    { columns, data: productsList },
    useExpanded,
    useRowSelect,
  );

  return {
    selectAllItems,
    setSelectAllItems,
    instance,
    updateChanges,
    setProductsList,
  };
};
