import { useNavigate } from "react-router-dom";
import Icon from "../../../assets/icons";
import styles from "./PushNotifications.module.scss";
import { usePushNotifications } from "./usePushNotifications";
import { pathConstants } from "../../../routes/pathContants";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import { OrderNoteIcon } from "../../../assets/svgIcons";
import { formatCurrency } from "../../../helpers";

const PushNotifications = ({ children }) => {
  const navigate = useNavigate();
  const {
    orderDetails,
    orderId,
    isProcessingOrder,
    showRejectionReason,
    isLoadingOrderDetails,
    setShowRejectionReason,
    setOrderId,
    acceptOrRejectOrder,
  } = usePushNotifications();

  return (
    <>
      {children}

      <secondaryComponents.Modal
        width={500}
        isActive={showRejectionReason}
        closeModal={() => setShowRejectionReason(false)}
      >
        <secondaryComponents.RejectOrderPop
          onSubmit={acceptOrRejectOrder}
          closeModal={() => setShowRejectionReason(false)}
          isProcessingOrder={isProcessingOrder}
        />
      </secondaryComponents.Modal>

      {orderId ? (
        <secondaryComponents.Modal
          width={840}
          isActive={orderId ? true : false}
          closeModal={() => {
            setOrderId(null);
          }}
        >
          <secondaryComponents.LoaderHelper
            isLoading={isLoadingOrderDetails}
            classNames={"mt-5"}
          >
            <div className={styles.headingContainer}>
              <div className={styles.heading}>
                <img src={Icon.orderNotification} alt="" />
                <h4>New Online Order</h4>
              </div>
              <div
                onClick={() => {
                  setOrderId(null);
                }}
                className={styles.routeButton}
              >
                <img src={Icon.reRoute} alt="" />
                <span>Re-Route to another cashier</span>
              </div>
            </div>
            <div>
              <div className={styles.transactionDetails__column}>
                <section>
                  <div className={styles.statusHeading}>
                    <p>Payment Status:</p>
                    <span
                      className={`small-text-size ${
                        !orderDetails?.is_paid
                          ? "error--squared"
                          : "success--squared"
                      }`}
                      style={{ width: "fit-content" }}
                    >
                      {" "}
                      {orderDetails?.is_paid ? "PAID" : "NOT PAID"}
                    </span>
                  </div>
                  <div className={styles.actionContainer}>
                    <primaryComponents.Button
                      onClick={() => {
                        navigate(
                          pathConstants.INVENTORY_SALES_TRANSACTIONS +
                            `?orderId=${orderId}`,
                        );
                        setOrderId(null);
                      }}
                      classNames={"btn btn--outline smallBtn"}
                    >
                      View More...
                    </primaryComponents.Button>
                  </div>
                </section>
                <div>
                  <p className="small-text-size">Payment Mode:</p>
                  <p className="dark--text">
                    {orderDetails?.payment_method || "N/A"}
                  </p>
                </div>
                <div>
                  <p className="small-text-size">Customer:</p>
                  <p className="dark--text">
                    {orderDetails?.customer?.first_name || "N/A"}
                  </p>
                </div>
                <div>
                  <p className="small-text-size">Store Name:</p>
                  <p className="dark--text">
                    {orderDetails?.store?.name || "N/A"}
                  </p>
                </div>
                <div>
                  <p className="small-text-size">Order Type:</p>
                  <p className="dark--text">
                    {orderDetails?.order_type === "DINE_IN"
                      ? "PICK UP"
                      : "DELIVERY" || "N/A"}
                  </p>
                </div>
                {orderDetails?.table && (
                  <div>
                    <p className="small-text-size">Table:</p>
                    <p className="dark--text">
                      {orderDetails?.table?.name || "N/A"}
                    </p>
                  </div>
                )}
                {orderDetails?.delivery_address && (
                  <div>
                    <p className="small-text-size">Address:</p>
                    <p className="dark--text">
                      {`${orderDetails?.delivery_address?.address} ${
                        orderDetails?.delivery_address?.zipcode || ""
                      } ${orderDetails?.delivery_address?.city || ""} ${
                        orderDetails?.delivery_address?.state
                      }, ${orderDetails?.delivery_address?.country}` || "N/A"}
                    </p>
                  </div>
                )}
              </div>

              <h5 className="mt-4 mb-3">Ordered Items</h5>
              <div className={styles.orderDetails}>
                <p>ITEM</p>
                <p>QUANTITY</p>
                <p>AMOUNT</p>
              </div>
              {orderDetails?.order_items?.map((item) => (
                <div className={styles.orderDetails} key={item?.id}>
                  <p>{item?.cart_item_name}</p>
                  <p className="text-left">{item?.quantity}</p>
                  <p className="text-left">
                    {formatCurrency({
                      value: item?.total_price,
                      currencyCode: orderDetails?.store?.currency_code,
                    })}
                  </p>
                </div>
              ))}
              {orderDetails?.notes && (
                <div className={styles.order_note}>
                  <div className="d-flex">
                    <OrderNoteIcon color={"#7647EE"} />
                    <h5 className="ml-1">Order Note</h5>
                  </div>
                  <div className={styles.order_note_field}>
                    <p>{orderDetails?.notes}</p>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.buttonContainer}>
              <primaryComponents.Button
                classNames={"btn btn--outline-red cover"}
                onClick={() => setShowRejectionReason(true)}
              >
                Reject
              </primaryComponents.Button>
              <primaryComponents.Button
                classNames={"btn btn--primary cover"}
                onClick={() =>
                  acceptOrRejectOrder({ isAccept: true, rejectionReason: null })
                }
                isLoading={isProcessingOrder}
                isDisabled={isProcessingOrder}
                loadingText={"Accepting..."}
              >
                Accept
              </primaryComponents.Button>
            </div>
          </secondaryComponents.LoaderHelper>
        </secondaryComponents.Modal>
      ) : null}
    </>
  );
};

export default PushNotifications;
