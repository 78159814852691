import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../primaryComponents";
import useDownloadStatement from "./useDownloadStatement";
import Validation from "./Validation";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const StoreWalletDownloadStatement = ({ closeWidget }) => {
  const {
    statementFormat,
    setStatementFormat,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    handleDownloadStatement,
    isLoading,
  } = useDownloadStatement({ closeWidget });
  const {
    control,
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = Validation(statementFormat, startDate, endDate);

  return (
    <div className={globalStyles.pageColumnGaps} style={{ minHeight: "100%" }}>
      <div>
        <primaryComponents.Button
          classNames={"btn btn--outline smallBtn"}
          onClick={closeWidget}
        >
          <FontAwesomeIcon icon="angle-left" style={{ fontSize: "15px" }} />
        </primaryComponents.Button>
      </div>

      <div>
        <h4>Statement of Account</h4>
        <p>
          You can choose how you&apos;d like to receive your account statement
          from the options below:
        </p>
      </div>

      <form
        className={globalStyles.pageColumnGaps}
        style={{ gap: "10px" }}
        id="walletStatementOfAccount"
        onSubmit={handleSubmit(handleDownloadStatement)}
      >
        <div>
          <label className="mb-1">
            <span className="error--text">*</span>File Format
          </label>
          <div className="mb-3">
            <primaryComponents.BoxedRadioInput
              control={control}
              name="statementFormat"
              value={statementFormat}
              id="PDF"
              isChecked={statementFormat === "PDF"}
              setRadioValue={() => {
                setStatementFormat("PDF");
              }}
            >
              <span className="font-weight-semibold">PDF</span>
            </primaryComponents.BoxedRadioInput>
          </div>
          <div>
            <primaryComponents.BoxedRadioInput
              control={control}
              name="statementFormat"
              value={statementFormat}
              id="CSV"
              isChecked={statementFormat === "CSV"}
              setRadioValue={() => {
                setStatementFormat("CSV");
              }}
            >
              <span className="font-weight-semibold">Excel</span>
            </primaryComponents.BoxedRadioInput>
          </div>
        </div>
        <div>
          <label className="mb-1">
            <span className="error--text">*</span>Date Range
          </label>
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ width: "max-content" }}>
              <primaryComponents.DateInputV2
                control={control}
                name="startDate"
                value={startDate}
                classNames="white"
                isRequired={true}
                errorMessage={errors.startDate}
                onChange={({ target: { value } }) => {
                  setStartDate(value);
                }}
                onKeyUp={() =>
                  errors.startDate !== undefined && trigger("startDate")
                }
                register={register}
              />
            </div>
            <span>To</span>
            <div style={{ width: "max-content" }}>
              <primaryComponents.DateInputV2
                control={control}
                name="endDate"
                value={endDate}
                classNames="white"
                isRequired={true}
                errorMessage={errors.endDate}
                onChange={({ target: { value } }) => {
                  setEndDate(value);
                }}
                onKeyUp={() =>
                  errors.endDate !== undefined && trigger("endDate")
                }
                register={register}
              />
            </div>
          </div>
        </div>
      </form>

      <primaryComponents.Button
        classNames="btn btn--primary mt-auto"
        isLoading={isLoading}
        loadingText="Downloading..."
        isDisabled={isLoading}
        type="submit"
        form="walletStatementOfAccount"
      >
        Download
      </primaryComponents.Button>
    </div>
  );
};

export default StoreWalletDownloadStatement;
