import secondaryComponents from "..";
import { BankEmptyIcon } from "../../../assets/svgIcons";
import useAccountingsListLogic from "./useAccountingsListLogic";

const AllAccountingsList = ({ banks, refetch }) => {
  const {
    instance,
    selectedBank,
    setSelectedBank,
    bankInfo,
    isFetchingBankInfo,
    selectedDeleteBank,
    setSelectedDeleteBank,
  } = useAccountingsListLogic({
    banks,
    refetch,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5">
          <BankEmptyIcon />
          <h5>You have no banks</h5>
          <p>When you do, they would appear here.</p>
        </div>
      </secondaryComponents.TableHelper>
      {/* <secondaryComponents.Pagination
        totalPageNumber={metaDetails?.last_page}
        handlePageClick={(value) => gotoPage(value?.selected + 1)}
        currentPage={metaDetails?.current_page}
      /> */}

      {/* update */}
      <secondaryComponents.LoaderHelper isLoading={isFetchingBankInfo}>
        <secondaryComponents.SideModal
          isActive={!!selectedBank}
          closeModal={() => setSelectedBank(null)}
          width={500}
        >
          <secondaryComponents.AddBankAccount
            closeWidget={() => setSelectedBank(null)}
            handleFetchbanks={refetch}
            isEditMode={true}
            bankInfo={bankInfo}
          />
        </secondaryComponents.SideModal>
      </secondaryComponents.LoaderHelper>

      {/* delete modal */}
      <secondaryComponents.LoaderHelper isLoading={isFetchingBankInfo}>
        <secondaryComponents.Modal
          isActive={selectedDeleteBank !== null}
          closeModal={() => setSelectedDeleteBank(null)}
          width={500}
        >
          <secondaryComponents.BankAccountDeletion
            closeWidget={() => setSelectedDeleteBank(null)}
            bankDetails={bankInfo}
            selectedDeleteBank={selectedDeleteBank}
            handleFetchBanks={refetch}
          />
        </secondaryComponents.Modal>
      </secondaryComponents.LoaderHelper>
      {/* delete modal */}
    </div>
  );
};

export default AllAccountingsList;
