import styles from "./CreditLodDetails.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency, convertToTilteCase } from "../../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import useCreditLogDetails from "./useCreditLogDetails";
import Icon from "../../../../../assets/icons";
import AddDebtWidgt from "../AddDebt";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";
import { pathConstants } from "../../../../../routes/pathContants";

const CreditLogDetails = () => {
  const navigate = useNavigate();
  const {
    addDebt,
    showUpdateCreditReminder,
    setShowUpdateCreditReminder,
    showUpdateCreditLimit,
    setShowUpdateCreditLimit,
    showUpdateCredit,
    isFetchingCustomer,
    customer,
    metaDetails,
    isFetchingCredits,
    credits,
    refetchCredits,
    fetchCustomer,
    setShowUpdateCredit,
    setAddDebt,
    gotoPage,
  } = useCreditLogDetails();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Customer Details"
      description="View the details of a customer"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={`${globalStyles.pageColumnGaps} ${globalStyles.pagePaddings}`}
          >
            <div>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  className="mr-3"
                  style={{ fontSize: "18px" }}
                />
                <span>Back</span>
              </primaryComponents.Button>
            </div>

            <secondaryComponents.LoaderHelper
              isLoading={isFetchingCustomer}
              classNames="mt-5"
            >
              <>
                <div className={globalStyles.pageHeaders}>
                  <Link
                    to={pathConstants.CUSTOMER_DETAILS({
                      customerId: customer?.id,
                    })}
                  >
                    <h4 className="font-weight-semibold purple--text">
                      {customer?.full_name
                        ? convertToTilteCase(customer?.full_name)
                        : "N/A"}
                    </h4>
                  </Link>

                  <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
                    <primaryComponents.Button
                      classNames="btn btn--outline p-3"
                      onClick={() => setShowUpdateCreditReminder(true)}
                    >
                      <img src={Icon.emailTwo} alt="" />
                    </primaryComponents.Button>
                    <primaryComponents.Button
                      type="button"
                      classNames={"btn btn--outline-red"}
                      onClick={() => setAddDebt(true)}
                    >
                      Add Debt
                    </primaryComponents.Button>
                    <primaryComponents.Button
                      type="button"
                      classNames={"btn btn--success"}
                      onClick={() => setShowUpdateCredit(true)}
                    >
                      Add Repayment
                    </primaryComponents.Button>
                  </div>
                </div>

                <div className={styles.customerCard}>
                  <div>
                    <h6>Phone Number</h6>
                    <p className="mb-0 small-text-size">
                      {customer?.phone
                        ? "(+" + customer?.phone_code + ") " + customer?.phone
                        : "N/A"}
                    </p>
                  </div>
                  <div>
                    <h6>Credit till date</h6>
                    <p className="mb-0 small-text-size">
                      {formatCurrency({
                        value: customer?.total_credit,
                        currencyCode: customer?.store?.currency_code,
                      })}
                    </p>
                  </div>
                  <div>
                    <h6>Total Repayment</h6>
                    <p className="mb-0 small-text-size">
                      {formatCurrency({
                        value: customer?.total_repayment,
                        currencyCode: customer?.store?.currency_code,
                      })}
                    </p>
                  </div>
                  <div>
                    <h6>Outstanding Balance</h6>
                    <p className="mb-0 small-text-size">
                      {formatCurrency({
                        value: customer?.outstanding_balance,
                        currencyCode: customer?.store?.currency_code,
                      })}
                    </p>
                  </div>
                  <div>
                    <h6>Average Spent</h6>
                    <p className="mb-0 small-text-size">
                      {formatCurrency({
                        value: customer?.average_spent,
                        currencyCode: customer?.store?.currency_code,
                      })}
                    </p>
                  </div>
                  <div>
                    <h6>Total Purchase</h6>
                    <p className="mb-0 small-text-size">
                      {formatCurrency({
                        value: customer?.total_purchases,
                        currencyCode: customer?.store?.currency_code,
                      })}
                    </p>
                  </div>
                  <div>
                    <h6>Credit Limit</h6>
                    <div
                      className="d-flex items-center"
                      style={{ gap: "5px", alignItems: "center" }}
                    >
                      <p className="mb-0 small-text-size">
                        {formatCurrency({
                          value: customer?.credit_limit,
                          currencyCode: customer?.store?.currency_code,
                        })}
                      </p>
                      <primaryComponents.Button
                        classNames={"btn btn--outline p-2"}
                        onClick={() => {
                          setShowUpdateCreditLimit(true);
                        }}
                        style={{ height: "25px" }}
                      >
                        <img
                          src={Icon.editWrite}
                          alt=""
                          width="16px"
                          height="16px"
                        />{" "}
                        Edit
                      </primaryComponents.Button>
                    </div>
                  </div>
                </div>
              </>
            </secondaryComponents.LoaderHelper>

            <div className="mt-4">
              <h5 className="font-weight-semibold">
                {" "}
                ({metaDetails?.total || 0}) Transactions
              </h5>
              <secondaryComponents.LoaderHelper
                classNames="mt-4"
                isLoading={isFetchingCredits}
              >
                <secondaryComponents.CustomerCreditLogTable
                  credits={credits}
                  gotoPage={gotoPage}
                  metaDetails={metaDetails}
                />
              </secondaryComponents.LoaderHelper>
            </div>
          </motion.div>

          <secondaryComponents.SideModal
            isActive={showUpdateCredit}
            closeModal={() => setShowUpdateCredit(false)}
          >
            <secondaryComponents.UpdateCreditWidget
              closeWidget={() => setShowUpdateCredit(false)}
              onSuccess={() => {
                fetchCustomer();
                refetchCredits();
              }}
              customer={customer}
            />
          </secondaryComponents.SideModal>

          <secondaryComponents.SideModal
            isActive={addDebt}
            closeModal={() => setAddDebt(false)}
          >
            <AddDebtWidgt
              closeWidget={() => setAddDebt(false)}
              onSuccess={() => {
                fetchCustomer();
                refetchCredits();
              }}
              customer={customer}
            />
          </secondaryComponents.SideModal>

          <secondaryComponents.SideModal
            isActive={showUpdateCreditReminder}
            closeModal={() => setShowUpdateCreditReminder(false)}
            width={650}
          >
            <secondaryComponents.SendCreditReminderWidget
              closeWidget={() => setShowUpdateCreditReminder(false)}
              customer={customer}
            />
          </secondaryComponents.SideModal>

          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Customers"}
            permissionChildKey={"can_update_customer_credit_limit"}
          >
            <secondaryComponents.Modal
              isActive={showUpdateCreditLimit}
              closeModal={() => setShowUpdateCreditLimit(false)}
              width={500}
            >
              <secondaryComponents.UpdateCreditLogModal
                closeModal={() => setShowUpdateCreditLimit(false)}
                customer={customer}
                type="credit"
              />
            </secondaryComponents.Modal>
          </secondaryComponents.NavigateWithPermission>
        </>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default CreditLogDetails;
