import "./Profile.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import ProfileLogic from "./ProfileLogic";
import { motion } from "framer-motion";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import Icon from "../../../../assets/icons";
import CopyToClipboard from "react-copy-to-clipboard";

const Profile = () => {
  const { business_name, first_name, last_name, referral_code, triggerToast } =
    ProfileLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Settings - Profile"
      description="Edit your profile details on lumi merchant"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`profile-page ${globalStyles.pageColumnGaps}`}
        >
          <div className={`${globalStyles.pageHeaders}`}>
            <div>
              <h4 className="mb-0">Profile</h4>
              <p>View and update your personal and business information</p>
            </div>

            <div
              className={`${globalStyles.cardWithBackground} flex-row white align-items-center`}
            >
              <div
                className="pr-3"
                style={{ borderRight: "1px solid #696677", height: "60px" }}
              >
                <p className="mb-1">Copy</p>

                <CopyToClipboard
                  text={`${window.location.origin}/signup?referral_code=${referral_code}`}
                  onCopy={() => triggerToast("Copied", "success")}
                >
                  <primaryComponents.Button
                    classNames={"btn btn--outline smallBtn"}
                    style={{ padding: "0 10px" }}
                  >
                    <img width="15px" src={Icon.copy} alt="" />
                  </primaryComponents.Button>
                </CopyToClipboard>
              </div>
              <div className="pl-3 pr-3 d-flex align-items-center">
                <div>
                  <h6 className="mb-0">Your business referral link</h6>
                  <p>{`${window.location.origin}/signup?referral_code=${referral_code}`}</p>
                </div>
              </div>
              {/* <div className="pl-3">
                <p className="mb-1">Share</p>
                <div className="d-flex" style={{ gap: "15px" }}>
                  <a>
                    <XIcon />
                  </a>
                  <a>
                    <InstagramIcon />
                  </a>
                  <a>
                    <WhatAppIcon />
                  </a>
                </div>
              </div> */}
            </div>
          </div>

          {/* personal information */}
          <div>
            <h5 className="font-weight-normal mb-3">Personal Information</h5>
            <div className="profile-page__section">
              <div className="profile-page__section__left">
                <div className="profile-page__section__left__avatar">
                  {first_name?.charAt(0)}
                  {last_name?.charAt(0)}
                </div>
                <p className="mt-4 dark--text">
                  {first_name} {last_name}
                </p>
              </div>
              <div className="profile-page__section__right">
                <secondaryComponents.ProfilePersonalInfoForm />
              </div>
            </div>
          </div>
          {/* business info form */}
          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Settings"}
            permissionChildKey="can_update_business_profile"
          >
            <div>
              <h5 className="font-weight-normal mb-3 mt-4">
                Business Information
              </h5>
              <div className="profile-page__section">
                <div className="profile-page__section__left">
                  <div className="profile-page__section__left__avatar">
                    {business_name?.charAt(0)}
                  </div>
                  <p className="mt-4 dark--text">{business_name}</p>
                </div>
                <div className="profile-page__section__right">
                  <secondaryComponents.ProfileBusinessInfoForm />
                </div>
              </div>
            </div>
          </secondaryComponents.NavigateWithPermission>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default Profile;
