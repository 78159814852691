import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import styles from "./CreateFoodMenu.module.scss";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import Icon from "../../../../../assets/icons";
import { motion } from "framer-motion";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreateFoodMenu } from "./useCreateFoodMenu";
import { Validation } from "./Validation";
import { Fragment, useEffect } from "react";
import MenuDetails from "./components/MenuDetails";
import { pathConstants } from "../../../../../routes/pathContants";

const storefrontStage = [
  {
    id: 1,
    title: "Storefront Details",
    description: "Select product or services",
  },
  {
    id: 2,
    title: "Customization",
    description: "Customize your storefront to fit your needs.",
  },
  {
    id: 3,
    title: "Shipping Setup",
    description:
      "Tailor your shipping process to perfectly match your business needs",
  },
];

const CreateFoodMenu = () => {
  const navigate = useNavigate();
  const {
    store_id,
    storeList,
    productsList,
    selectedMenuItems,
    isCreatingMenu,
    searchParams,
    isFetchingNextPage,
    isFetchingMenuCategories,
    isFetchingProducts,
    menuDetails,
    menuProperties,
    menuId,
    sortMenuCategories,
    selectedPricingType,
    setSelectedPricingType,
    setMenuDetails,
    autoScrollRef,
    setSelectedMenuItems,
    onSubmit,
    filterProductByStore,
    handleSearchMenuItems,
    setSortMenuCategories,
    mutateSortCategories,
  } = useCreateFoodMenu();
  const { pathname } = useLocation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = Validation({ menuDetails });

  useEffect(() => {
    if (menuId !== undefined && menuProperties !== undefined) {
      setMenuDetails((prev) => ({
        ...prev,
        menuName: menuProperties?.name,
        allowCheckout: menuProperties?.allow_payment,
      }));
      setValue("menuName", menuProperties?.name);
    }
  }, [menuProperties]);

  return (
    <secondaryComponents.MetaSetter
      title={"Lumi Merchant | Inventory - Create Menu"}
      description={"Create a Menu"}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className={`${globalStyles.pagePaddings} ${styles.createFoodMenu}`}
      >
        {pathname.includes("create") ? (
          <div className={`${styles.createFoodMenu__header}`}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => {
                  if (pathname.includes("customization")) {
                    navigate(
                      pathConstants.INVENTORY_MENU_CREATE_EDIT({
                        menuId: menuId,
                      }) + `?store_id=${store_id}`,
                    );
                  } else {
                    navigate(-1);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  className="mr-3"
                  style={{ fontSize: "18px" }}
                />
                <span>Back</span>
              </primaryComponents.Button>
              <h4 className="ml-2 mb-0">
                {pathname.includes("create")
                  ? "Create Storefront"
                  : "Edit Storefront"}
              </h4>
            </div>

            {!isFetchingProducts ? (
              <primaryComponents.Button
                classNames="btn btn--primary ml-auto"
                onClick={() => {
                  if (pathname.endsWith("create")) {
                    handleSubmit(onSubmit)();
                  } else if (pathname.includes("customization")) {
                    navigate(
                      pathConstants.INVENTORY_MENU_CREATE_SHIPPING({
                        menuId: menuId,
                      }) + `?store_id=${store_id}`,
                    );
                  } else {
                    navigate(pathConstants.INVENTORY_MENU);
                  }
                }}
                isLoading={isCreatingMenu}
                isDisabled={isCreatingMenu}
                loadingText={"Saving..."}
              >
                {pathname.includes("shipping") ? (
                  <img className="mr-2" src={Icon.saveIconAlt} alt="" />
                ) : (
                  <img className="mr-2" src={Icon.arrow_right} alt="" />
                )}
                <span>Next</span>
              </primaryComponents.Button>
            ) : null}
          </div>
        ) : (
          <div className={styles.editHeadingContainer}>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => {
                pathname.includes("customization")
                  ? navigate(pathConstants.INVENTORY_MENU)
                  : navigate(-1);
              }}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
            <div className={styles.editHeading}>
              {pathname.includes("customization") ? (
                <div>
                  <h4>{menuDetails?.menuName}</h4>
                  <p>Customize your storefront to fit your needs.</p>
                </div>
              ) : (
                <div>
                  <h4>Edit Storefront</h4>
                  <p>Update your storefront information</p>
                </div>
              )}
              {!pathname.includes("customization") && (
                <primaryComponents.Button
                  classNames="btn btn--primary ml-auto"
                  onClick={() => {
                    if (pathname.includes("customization")) {
                      mutateSortCategories({
                        menu_category_ids: sortMenuCategories.map(
                          (item) => `${item.id}`,
                        ),
                      });
                    } else {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  isLoading={isCreatingMenu}
                  isDisabled={isCreatingMenu}
                  loadingText={"Saving..."}
                >
                  <img className="mr-2" src={Icon.saveIconAlt} alt="" />
                  <span>Save</span>
                </primaryComponents.Button>
              )}
            </div>
          </div>
        )}

        <div
          style={{
            backgroundColor: "white",
            // backgroundColor:
            //   pathname.includes("customization")
            //     ? undefined
            //     : "white",
            // padding: 0,
          }}
          className={styles.createFoodMenu__content}
        >
          <div
            style={{
              borderBottom: 0,
              padding: pathname.includes("customization") ? 0 : undefined,
            }}
            className={`${styles.topSection} ${globalStyles.tableHeader}`}
          >
            {pathname.includes("create") && (
              <div className={styles.menuCategory}>
                <div className={styles.lineContainer}>
                  {storefrontStage.map((_, index) => (
                    <Fragment key={index}>
                      <div
                        className={styles.stageNumber}
                        style={{
                          background:
                            index + 1 === 1
                              ? undefined
                              : index + 1 === 2 &&
                                  pathname.includes("customization")
                                ? "#7647ee"
                                : pathname.includes("shipping")
                                  ? "#7647ee"
                                  : "#F2EDFE",
                        }}
                      >
                        {index + 1 === 1 ? (
                          pathname.endsWith("create") ? (
                            <p>{index + 1}</p>
                          ) : (
                            <img src={Icon.check} alt="" />
                          )
                        ) : index + 1 === 2 ? (
                          pathname.endsWith("create") ||
                          pathname.includes("customization") ? (
                            <p
                              style={{
                                color: pathname.includes("customization")
                                  ? "#ffffff"
                                  : "#7647ee",
                              }}
                            >
                              {index + 1}
                            </p>
                          ) : (
                            <img src={Icon.check} alt="" />
                          )
                        ) : (
                          <p
                            style={{
                              color: pathname.includes("shipping")
                                ? "#ffffff"
                                : "#7647ee",
                            }}
                          >
                            {index + 1}
                          </p>
                        )}
                      </div>
                      {storefrontStage.length !== index + 1 && (
                        <div className={styles.line} />
                      )}
                    </Fragment>
                  ))}
                </div>
                <div className={styles.menuCategoryText}>
                  {storefrontStage.map((item, index) => (
                    <div key={index}>
                      <p
                        style={{
                          color:
                            index + 1 === 1 && pathname.endsWith("create")
                              ? "#7647EE"
                              : index + 1 === 2 &&
                                  pathname.includes("customization")
                                ? "#7647EE"
                                : index + 1 === 3 &&
                                    pathname.includes("shipping")
                                  ? "#7647EE"
                                  : "#696677",
                        }}
                        className={styles.menuCategoryHeading}
                      >
                        {item.title}
                      </p>
                      <p className={styles.description}>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {pathname.includes("create") && (
              <div className={styles.menuContainerMobile}>
                <div className={styles.menuContainerMobileHeading}>
                  <h4>
                    {pathname.endsWith("create")
                      ? storefrontStage[0].title
                      : pathname.includes("customization")
                        ? storefrontStage[1].title
                        : storefrontStage[2].title}
                  </h4>
                  <p>
                    <span>
                      {pathname.endsWith("create")
                        ? storefrontStage[0].id
                        : pathname.includes("customization")
                          ? storefrontStage[1].id
                          : storefrontStage[2].id}
                    </span>
                    /3
                  </p>
                </div>
                <p className={styles.menuContainerMobileDesc}>
                  {pathname.endsWith("create")
                    ? storefrontStage[0].description
                    : pathname.includes("customization")
                      ? storefrontStage[1].description
                      : storefrontStage[2].description}
                </p>
                <div className={styles.menuContainerMobileLine}>
                  <div
                    style={{
                      width: pathname.endsWith("create")
                        ? `${(1 / storefrontStage.length) * 100}%`
                        : pathname.includes("customization")
                          ? `${(2 / storefrontStage.length) * 100}%`
                          : `${(3 / storefrontStage.length) * 100}%`,
                    }}
                    className={styles.menuContainerMobileLineFill}
                  />
                </div>
              </div>
            )}
            {pathname.endsWith("create") || pathname.endsWith("edit") ? (
              <MenuDetails
                menuDetails={menuDetails}
                setMenuDetails={setMenuDetails}
                menuProperties={menuProperties}
                storeList={storeList}
                selectedMenuItems={selectedMenuItems}
                searchParams={searchParams}
                filterProductByStore={filterProductByStore}
                handleSearchMenuItems={handleSearchMenuItems}
                selectedPricingType={selectedPricingType}
                setSelectedPricingType={setSelectedPricingType}
                control={control}
                errors={errors}
              />
            ) : (
              <Outlet
                context={{
                  menuProperties,
                  isFetchingMenuCategories,
                  sortMenuCategories,
                  setSortMenuCategories,
                }}
              />
            )}
          </div>
          {(pathname.endsWith("create") || pathname.endsWith("edit")) && (
            <div>
              <secondaryComponents.LoaderHelper
                classNames={"mt-5"}
                isLoading={isFetchingProducts}
              >
                {menuDetails?.store !== "" ? (
                  <secondaryComponents.FoodMenuTable
                    menuItems={productsList}
                    menuProperties={menuProperties}
                    selectedPricingType={selectedPricingType}
                    selectedMenuItems={selectedMenuItems}
                    setSelectedMenuItems={setSelectedMenuItems}
                    autoScrollRef={autoScrollRef}
                  />
                ) : (
                  <h4 className="text-center my-4">
                    Please select a store to proceed with storefront creation
                  </h4>
                )}
              </secondaryComponents.LoaderHelper>
              <secondaryComponents.LoaderHelper
                classNames="mt-4"
                isLoading={isFetchingNextPage && !isFetchingProducts}
              />
            </div>
          )}
        </div>
      </motion.div>
    </secondaryComponents.MetaSetter>
  );
};

export default CreateFoodMenu;
