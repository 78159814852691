/* eslint-disable jsx-a11y/anchor-is-valid */
import { motion } from "framer-motion";
import secondaryComponents from "../..";
import styles from "./CompositeDetailsForm.module.scss";
import primaryComponents from "../../../primaryComponents";
import { Validation } from "./Validation";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../../assets/icons";
import Images from "../../../../assets/images";
import { useEffect } from "react";
import sharedStyles from "../../SingleProductForm/SingleProductForm.module.scss";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const CompositeDetailsForm = ({ compositeProperties }) => {
  const {
    setCurrentStep,
    createOrUpdateProduct,
    rootFormDetails,
    storeProperties,
    setIsStorePropertiesUpdated,
    setRootFormDetails,
    setStoreProperties,
    isEditMode,
    isSubmiting,
    inputRef,
    sections,
    isLoadingSections,
    isCreatePricing,
    categories,
    storeList,
    isUploadingImage,
    stockUnits,
    isLoadingPricingTypes,
    isLoadingStockUnit,
    addNewCategory,
    setIsCreatePricing,
    pricingTypes,
    removePricingForStoreProperty,
    addNewPricingForStoreProperty,
    compositeRootFormDetails,
    setCompositeRootFormDetails,
    booleanLabel,
    handleMultipleImageUpload,
    productImages,
    setProductImages,
    deleteImage,
    isUpdatingComposite,
  } = compositeProperties;

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    setValue,
  } = Validation({
    rootFormDetails,
    storeProperties,
  });

  useEffect(() => {
    window.setProductFormValue = setValue;
  }, []);

  return (
    <>
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        className={`${styles.compositeDetailsForm} ${globalStyles.pageColumnGaps}`}
      >
        <div>
          <h4>Composite Details</h4>
          <p>
            Please provide the following information about your composite
            product
          </p>
        </div>

        <div className="d-flex" style={{ gap: "20px" }}>
          <secondaryComponents.WalletOption
            title="On Demand"
            subtitle="Customers will have to wait for me to produce it."
            onChange={(val) => {
              setCompositeRootFormDetails((prevState) => ({
                ...prevState,
                type: val,
              }));
            }}
            name="compositeType"
            checked={compositeRootFormDetails?.type === "ON_DEMAND"}
            value={"ON_DEMAND"}
            style={{ maxWidth: "none" }}
            isDisabled={isEditMode}
          />
          <secondaryComponents.WalletOption
            title="Stocked"
            subtitle="Already produced and kept in stock for purchase."
            onChange={(val) => {
              setCompositeRootFormDetails((prevState) => ({
                ...prevState,
                type: val,
              }));
            }}
            name="compositeType"
            checked={compositeRootFormDetails?.type === "STOCKED"}
            value={"STOCKED"}
            style={{ maxWidth: "none" }}
            isDisabled={isEditMode}
          />
        </div>

        <form
          id="compositeForm"
          className={styles.formSection}
          onSubmit={handleSubmit(createOrUpdateProduct)}
        >
          <div className={sharedStyles.topFormSection}>
            <div className={sharedStyles.topFormSection__left}>
              {/* product name */}
              <primaryComponents.InputFieldV2
                name="productName"
                control={control}
                register={register}
                placeholder={"Enter product name"}
                classNames="white"
                label="Composite Product Name"
                value={rootFormDetails.name}
                isRequired
                errorMessage={errors?.productName}
                onKeyUp={() => errors?.productName && trigger("productName")}
                onChange={({ target: { value } }) => {
                  setRootFormDetails((prevState) => ({
                    ...prevState,
                    name: value,
                  }));
                }}
              />

              <div className={sharedStyles.flexArrange}>
                {/* category field */}
                <div className="mb-2 w-100">
                  <label>
                    <span className="error--text">*</span>Category
                  </label>
                  <Controller
                    name="productCategory"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <primaryComponents.TagsMultiSelect
                        options={categories}
                        idKey="id"
                        nameKey="name"
                        placeholder="Select category"
                        onChange={(item) => {
                          onChange(item?.label);
                          setRootFormDetails((prevState) => ({
                            ...prevState,
                            category: item,
                          }));
                        }}
                        onCreateOption={(value) => {
                          addNewCategory(value);
                          onChange(value);
                        }}
                        isSearchable={true}
                        isMulti={false}
                        value={rootFormDetails?.category}
                        giveControl={false}
                        height="50px"
                        fontSize="16px"
                      />
                    )}
                  />
                  {/* error message */}
                  {errors.productCategory?.message && (
                    <p className="text-danger">
                      <FontAwesomeIcon
                        icon={["fas", "exclamation-circle"]}
                        className="mr-2"
                        style={{ fontSize: "13px" }}
                      />
                      <span style={{ fontSize: "13px" }}>
                        {errors.productCategory?.message}
                      </span>
                    </p>
                  )}
                </div>

                {/* show on sell mode */}
                <primaryComponents.SelectFieldV2
                  classNames="w-100 mt-2"
                  name="showProductOnSellMode"
                  control={control}
                  label="Show this service in sell mode?"
                  isRequired={true}
                  value={
                    booleanLabel[rootFormDetails.show_product_in_sell_mode]
                  }
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  nameKey="label"
                  idKey="value"
                  onChange={(value) => {
                    setRootFormDetails((prevState) => ({
                      ...prevState,
                      show_product_in_sell_mode: value,
                    }));
                  }}
                  isSearchable={false}
                />
              </div>

              <div className={sharedStyles.flexArrange}>
                {/* unit */}
                <primaryComponents.SelectFieldV2
                  name="unit"
                  control={control}
                  label="Product Unit"
                  value={rootFormDetails.unit?.label || "Select..."}
                  options={stockUnits}
                  nameKey="unit"
                  idKey="id"
                  onChange={(value, label) => {
                    setRootFormDetails((prevState) => ({
                      ...prevState,
                      unit: { value, label },
                    }));
                  }}
                  classNames="w-100"
                  isDisabled={isLoadingStockUnit}
                />

                <secondaryComponents.LoaderHelper
                  isLoading={isLoadingSections}
                  classNames={"mt-2"}
                >
                  {sections?.length > 0 ? (
                    <primaryComponents.SelectFieldV2
                      name="section"
                      control={control}
                      label="Select kitchen"
                      value={
                        sections?.find(
                          (section) => section.id === rootFormDetails?.section,
                        )?.name || "Select..."
                      }
                      options={sections}
                      nameKey="name"
                      idKey="id"
                      classNames="w-100"
                      onChange={(value) => {
                        setRootFormDetails((prevState) => ({
                          ...prevState,
                          section: value,
                        }));
                      }}
                      isSearchable={true}
                    />
                  ) : null}
                </secondaryComponents.LoaderHelper>
              </div>

              {/* store field  */}
              <primaryComponents.MultipleSelectFieldV2
                name="stores"
                control={control}
                options={storeList}
                isRequired={true}
                value={rootFormDetails?.stores}
                placeholder="Select store..."
                errorMessage={errors?.stores}
                idKey="id"
                nameKey="name"
                label="Select Store To Make Product Available At"
                onChange={(values) => {
                  setRootFormDetails((prevState) => ({
                    ...prevState,
                    stores: values,
                  }));
                  setIsStorePropertiesUpdated(true);
                }}
              />

              <div className={sharedStyles.flexArrange}>
                {/* product sku */}
                <primaryComponents.InputFieldV2
                  name="productSku"
                  control={control}
                  label="Product SKU"
                  placeholder="Enter SKU"
                  classNames="white"
                  value={rootFormDetails.sku}
                  errorMessage={errors?.productSku}
                  onKeyUp={() => errors?.productSku && trigger("productSku")}
                  onChange={({ target: { value } }) => {
                    setRootFormDetails((prevState) => ({
                      ...prevState,
                      sku: value,
                    }));
                  }}
                />
                {/* bar code */}
                {!rootFormDetails?.has_variant ? (
                  <primaryComponents.InputFieldV2
                    inputIcon={Icon.barCodeIcon}
                    name="barcode"
                    control={control}
                    label="Scan Barcode"
                    placeholder="Scan barcode"
                    classNames="white"
                    value={rootFormDetails.barcode}
                    errorMessage={errors?.barcode}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                      errors?.barcode && trigger("barcode");
                    }}
                    onChange={(e) => {
                      setRootFormDetails((prevState) => ({
                        ...prevState,
                        barcode: e?.target?.value,
                      }));
                    }}
                  />
                ) : null}
              </div>
            </div>

            {/* profile section */}
            <div className={styles.topFormSection__right}>
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                name="product-img"
                id="product-img"
                onChange={handleMultipleImageUpload}
              />
              <primaryComponents.Button
                classNames={`btn btn--outline ${styles.uploadBtn}`}
                onClick={() => inputRef.current.click()}
                isLoading={isUploadingImage}
                loadingText={"uploading..."}
                primarySpinner={false}
                type="button"
              >
                <img className="mr-1" src={Icon.addCriclePlus} alt="" />
                Add Product Images
              </primaryComponents.Button>
              <div className={styles.imagesContainer}>
                {productImages?.length > 0 ? (
                  productImages?.map((image, index) => (
                    <div key={index} className={styles.imageDeleteContainer}>
                      <div className={styles.imageContainer}>
                        <img
                          src={
                            image.url
                              ? image.url
                              : image.preview
                                ? image.preview
                                : Images.product_avatar
                          }
                          alt=""
                        />
                      </div>
                      <div
                        onClick={() => {
                          if (image.url) {
                            setProductImages((prev) =>
                              prev.filter((item) => item.id !== image.id),
                            );
                            deleteImage(image.id);
                          } else {
                            setProductImages((prev) =>
                              prev.filter(
                                (item) => item.preview !== image.preview,
                              ),
                            );
                          }
                        }}
                        className={styles.deleteContainer}
                      >
                        <img src={Icon.deleteIcon} alt="" />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.imageDeleteContainer}>
                    <div className={styles.imageContainer}>
                      <img src={Images.product_avatar} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <h5 className="mt-5">Composite Product Pricing(s)</h5>

          <div className={sharedStyles.bottomFormSection}>
            {/*  product without variants */}
            <>
              {storeProperties?.length > 0 ? (
                <>
                  {storeProperties?.map((item, storeIndex) => (
                    <div
                      className={sharedStyles.priceCover}
                      key={item.store_id}
                    >
                      <div>
                        <h6>Enter {item?.store_name || ""} pricing</h6>
                        <div
                          className={`${sharedStyles.priceSection} ${sharedStyles.priceSectionBigInput}`}
                        >
                          {/* cost price */}
                          <primaryComponents.InputFieldV2
                            name={`storeProperties.${storeIndex}.cost_price`}
                            control={control}
                            type="number"
                            step={0.01}
                            label={`Cost Price (${
                              storeList.find(
                                (store) => store?.id === item?.store_id,
                              )?.currency_symbol
                            })`}
                            placeholder="0"
                            classNames="white"
                            isRequired
                            isDisabled={
                              storeIndex > 0 &&
                              rootFormDetails?.use_same_pricing
                            }
                            value={storeProperties[storeIndex].cost_price}
                            errorMessage={
                              errors?.storeProperties?.[storeIndex]?.cost_price
                            }
                            onKeyUp={() =>
                              errors?.storeProperties?.[storeIndex]
                                ?.cost_price &&
                              trigger(
                                `storeProperties.${storeIndex}.cost_price`,
                              )
                            }
                            onChange={({ target: { value } }) => {
                              const newStoreProperties = [...storeProperties];
                              newStoreProperties[storeIndex].cost_price = value;
                              setStoreProperties(newStoreProperties);
                              setIsStorePropertiesUpdated(true);
                            }}
                          />

                          {compositeRootFormDetails?.type === "STOCKED" ? (
                            <>
                              {!isEditMode ? (
                                // stock quantity
                                <primaryComponents.InputFieldV2
                                  name={`storeProperties.${storeIndex}.stock_quantity`}
                                  control={control}
                                  type="number"
                                  step={0.01}
                                  min={0.01}
                                  label="Initial Stock"
                                  placeholder="0"
                                  classNames="white"
                                  value={
                                    storeProperties[storeIndex]
                                      ?.stock_quantity || ""
                                  }
                                  errorMessage={
                                    errors?.storeProperties?.[storeIndex]
                                      ?.stock_quantity
                                  }
                                  onKeyUp={() =>
                                    errors?.storeProperties?.[storeIndex]
                                      ?.stock_quantity &&
                                    trigger(
                                      `storeProperties.${storeIndex}.stock_quantity`,
                                    )
                                  }
                                  onChange={({ target: { value } }) => {
                                    const newStoreProperties = [
                                      ...storeProperties,
                                    ];
                                    newStoreProperties[
                                      storeIndex
                                    ].stock_quantity =
                                      value === "" ? undefined : value;
                                    setStoreProperties(newStoreProperties);
                                    setIsStorePropertiesUpdated(true);
                                  }}
                                />
                              ) : null}

                              {/* minimum stock quantity */}
                              <primaryComponents.InputFieldV2
                                name={`storeProperties.${storeIndex}.minimum_stock_quantity`}
                                control={control}
                                type="number"
                                step={0.01}
                                min={0}
                                label="Stock Level Alert"
                                placeholder="0"
                                classNames="white"
                                value={
                                  storeProperties[storeIndex]
                                    ?.minimum_stock_quantity || ""
                                }
                                errorMessage={
                                  errors?.storeProperties?.[storeIndex]
                                    ?.minimum_stock_quantity
                                }
                                onKeyUp={() =>
                                  errors?.storeProperties?.[storeIndex]
                                    ?.minimum_stock_quantity &&
                                  trigger(
                                    `storeProperties.${storeIndex}.minimum_stock_quantity`,
                                  )
                                }
                                onChange={({ target: { value } }) => {
                                  const newStoreProperties = [
                                    ...storeProperties,
                                  ];
                                  newStoreProperties[
                                    storeIndex
                                  ].minimum_stock_quantity =
                                    value === "" ? undefined : value;
                                  setStoreProperties(newStoreProperties);
                                  setIsStorePropertiesUpdated(true);
                                }}
                              />

                              {/* Date input*/}
                              {storeProperties[storeIndex]?.stock_quantity >
                                0 || isEditMode ? (
                                <primaryComponents.DateInputV2
                                  name={`storeProperties.${storeIndex}.expiry_date`}
                                  control={control}
                                  label="Expiry Date"
                                  placeholder="Expiry date"
                                  classNames="white"
                                  value={
                                    storeProperties?.[storeIndex]
                                      ?.expiry_date || ""
                                  }
                                  errorMessage={
                                    errors?.storeProperties?.[storeIndex]
                                      ?.expiry_date
                                  }
                                  onKeyUp={() =>
                                    errors?.storeProperties?.[storeIndex]
                                      ?.expiry_date &&
                                    trigger(
                                      `storeProperties.${storeIndex}.expiry_date`,
                                    )
                                  }
                                  onChange={({ target: { value } }) => {
                                    const newStoreProperties = [
                                      ...storeProperties,
                                    ];
                                    newStoreProperties[storeIndex].expiry_date =
                                      value === "" ? undefined : value;
                                    setStoreProperties(newStoreProperties);
                                    setIsStorePropertiesUpdated(true);
                                  }}
                                />
                              ) : null}
                            </>
                          ) : null}

                          {item?.pricings?.map(
                            (pricingProperty, pricingIndex) => {
                              const pricingDetailsProperty = pricingTypes?.find(
                                (pricingItem) =>
                                  pricingItem?.id ===
                                  pricingProperty?.pricing_type_id,
                              );

                              return (
                                <div
                                  className={`${sharedStyles.priceSection} ${globalStyles.borderTopPurple} ${globalStyles.borderTop} mt-0 pt-3`}
                                  style={{ minWidth: "100%" }}
                                  key={pricingProperty?.uuid}
                                >
                                  {/* pricing type */}
                                  <secondaryComponents.LoaderHelper
                                    isLoading={isLoadingPricingTypes}
                                    classNames={"mt-2"}
                                  >
                                    <div style={{ maxWidth: "180px" }}>
                                      <label htmlFor="">
                                        <span className="text-danger">*</span>

                                        <span>
                                          Price Type
                                          {item?.pricings?.length ===
                                          pricingIndex + 1 ? (
                                            <>
                                              {" ( "}
                                              <span
                                                className="purple--text"
                                                style={{
                                                  textDecoration: "underline",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  setIsCreatePricing(true)
                                                }
                                              >
                                                Create new
                                              </span>
                                              {" )"}
                                            </>
                                          ) : null}
                                        </span>
                                      </label>
                                      <primaryComponents.SelectFieldV2
                                        name={`storeProperties.${storeIndex}.pricings.${pricingIndex}.pricing_type_id`}
                                        control={control}
                                        giveControl={false}
                                        value={{
                                          label: pricingDetailsProperty?.label,
                                          value: pricingDetailsProperty?.id,
                                        }}
                                        options={pricingTypes}
                                        nameKey="label"
                                        idKey="id"
                                        onChange={(value) => {
                                          const newStoreProperties = [
                                            ...storeProperties,
                                          ];
                                          newStoreProperties[
                                            storeIndex
                                          ].pricings[
                                            pricingIndex
                                          ].pricing_type_id = value;
                                          setStoreProperties(
                                            newStoreProperties,
                                          );
                                          setIsStorePropertiesUpdated(true);
                                        }}
                                        errorMessage={
                                          errors?.storeProperties?.[storeIndex]
                                            ?.pricings?.[pricingIndex]
                                            ?.pricing_type_id
                                        }
                                        isDisabled={
                                          (storeIndex > 0 &&
                                            rootFormDetails?.use_same_pricing) ||
                                          pricingIndex === 0
                                        }
                                        isSearchable={false}
                                      />
                                    </div>
                                  </secondaryComponents.LoaderHelper>

                                  {/* selling price */}
                                  <primaryComponents.InputFieldV2
                                    name={`storeProperties.${storeIndex}.pricings.${pricingIndex}.price`}
                                    control={control}
                                    type="number"
                                    step={0.01}
                                    label={`Selling Price (${
                                      storeList.find(
                                        (store) => store?.id === item?.store_id,
                                      )?.currency_symbol
                                    })`}
                                    placeholder="0"
                                    classNames="white"
                                    isRequired
                                    isDisabled={
                                      storeIndex > 0 &&
                                      rootFormDetails?.use_same_pricing
                                    }
                                    value={pricingProperty?.price}
                                    errorMessage={
                                      errors?.storeProperties?.[storeIndex]
                                        ?.pricings?.[pricingIndex]?.price
                                    }
                                    onKeyUp={() =>
                                      errors?.storeProperties?.[storeIndex]
                                        ?.pricings?.[pricingIndex]?.price &&
                                      trigger(
                                        `storeProperties.${storeIndex}.pricings.${pricingIndex}.price`,
                                      )
                                    }
                                    onChange={({ target: { value } }) => {
                                      const newStoreProperties = [
                                        ...storeProperties,
                                      ];
                                      newStoreProperties[storeIndex].pricings[
                                        pricingIndex
                                      ].price = value;
                                      setStoreProperties(newStoreProperties);
                                      setIsStorePropertiesUpdated(true);
                                    }}
                                  />

                                  {/* discount */}
                                  <primaryComponents.PercentField
                                    label="Selling price discount"
                                    name={`storeProperties.${storeIndex}.pricings.${pricingIndex}.discount_rate`}
                                    control={control}
                                    value={pricingProperty?.discount_rate}
                                    isDisabled={
                                      storeIndex > 0 &&
                                      rootFormDetails?.use_same_pricing
                                    }
                                    onChange={({ percentValue }) => {
                                      const newStoreProperties = [
                                        ...storeProperties,
                                      ];
                                      newStoreProperties[storeIndex].pricings[
                                        pricingIndex
                                      ].discount_rate = percentValue;
                                      setStoreProperties(newStoreProperties);
                                      setIsStorePropertiesUpdated(true);
                                    }}
                                    dividedValue={
                                      pricingProperty?.price === ""
                                        ? 0
                                        : pricingProperty?.price
                                    }
                                  />

                                  {/* discounted selling price */}
                                  <primaryComponents.InputField
                                    classNames="white"
                                    label={`Discounted selling price (${
                                      storeList.find(
                                        (store) => store?.id === item?.store_id,
                                      )?.currency_symbol
                                    })`}
                                    isDisabled={true}
                                    value={(() => {
                                      let sellingPrice =
                                        pricingProperty?.price === ""
                                          ? 0
                                          : pricingProperty?.price;
                                      let discountPercent =
                                        pricingProperty?.discount_rate === ""
                                          ? 0
                                          : pricingProperty?.discount_rate;
                                      let discount_rate =
                                        (sellingPrice * discountPercent) / 100;

                                      let discountedSellingPrice =
                                        sellingPrice - discount_rate;

                                      return discountedSellingPrice;
                                    })()}
                                  />

                                  {/* profit */}
                                  <primaryComponents.InputField
                                    classNames="white"
                                    label={`Profit (${
                                      storeList.find(
                                        (store) => store?.id === item?.store_id,
                                      )?.currency_symbol
                                    })`}
                                    isDisabled={true}
                                    value={(
                                      pricingProperty?.price -
                                      (pricingProperty?.price *
                                        pricingProperty?.discount_rate) /
                                        100 -
                                      item?.cost_price
                                    ).toFixed(2)}
                                  />

                                  {pricingIndex > 0 ? (
                                    <div
                                      className="d-flex align-items-center my-auto"
                                      style={{
                                        minWidth: "fit-content",
                                        maxWidth: "fit-content",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn--outline-red"
                                        style={{
                                          height: "30px",
                                          width: "28px",
                                          padding: "0",
                                          borderRadius: "3px",
                                        }}
                                        onClick={() =>
                                          removePricingForStoreProperty(
                                            storeIndex,
                                            pricingIndex,
                                          )
                                        }
                                      >
                                        <img src={Icon.deleteIcon} alt="" />
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              );
                            },
                          )}
                        </div>

                        {/* use across */}
                        <div className={sharedStyles.useAcrossSection}>
                          <a
                            className="purple--text"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              addNewPricingForStoreProperty(storeIndex)
                            }
                          >
                            + Add another price option
                          </a>
                          {storeIndex === 0 ? (
                            <label
                              className="d-flex align-items-center"
                              style={{ cursor: "pointer" }}
                              htmlFor="useSamePricing"
                            >
                              <primaryComponents.Checkbox
                                name="useSamePricing"
                                id="useSamePricing"
                                isChecked={rootFormDetails?.use_same_pricing}
                                onChange={(value) => {
                                  setRootFormDetails((prevState) => ({
                                    ...prevState,
                                    use_same_pricing: value,
                                  }));
                                  if (value) {
                                    if (!rootFormDetails.has_variant)
                                      setIsStorePropertiesUpdated(true);
                                  }
                                }}
                              />
                              <span className="ml-2 mt-1">
                                Use this pricing properties across other stores
                              </span>
                            </label>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <h5 className="text-center my-4">
                  Please select stores to proceed with pricing
                </h5>
              )}
            </>
          </div>
        </form>

        <div className={styles.buttonSection}>
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            style={{ width: "200px" }}
            onClick={() => setCurrentStep(0)}
          >
            Back
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            form="compositeForm"
            type="submit"
            isDisabled={isSubmiting || isUpdatingComposite}
            isLoading={isSubmiting || isUpdatingComposite}
            loadingText={isEditMode ? "Updating..." : "Creating..."}
          >
            {isEditMode
              ? "Update Composite Product"
              : "Create Composite Product"}
          </primaryComponents.Button>
        </div>
      </motion.div>
      <secondaryComponents.Modal
        isActive={isCreatePricing}
        closeModal={() => setIsCreatePricing(false)}
        width={400}
      >
        <secondaryComponents.CreatePricingType
          closeWidget={() => setIsCreatePricing(false)}
        />
      </secondaryComponents.Modal>
    </>
  );
};
export default CompositeDetailsForm;
