import secondaryComponents from "..";
import Images from "../../../assets/images";
import styles from "./ReconcileStockEditView.module.scss";
import "./ReconcileStockEditView.scss";
import useReconcileStockEditView from "./useReconcileStockEditView";

const ReconcileStockEditView = ({
  store_currency,
  products,
  autoScrollRef,
  setReconciliedData,
  discrepancyReasons,
  reconciliedData,
  isReviewMode = false,
}) => {
  const { instance } = useReconcileStockEditView({
    store_currency,
    products,
    setReconciliedData,
    discrepancyReasons,
    isReviewMode,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={`${styles.reconcileStockEditView} reconcileStockEditView`}>
      <div className={styles.reconcileStockEditView__content}>
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          // useDefaultTableMobile={false}
          autoScrollRef={autoScrollRef}
          handleScroll={true}
        >
          <div className="text-center mt-5 mb-5">
            <img src={Images.reconciliationEmptyState} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have no product for reconciliation yet
            </h4>
          </div>
        </secondaryComponents.TableHelper>
      </div>
    </div>
  );
};
export default ReconcileStockEditView;
