import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { useDownloadStatementMutation } from "../../../../hooks/api/mutations/useInventory";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import { useParams } from "react-router-dom";

const useDownloadStatement = ({ closeWidget }) => {
  const triggerToast = useContext(ToastContext);
  const { id } = useParams();

  const [statementFormat, setStatementFormat] = useState("PDF");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const downloadStatement = useDownloadStatementMutation();

  const handleDownloadStatement = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));

    downloadStatement.mutate(
      cleanObject({
        store_wallet_id: id,
        type: statementFormat.toLowerCase(),
        start_date: startDate,
        end_date: endDate,
      }),
      {
        onSuccess: () => {
          triggerToast(
            "Statement of account downloaded successfully",
            "success",
          );
          closeWidget();
        },

        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  return {
    statementFormat,
    setStatementFormat,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    handleDownloadStatement,
    isLoading: downloadStatement.isLoading,
  };
};

export default useDownloadStatement;
