import { useTable } from "react-table";
import { useMemo, useState } from "react";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { DeleteIcon } from "../../../assets/svgIcons";
import { useGetShippingDetails } from "../../../hooks/api/queries";
import { formatCurrency } from "../../../helpers";

const useShippingsListLogic = ({ shippings }) => {
  const [selectedShipping, setSelectedShipping] = useState(null);
  const [selectedDeleteShipping, setSelectedDeleteShipping] = useState(null);

  const { isLoading: isFetchingShippingInfo, data: shippingInfo } =
    useGetShippingDetails(selectedShipping || selectedDeleteShipping);
  const columns = useMemo(
    () => [
      {
        Header: "Shipping Name",
        accessor: "name",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Rate",
        accessor: "rate",
        Cell: ({ cell: { value, row } }) =>
          value
            ? formatCurrency({
                value: value || 0,
                currencyCode:
                  Array.isArray(row?.original?.menus) &&
                  row?.original?.menus.length > 0
                    ? row.original.menus.map(
                        (item) => item?.store?.currency_code,
                      )
                    : "USD",
              })
            : "N/A",
      },
      {
        Header: "Associated Storefront",
        accessor: "menus",
        Cell: ({ cell: { value } }) =>
          value ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                position: "relative",
              }}
            >
              <span
                style={{
                  background: "#F2EDFE",
                  padding: "6px",
                  borderRadius: "8px",
                  fontSize: "10px",
                  alignItems: "center",
                }}
                className="gray--text font-bold"
              >
                {value[0]?.name}
              </span>
              {value.length > 2 && (
                <secondaryComponents.ToolTip
                  showInfoIcon={false}
                  useContentStyling={true}
                  contentStyles={{
                    width: "140%",
                    maxWidth: "500px",
                    padding: "8px",
                    wordWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                    position: "absolute",
                    left: "-80%",
                    right: "0%",
                    top: "120%",
                  }}
                >
                  {value?.map((store, index) => (
                    <span
                      className="purple--text smallest-text-size p-1"
                      key={index}
                      style={{
                        display: "inline-block",
                        maxWidth: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {store?.name},
                    </span>
                  ))}
                </secondaryComponents.ToolTip>
              )}
            </div>
          ) : (
            "N/A"
          ),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell: { row } }) => (
          <div className="d-flex" style={{ gap: "5px" }}>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_edit_shipping_group"
            >
              <primaryComponents.Button
                classNames={"btn btn--primary smallBtn"}
                isDisabled={!row?.original}
                onClick={() => {
                  if (row?.original?.id) {
                    setSelectedShipping(row?.original?.id);
                  }
                }}
              >
                Update
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_delete_shipping_group"
            >
              <primaryComponents.Button
                classNames={"btn btn--outline-red smallBtn"}
                onClick={() => {
                  if (row?.original?.id) {
                    setSelectedDeleteShipping(row?.original?.id);
                  }
                }}
              >
                <DeleteIcon type="menu" />
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>
        ),
      },
    ],
    [selectedShipping, isFetchingShippingInfo],
  );

  const instance = useTable({ columns, data: shippings });
  return {
    instance,
    selectedShipping,
    shippingInfo,
    isFetchingShippingInfo,
    selectedDeleteShipping,
    setSelectedDeleteShipping,
    setSelectedShipping,
  };
};

export default useShippingsListLogic;
