import { useContext, useState } from "react";
import { addDebt } from "../../../../../services/credit";
import { useMutation } from "react-query";
import { ToastContext } from "../../../../../hooks/context";
import { useSelector } from "react-redux";

const useAddDebt = ({ closeWidget, customer, onSuccess }) => {
  const triggerToast = useContext(ToastContext);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);

  const [amount, setAmount] = useState("");
  const [repaymentDate, setRepaymentDate] = useState("");
  const [note, setNote] = useState("");
  const [store, setStore] = useState("");

  const { isLoading, mutate } = useMutation({
    mutationFn: (data) => addDebt(data),
    onSuccess: (data) => {
      triggerToast(data.data.detail, "success");
      onSuccess();
      closeWidget();
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const handleAddDebt = () => {
    mutate({
      amount,
      store_id: store,
      customer_id: customer?.id,
      repayment_date: repaymentDate,
      note,
    });
  };

  return {
    isLoading,
    amount,
    repaymentDate,
    note,
    store,
    setAmount,
    setRepaymentDate,
    setNote,
    handleAddDebt,
    setStore,
    storeList,
  };
};

export default useAddDebt;
