import { pathConstants } from "../routes/pathContants";
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useContext } from "react";
import { platformHandler } from "./context";
import { getPlatform } from "../helpers";
import {
  CustomerIcon,
  ExpenseIcon,
  HomeIcon,
  InventoryIcon,
  LoyaltyIcon,
  MoreIcon,
  MarketsIcon,
  PaymentIcon,
  ReportIcon,
  AccountingsIcon,
} from "../assets/svgIcons";
import { useSelector } from "react-redux";

const useAppSidebarItems = ({ isCollapseSidebar, setActiveSubMenu }) => {
  const { pathname } = useLocation();
  const { platform } = useContext(platformHandler);
  const navigate = useNavigate();
  const { storeList, businessDetails } = useSelector(
    (state) => state.profileDetailsReducer,
  );
  const [search] = useSearchParams();
  const defaultInventoryPath = () => {
    if (search.get("store_id"))
      return (
        pathConstants.INVENTORY_PRODUCTS + "?store_id=" + search.get("store_id")
      );
    else if (storeList?.length > 0)
      return (
        pathConstants.INVENTORY_PRODUCTS + "?store_id=" + storeList?.[0]?.id
      );
    else return pathConstants.INVENTORY_PRODUCTS;
  };

  return {
    appSidebarItems: [
      {
        name: "Dashboard",
        key: 0,
        active: matchPath(getPlatform(platform) + "/dashboard/*", pathname),
        icon: (iconProp = { active: false }) => {
          return (
            <HomeIcon strokeColor={iconProp?.active ? "#7647ee" : undefined} />
          );
        },
        onClick: () => {
          navigate(pathConstants.DASHBOARD_MAIN);
          if (isCollapseSidebar) setActiveSubMenu(11);
        },
      },
      {
        name: "Inventory",
        key: 1,
        active:
          matchPath(getPlatform(platform) + "/inventory/*", pathname) ||
          matchPath(getPlatform(platform) + "/stores/*", pathname) ||
          matchPath(getPlatform(platform) + "/sections/*", pathname) ||
          matchPath(getPlatform(platform) + "/manage-sections/*", pathname),

        icon: (iconProp = { active: false }) => {
          return (
            <InventoryIcon
              strokeColor={iconProp?.active ? "#7647ee" : undefined}
            />
          );
        },
        onClick: () => {
          navigate(defaultInventoryPath());
          // setIsCollapseSidebar(false);
          setActiveSubMenu(11);
        },
        permissionParentKey: "Inventory Management",
        children: [
          {
            name: "Sell Mode",
            permissionParentKey: "Inventory Management",
            permissionChildKey: "can_view_stores",
            routePath: pathConstants.STORES,
            key: 10,
            showBorder: true,
          },
          {
            name: "Kitchen Display",
            routePath: pathConstants.SECTIONS,
            key: 12,
            showBorder: true,
          },
          {
            name: "Manage",
            permissionParentKey: "Inventory Management",
            key: 11,
            active: matchPath(getPlatform(platform) + "/inventory/*", pathname),
            children: [
              {
                name: "Products",
                key: 110,
                routePath: defaultInventoryPath(),
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_products",
              },
              {
                name: "Analytics",
                key: 111,
                routePath: pathConstants.INVENTORY_ANALYTICS,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_analytics",
              },
              {
                name: "Categories",
                key: 112,
                routePath: pathConstants.INVENTORY_CATEGORIES,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_product_category",
              },
              {
                name: "Stores",
                key: 113,
                routePath: pathConstants.INVENTORY_STORES,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_stores",
              },
              {
                name: "Manage Kitchen",
                key: 1111,
                routePath: pathConstants.MANAGE_SECTIONS,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_create_kitchen_sections",
              },
              {
                name: "Storefront",
                key: 1110,
                routePath: pathConstants.INVENTORY_MENU,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_edit_and_create_menu",
              },
              {
                name: "Suppliers",
                key: 114,
                routePath: pathConstants.INVENTORY_SUPPLIERS,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_edit_and_create_suppliers",
              },
              {
                name: "Sales Transactions",
                key: 115,
                routePath:
                  pathConstants.INVENTORY_SALES_TRANSACTIONS +
                  "?order_source=INTERNAL",
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_sales_transaction",
              },
              {
                name: "Stock History",
                key: 116,
                routePath: pathConstants.INVENTORY_STOCK_HISTORY,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_stock_history",
              },
              {
                name: "Stock Transfer",
                key: 117,
                routePath:
                  pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER_HISTORY,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_stock_history",
              },
              {
                name: "Store Wallets",
                key: 118,
                routePath: pathConstants.INVENTORY_STORE_WALLET_LIST,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_store_wallet",
              },
              {
                name: "Debtors",
                key: 119,
                routePath: pathConstants.INVENTORY_CREDITLOG,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_and_update_credits",
              },
              {
                name: "Reconciliations",
                key: 120,
                routePath: pathConstants.INVENTORY_RECONCILIATIONS,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_create_and_submit_reconciliation",
              },
              {
                name: "Tables",
                key: 121,
                routePath: pathConstants.INVENTORY_TABLES,
                permissionParentKey: "Inventory Management",
                permissionChildKey: "can_view_edit_and_create_tables",
              },
            ],
          },
        ],
      },
      {
        name: "Payments",
        key: 2,
        active: matchPath(getPlatform(platform) + "/payments/*", pathname),
        icon: (iconProp = { active: false, hover: false }) => {
          return (
            <PaymentIcon
              strokeColor={iconProp?.active ? "#7647ee" : undefined}
            />
          );
        },
        onClick: () => {
          navigate(pathConstants.DASHBOARD_OVERVIEW + "?all_business=true");
          // setIsCollapseSidebar(false);
        },
        permissionParentKey: "Payments",
        dontCheckCountry: false,
        children: [
          {
            name: "Overview",
            key: 20,
            permissionParentKey: "Payments",
            permissionChildKey:
              "can_view_payment_dashboard_overview_cash_and_customer_analytics",
            active: matchPath(
              getPlatform(platform) + "/payments/overview/*",
              pathname,
            ),
            children: [
              {
                name: "Summary",
                key: 201,
                routePath:
                  pathConstants.DASHBOARD_OVERVIEW + "?all_business=true",
              },
              {
                name: "Cashflow Analytics",
                key: 202,
                routePath:
                  pathConstants.DASHBOARD_CASHFLOW_ANALYTICS +
                  "?all_business=true",
              },
              {
                name: "Customer Analytics",
                key: 203,
                routePath:
                  pathConstants.DASHBOARD_CUSTOMER_ANALYTICS +
                  "?all_business=true",
              },
            ],
          },
          {
            name: "Transactions",
            key: 21,
            permissionParentKey: "Payments",
            permissionChildKey: "can_view_transactions_history",
            onClick: () => {},
            routePath:
              pathConstants.PAYMENT_TRANSACTIONS_ALL + "?all_business=true",
          },
          {
            name: "Transfers",
            key: 22,
            permissionParentKey: "Payments",
            permissionChildKey: "can_view_transfer",
            active: matchPath(
              getPlatform(platform) + "/payments/transfers/*",
              pathname,
            ),
            children: [
              {
                name: "Approved Transfers",
                key: 221,
                routePath:
                  pathConstants.PAYMENT_TRANSFER_APPROVED +
                  "?status=accepted&all_business=true",
              },
              {
                name: "Pending Transfers",
                key: 222,
                routePath:
                  pathConstants.PAYMENT_TRANSFER_PENDING +
                  "?status=pending_approval&all_business=true",
              },
              {
                name: "Rejected Transfers",
                key: 223,
                routePath:
                  pathConstants.PAYMENT_TRANSFER_REJECTED +
                  "?status=rejected&all_business=true",
              },
              {
                name: "Saved Beneficiaries",
                key: 224,
                routePath:
                  pathConstants.PAYMENT_TRANSFER_SAVEDBENEFICIARY +
                  "?all_business=true",
              },
            ],
          },
          {
            name: "Sub Accounts",
            key: 23,
            permissionParentKey: "Payments",
            routePath: pathConstants.PAYMENT_SUBACCOUNTS,
          },
          {
            name: "POS",
            key: 24,
            permissionParentKey: "Payments",
            permissionChildKey: "can_view_and_request_pos",
            active: matchPath(
              getPlatform(platform) + "/payments/pos/*",
              pathname,
            ),
            children: [
              {
                name: "All Terminals",
                key: 241,
                routePath:
                  pathConstants.PAYMENT_POS_ALL_TERMINALS +
                  "?all_business=true",
              },
              {
                name: "Terminal Requests",
                key: 242,
                routePath:
                  pathConstants.PAYMENT_POS_REQUEST_TERMINAL +
                  "?all_business=true",
              },
            ],
          },
          {
            name: "Tags",
            key: 25,
            permissionParentKey: "Payments",
            permissionChildKey: "can_view_tags",
            routePath: pathConstants.PAYMENT_ALL_TAGS,
          },
        ],
      },
      businessDetails?.allow_ledger_account
        ? {
            name: "Accounting",
            key: 3,
            active: matchPath(
              getPlatform(platform) + "/accountings/*",
              pathname,
            ),
            icon: (iconProp = { active: false, hover: false }) => {
              return (
                <AccountingsIcon
                  strokeColor={iconProp?.active ? "#7647ee" : undefined}
                />
              );
            },
            onClick: () => {
              navigate(
                pathConstants.CASH_ACCOUNTINGS + "?account_type=cash_on_hand",
              );
              // setIsCollapseSidebar(false);
            },
            permissionParentKey: "Accounting",
            dontCheckCountry: false,
            children: [
              {
                name: "Cash On Hand",
                key: 21,
                permissionParentKey: "Accounting",
                permissionChildKey: "can_allow_ledger_account",
                onClick: () => {},
                routePath:
                  pathConstants.CASH_ACCOUNTINGS + "?account_type=cash_on_hand",
              },
              {
                name: "Bank Accounts",
                key: 22,
                permissionParentKey: "Accounting",
                permissionChildKey: "can_allow_ledger_account",
                onClick: () => {},
                routePath:
                  pathConstants.BANK_ACCOUNTINGS + "?account_type=bank_account",
              },
              {
                name: "Bank Transactions",
                key: 23,
                permissionParentKey: "Accounting",
                permissionChildKey: "can_allow_ledger_account",
                routePath:
                  pathConstants.ACCOUNTINGS_TRANSACTION +
                  "?account_type=bank_account",
              },
            ],
          }
        : null,
      {
        name: "Expenses",
        key: 4,
        active: matchPath(getPlatform(platform) + "/expenses/*", pathname),
        icon: (iconProp = { active: false }) => {
          return (
            <ExpenseIcon
              strokeColor={iconProp?.active ? "#7647ee" : undefined}
            />
          );
        },
        onClick: () => {
          navigate(pathConstants.EXPENSES_LIST);
          // setIsCollapseSidebar(false);
        },
        permissionParentKey: "Expense",
        children: [
          {
            name: "Expenses",
            key: 30,
            permissionParentKey: "Expense",
            permissionChildKey: "can_create_expense",
            onClick: () => {},
            routePath: pathConstants.EXPENSES_LIST,
          },
          {
            name: "Analytics",
            key: 31,
            permissionParentKey: "Expense",
            permissionChildKey: "can_view_expense_analytics",
            onClick: () => {},
            routePath: pathConstants.EXPENSES_ANALYTICS,
          },
          {
            name: "Categories",
            key: 32,
            permissionParentKey: "Expense",
            permissionChildKey: "can_view_edit_and_create_expense_category",
            onClick: () => {},
            routePath: pathConstants.EXPENSES_CATEGORIES,
          },
        ],
      },
      {
        name: "Reports",
        key: 5,
        active: matchPath(getPlatform(platform) + "/accounting/*", pathname),
        icon: (iconProp = { active: false }) => {
          return (
            <ReportIcon
              strokeColor={iconProp?.active ? "#7647ee" : undefined}
            />
          );
        },
        onClick: () => {
          navigate(pathConstants.ACCOUNTING_OVERVIEW);
        },
        permissionParentKey: "Accounting",
      },
      {
        name: "Loyalty",
        key: 6,
        active: matchPath(getPlatform(platform) + "/loyalty/*", pathname),
        icon: (iconProp = { active: false }) => {
          return (
            <LoyaltyIcon
              strokeColor={iconProp?.active ? "#7647ee" : undefined}
            />
          );
        },
        onClick: () => {
          navigate(pathConstants.LOYALTY_MANAGE_CAMPAIGN);
          // setIsCollapseSidebar(false);
        },
        permissionParentKey: "Loyalty",
        children: [
          {
            name: "Loyalty Campaign",
            key: 511,
            onClick: () => {},
            routePath: pathConstants.LOYALTY_MANAGE_CAMPAIGN,
            permissionParentKey: "Loyalty",
            permissionChildKey: "can_view_and_create_loyalty_campaigns",
          },
          {
            name: "Giftcards",
            key: 512,
            onClick: () => {},
            routePath: pathConstants.LOYALTY_MANAGE_GIFTCARDS,
            permissionParentKey: "Loyalty",
            permissionChildKey: "can_view_and_create_gift_cards",
          },
        ],
      },
      {
        name: "Customers",
        key: 7,
        active: matchPath(getPlatform(platform) + "/customers/*", pathname),
        icon: (iconProp = { active: false }) => {
          return (
            <CustomerIcon
              strokeColor={iconProp?.active ? "#7647ee" : undefined}
            />
          );
        },
        onClick: () => {
          navigate(pathConstants.CUSTOMERS);
        },
        permissionParentKey: "Customers",
        permissionChildKey: "can_view_customer",
      },
      {
        name: "Apps",
        key: 8,
        active: matchPath(getPlatform(platform) + "/markets/*", pathname),
        icon: (iconProp = { active: false }) => {
          return (
            <MarketsIcon
              strokeColor={iconProp?.active ? "#7647ee" : undefined}
            />
          );
        },
        onClick: () => {
          navigate(pathConstants.APPS_MAIN);
          if (isCollapseSidebar) setActiveSubMenu(11);
        },
      },
      {
        name: "More",
        active: matchPath(getPlatform(platform) + "/settings/*", pathname),
        key: 9,
        icon: (iconProp = { active: false }) => {
          return (
            <MoreIcon strokeColor={iconProp?.active ? "#7647ee" : undefined} />
          );
        },
      },
    ].filter(Boolean),
  };
};
export default useAppSidebarItems;
