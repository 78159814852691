import { useContext, useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  cleanObject,
  deepCopyFunction,
  getDefaultStoreProperties,
  getRandomIntInclusive,
} from "../../../helpers";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { restockProduct } from "../../../services/inventory";
import {
  useGetPricingTypes,
  useGetSuppliers,
} from "../../../hooks/api/queries/useInventory";
import { v4 as uuidv4 } from "uuid";
import { useGetAccountingBanksListWithSpreadFilter } from "../../../hooks/api/queries";

const storeVariantProperty = {
  store_id: null,
  store_name: "",
  variants: [],
  item_level_tracking: false,
  uuid: uuidv4(),
};

const getVariantsList = (variants) => {
  let newVariants = [];
  variants?.forEach((item) => {
    newVariants.push({ name: `${item?.option} - ${item.value}`, id: item?.id });
  });
  return newVariants;
};

const useAddProductStock = ({
  selectedProduct,
  closeWidget,
  handleFetchproducts,
  refetchSelectedProduct,
}) => {
  const { storeList, businessDetails } = useSelector(
    (state) => state.profileDetailsReducer,
  );
  const [storeProperties, setStoreProperties] = useState([
    {
      ...getDefaultStoreProperties(),
      uuid: uuidv4(),
    },
  ]);
  const [selectedStore, setSelectedStore] = useState("");

  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: handleFetchBankAccountsList,
  } = useGetAccountingBanksListWithSpreadFilter({
    account_type: "bank_account",
  });

  const [storeVariantProperties, setStoreVariantProperties] = useState([
    {
      ...storeVariantProperty,
    },
  ]);
  const [variantsList] = useState(getVariantsList(selectedProduct?.variants));
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();
  const { data: pricingTypes, isLoading: isLoadingPricingTypes } =
    useGetPricingTypes();
  const { isLoading: isFetchingSuppliers, data: suppliers } = useGetSuppliers();

  const setPropertiesBasedOnStore = (storeId, storePropertyIndex) => {
    if (storeProperties?.find((item) => item.store_id === storeId))
      return triggerToast("This store has been selected", "warning");

    const newStoreProperties = deepCopyFunction(storeProperties);
    const selectedProductStoreProperties =
      selectedProduct?.store_product_properties?.find(
        (item) => item?.store_id === storeId,
      );
    newStoreProperties[storePropertyIndex].store_id = storeId;
    newStoreProperties[storePropertyIndex].store_name = storeList?.find(
      (item) => item.id === storeId,
    )?.name;
    newStoreProperties[storePropertyIndex].cost_price =
      selectedProductStoreProperties?.cost_price;
    newStoreProperties[storePropertyIndex].pricings[0] = {
      ...newStoreProperties[storePropertyIndex].pricings[0],
      pricing_type_id:
        selectedProductStoreProperties?.pricings?.[0]?.pricing_type?.id,
      price: selectedProductStoreProperties?.pricings?.[0]?.price,
      discount_rate:
        selectedProductStoreProperties?.pricings?.[0]?.discount_rate || "",
    };
    newStoreProperties[storePropertyIndex].expiry_date =
      selectedProductStoreProperties?.expiry_date || "";

    setStorePropertiesFunc(newStoreProperties);
  };

  const addMoreStoreOption = () => {
    const newStoreProperties = [...storeProperties];
    newStoreProperties.push({
      ...getDefaultStoreProperties(),
    });
    setStorePropertiesFunc(newStoreProperties);
  };

  const deleteStoreProperty = (storePropertyIndex) => {
    const newStoreProperties = [...storeProperties];
    newStoreProperties.splice(storePropertyIndex, 1);
    setStorePropertiesFunc(newStoreProperties);
  };

  const setStorePropertiesFunc = (newStoreProperties) => {
    setStoreProperties(newStoreProperties);
    window.setAddStockFormValue("storeProperties", newStoreProperties);
  };

  const setVariantPropertyBasedOnStore = (storeId, storeVariantIndex) => {
    if (storeVariantProperties?.find((item) => item.store_id === storeId))
      return triggerToast("This store has been selected", "warning");

    const newStoreVariantProperties = deepCopyFunction(storeVariantProperties);
    newStoreVariantProperties[storeVariantIndex].store_id = storeId;
    newStoreVariantProperties[storeVariantIndex].store_name = storeList?.find(
      (item) => item?.id === storeId,
    )?.name;
    setStoreVariantPropertiesFunc(newStoreVariantProperties);
  };

  const setPropertiesBasedOnVariantId = (variantsIds, storeVariantIndex) => {
    const newStoreVariantProperties = deepCopyFunction(storeVariantProperties);
    newStoreVariantProperties[storeVariantIndex].variants = [];
    variantsIds?.forEach((variantId) => {
      const variantsProperties = selectedProduct?.variants?.find(
        (item) => item?.id === variantId,
      );
      const storeVariantProperty = variantsProperties?.store_variant_properties;
      const variantProperty = storeVariantProperty?.find(
        (item) =>
          item?.store_id ===
          newStoreVariantProperties?.[storeVariantIndex]?.store_id,
      );

      newStoreVariantProperties[storeVariantIndex].variants.push({
        variant_id: variantId,
        variant_name: variantsList?.find((item) => item?.id === variantId)
          ?.name,
        stock_quantity: "",
        expiry_date: variantProperty?.expiry_date || "",
        barcode: variantsProperties?.barcode || "",
        cost_price: variantProperty?.cost_price || "",
        items_number: [],
        pricings: [
          {
            price: variantProperty?.pricings?.[0].price,
            pricing_type_id: variantProperty?.pricings?.[0].pricing_type?.id,
            discount_rate: variantProperty?.pricings?.[0].discount_rate,
            uuid: uuidv4(),
          },
        ],
      });
    });

    setStoreVariantPropertiesFunc(newStoreVariantProperties);
  };

  const addStoreVariantOption = () => {
    const newStoreVariantProperties = [...storeVariantProperties];
    newStoreVariantProperties.push({ ...storeVariantProperty });
    setStoreVariantPropertiesFunc(newStoreVariantProperties);
  };

  const deleteStoreVariantOption = (storeVariantIndex) => {
    const newStoreVariantProperties = [...storeVariantProperties];
    newStoreVariantProperties.splice(storeVariantIndex, 1);
    setStoreVariantPropertiesFunc(newStoreVariantProperties);
  };

  const setStoreVariantPropertiesFunc = (newStoreVariantProperties) => {
    setStoreVariantProperties(newStoreVariantProperties);
    window.setAddStockFormValue(
      "storeVariantProperties",
      newStoreVariantProperties,
    );
  };

  const updateSupplierAcrossStore = (supplierId, itemIndex, status) => {
    const newStoreProperties = deepCopyFunction(storeProperties);
    newStoreProperties?.forEach((item, index) => {
      if (index !== itemIndex) {
        item.useSupplierAcross = false;
        item.supplier = status ? supplierId : null;
      }
    });

    setStoreProperties(newStoreProperties);
  };

  const updateAccountAcrossStore = (accountId, itemIndex, status) => {
    const newStoreProperties = deepCopyFunction(storeProperties);
    newStoreProperties?.forEach((item, index) => {
      if (index !== itemIndex) {
        item.useAccountAcross = false;
        item.ledger_account_id = status ? accountId : null;
      }
    });

    setStoreProperties(newStoreProperties);
  };

  const updateSupplierAcrossStoreVariant = (
    storeVariantIndex,
    variantIndex,
    supplierId,
    status,
  ) => {
    const newStoreVariantProperties = deepCopyFunction(storeVariantProperties);
    newStoreVariantProperties?.forEach((storeItem, storeIndex) => {
      storeItem?.variants?.forEach((item, itemIndex) => {
        if (storeIndex !== storeVariantIndex || itemIndex !== variantIndex) {
          item.useSupplierAcross = false;
          item.supplier = status ? supplierId : null;
        }
      });
    });
    setStoreVariantPropertiesFunc(newStoreVariantProperties);
  };

  const { mutate: addProductStock, isLoading: isAddingStock } = useMutation(
    (data) => restockProduct(data),
  );
  const addStock = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 30));
    const submissionData = {};
    if (selectedProduct?.variants?.length === 0) {
      submissionData.has_variants = false;
      submissionData.restock_data = [...storeProperties]?.map((item) => {
        const pricings = item?.pricings?.map((pricingItem) => {
          return cleanObject(pricingItem, ["uuid"]);
        });
        return cleanObject({ ...item, pricings }, [
          "stock_quantity",
          "minimum_stock_quantity",
          "uuid",
          `${item?.item_level_tracking ? "" : "items_number"}`,
        ]);
      });
    } else {
      submissionData.has_variants = true;
      const newRestock = [];
      storeVariantProperties?.forEach((storeVariantProperty) => {
        storeVariantProperty?.variants?.forEach((variantItem) => {
          newRestock.push(
            cleanObject({
              store_id: storeVariantProperty?.store_id,
              expiry_date: variantItem?.expiry_date,
              barcode: variantItem?.barcode,
              quantity: variantItem?.stock_quantity,
              variant_id: variantItem?.variant_id,
              cost_price: variantItem?.cost_price,
              supplier: variantItem?.supplier,
              ledger_account_id: storeVariantProperty?.ledger_account_id,
              items_number: storeVariantProperty?.item_level_tracking
                ? variantItem?.items_number
                : undefined,
              pricings: variantItem?.pricings?.map((pricingItem) => {
                return cleanObject(pricingItem, ["uuid"]);
              }),
            }),
          );
        });
      });
      submissionData.restock_data = newRestock;
    }

    addProductStock(
      { id: selectedProduct?.id, payload: submissionData },
      {
        onSuccess: () => {
          triggerToast("Product Updated Sucessfully", "success");
          handleFetchproducts();
          refetchSelectedProduct();
          queryClient.invalidateQueries("getTrackItems");
          closeWidget();
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  useEffect(() => {
    handleFetchBankAccountsList();
  }, [selectedStore]);

  return {
    storeList,
    storeProperties,
    isAddingStock,
    storeVariantProperties,
    variantsList,
    suppliers: suppliers || [],
    isFetchingSuppliers,
    pricingTypes,
    isLoadingPricingTypes,
    setStoreProperties,
    addMoreStoreOption,
    setPropertiesBasedOnStore,
    deleteStoreProperty,
    addStock,
    isLoadingBankAccountsList,
    handleFetchBankAccountsList,
    bankAccount,
    updateAccountAcrossStore,
    setSelectedStore,
    selectedStore,
    setStoreVariantProperties,
    setVariantPropertyBasedOnStore,
    setPropertiesBasedOnVariantId,
    addStoreVariantOption,
    deleteStoreVariantOption,
    updateSupplierAcrossStore,
    updateSupplierAcrossStoreVariant,
    businessDetails,
  };
};
export default useAddProductStock;
