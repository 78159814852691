import httpClient from "../axiosBase/axiosHandler";

export const createSubAccount = (payload) => {
  const response = httpClient.post("/business/", payload);
  return response;
};

export const getSubAccounts = () => {
  const response = httpClient.get("/business/");
  return response;
};

export const switchBusinessAccount = (payload) => {
  const response = httpClient.post("/business/switch-business/", payload);
  return response;
};

export const updateSubAccount = (payload) => {
  const response = httpClient.post("/business/update-sub-account/", payload);
  return response;
};

export const getCountries = () => {
  const response = httpClient.get("/locations/countries/");
  return response;
};

export const getCountry = (id) => {
  const response = httpClient.get(`/locations/countries/${id}/`);
  return response;
};

export const getStates = ({ id }) => {
  const response = httpClient.get(`/locations/${id}/get-state/`);
  return response;
};

export const getCities = ({ id }) => {
  const response = httpClient.get(`/locations/${id}/`);
  return response;
};

export const addCityToLocation = (payload) => {
  const response = httpClient.post("/locations/cities/", payload);
  return response;
};

export const getStoreLocations = (queryParams = {}) => {
  const response = httpClient.get(`/menus/${queryParams?.id}/business-menu/`, {
    params: { ...queryParams },
  });
  return response;
};

export const getCountriesForBusiness = (id) => {
  const response = httpClient.get(`/menus/${id}/business-store-locations/`);
  return response;
};

export const getTimezones = () => {
  const response = httpClient.get("/timezones/");
  return response;
};
