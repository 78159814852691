import { useTable } from "react-table";
import { useMemo, useState } from "react";
import { formatCurrency, formatDate } from "../../../helpers";
import { useSelector } from "react-redux";
import Icon from "../../../assets/icons";
import styles from "./styles.module.scss";

const NoteContent = ({ note }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <div
        onMouseEnter={() => setShowDetails(true)}
        onMouseLeave={() => setShowDetails(false)}
        className={styles.noteIconContainer}
      >
        {showDetails ? (
          <img src={Icon.noteTwo} alt="" />
        ) : (
          <img src={Icon.noteOne} alt="" />
        )}
      </div>
      {showDetails && (
        <div
          onMouseEnter={() => setShowDetails(true)}
          onMouseLeave={() => setShowDetails(false)}
          className={styles.noteDetails}
        >
          {note}
        </div>
      )}
    </div>
  );
};

const useCustomerCreditLogTable = ({ credits }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);

  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: (cell) => new Date(cell.value).toLocaleString("en-us"),
      },
      {
        Header: "Order Number",
        accessor: "order_id",
        Cell: (cell) =>
          cell?.value ? (
            <span
              style={{
                color: "#7647EE",
                height: "28px",
                cursor: "pointer",
              }}
              role="button"
              onClick={() => {
                setSelectedOrder(cell?.value);
              }}
            >
              {cell?.value}
            </span>
          ) : (
            "_____"
          ),
      },
      {
        Header: "Payment Method",
        accessor: "repayment_method",
        Cell: (cell) => <div>{cell.value ? cell.value : "____"}</div>,
      },
      {
        Header: "Description",
        accessor: "is_manually_added",
        Cell: ({ cell, row }) => (
          <div style={{ display: "flex" }}>
            <div className={styles.decription}>
              <p>
                {row?.original?.type === "repayment"
                  ? "Repayment"
                  : cell.value
                    ? "Debt initiated in Debtors"
                    : "Debt initiated in Sell Mode"}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell, row }) => {
          let val = formatCurrency({
            value: cell.value,
            currencyCode: storeList?.find(
              (storeItem) =>
                `${storeItem?.id}` === `${row?.original?.store_id}`,
            )?.currency_code,
          });

          if (cell.row.original.type === "credit") {
            return (
              <span
                style={{
                  color: "#E02020",
                }}
              >
                -{val}
              </span>
            );
          }

          return (
            <span
              style={{
                color: "#19AE57",
              }}
            >
              {val}
            </span>
          );
        },
      },
      {
        Header: "Notes",
        accessor: "note",
        Cell: ({ cell }) =>
          cell?.value ? <NoteContent note={cell?.value} /> : "N/A",
      },
      {
        Header: "Outstanding Balance",
        accessor: "closing_outstanding_balance",
        Cell: ({ cell, row }) => {
          return (
            <span>
              {formatCurrency({
                value: cell.value,
                currencyCode: storeList?.find(
                  (storeItem) =>
                    `${storeItem?.id}` === `${row?.original?.store_id}`,
                )?.currency_code,
              })}{" "}
              {row?.original?.store_id}
            </span>
          );
        },
      },
      {
        Header: "Due Date",
        accessor: "expected_repayment_date",
        Cell: (cell) => (cell.value ? formatDate(cell.value) : "___"),
      },
      {
        Header: "Cashier",
        accessor: "cashier_name",
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: credits });

  return {
    instance,
    selectedOrder,
    setSelectedOrder,
  };
};

export default useCustomerCreditLogTable;
