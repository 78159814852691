import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const bulkRestockSchema = yup.object({
  restockProperties: yup.array().of(
    yup.object().shape({
      quantity: yup
        .number()
        .typeError("Enter quantity")
        .required("Enter quantity"),
    }),
  ),
  supplierName: yup.string().required("Select supplier"),
  invoiceDate: yup.string().required("Select invoice/receipt date"),
});

export const Validation = ({ restockData, otherRestockInfo }) => {
  return useForm({
    resolver: yupResolver(bulkRestockSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      restockProperties: restockData,
      supplierName: otherRestockInfo?.supplier_id,
      invoiceDate: otherRestockInfo?.invoice_date,
    },
  });
};
