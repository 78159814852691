import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AddDebtValidationSchema = yup.object({
  amount: yup
    .number()
    .typeError("Amount is required")
    .required("Amount is required"),
  repaymentDate: yup.string().required("Repayment date is required"),
  note: yup.string().required("Note is required"),
});

export const Validation = ({ amount, repaymentDate, note }) => {
  return useForm({
    resolver: yupResolver(AddDebtValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      amount,
      repaymentDate,
      note,
    },
  });
};
