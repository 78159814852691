import { useContext, useState } from "react";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../primaryComponents";
import {
  useCompleteDebitTnx,
  useResendDebitTnxOtp,
} from "../../../../hooks/api/mutations/useInventory";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { useQueryClient } from "react-query";
import { getRandomIntInclusive } from "../../../../helpers";
import useCountDown from "../../../../hooks/useCountDown";

const StoreWalletOtpWidget = ({
  debitSessionDetails,
  closeModal,
  debitDetails,
  currencyCode,
  is_order = false,
  isViaSellMode = false,
  setIsDebitWallet = () => {},
  callBackFunc = () => {},
}) => {
  const [otp, setOtp] = useState("");
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const completeDebitTnx = useCompleteDebitTnx(isViaSellMode);
  const resendDebitTnxOtp = useResendDebitTnxOtp();
  const { countDown, setCountDown } = useCountDown({ defaultStartNum: 60 });

  const handleCompleteDebitTnx = (value) => {
    setOtp(value);
    if (value?.length !== 6) return;
    setRequestLoaderProgress(getRandomIntInclusive(10, 30));
    completeDebitTnx.mutate(
      {
        otp_code: value,
        session_id: debitSessionDetails?.session_id,
      },
      {
        onSuccess: () => {
          triggerToast(
            `A sum of ${currencyCode} ${debitDetails?.amount} has been debited from customer: (${debitDetails?.customer?.customerName})${is_order ? " which is use to process this order" : ""}`,
            "success",
          );
          queryClient.invalidateQueries(["getStoreWallets"]);
          queryClient.invalidateQueries(["getStoreWalletDetails"]);
          callBackFunc();
          setIsDebitWallet(null);
          closeModal();
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const handleResendOtp = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 30));
    resendDebitTnxOtp.mutate(
      {
        session_id: debitSessionDetails?.session_id,
      },
      {
        onSuccess: () => {
          triggerToast("OTP resent successfully", "success");
          setCountDown(60);
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  return (
    <div className={`p-3 text-center ${globalStyles.pageColumnGaps}`}>
      <div>
        <h5 className="purple--text">Store Wallet Verification</h5>
        <p>Enter the 6-digit code sent to your email and phone number</p>
      </div>

      <div className="mx-auto" style={{ width: "300px" }}>
        <primaryComponents.OtpInputField
          onChange={handleCompleteDebitTnx}
          numOfInputs={6}
          value={otp}
        />
      </div>

      <p>
        Didn&apos;t get code?{" "}
        {resendDebitTnxOtp?.isLoading ? (
          <span className="purple--text">Resending...</span>
        ) : (
          <>
            {countDown !== 0 && (
              <span className="purple--text"> {countDown}</span>
            )}
            {countDown === 0 && (
              <span
                className="purple--text cursor-pointer"
                onClick={() => handleResendOtp()}
              >
                Resend
              </span>
            )}
          </>
        )}
      </p>
    </div>
  );
};
export default StoreWalletOtpWidget;
