import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  amount: yup.string().required("Amount name is required"),
  description: yup.string().required("Description is required"),
  account: yup.string().when("paymentMethod", {
    is: "cash",
    then: yup.string().nullable(),
    otherwise: yup.string().required("Account is required"),
  }),
  storeIds: yup.array().nullable(),
  paymentMethod: yup.string().required("Payment type is required"),
});

const Validation = ({ bankDetails, type }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      amount: bankDetails?.amount,
      description: bankDetails?.description,
      account: bankDetails?.ledger_account_id,
      paymentMethod: type === "cash" ? "cash" : bankDetails?.payment_method,
      storeIds: bankDetails?.store_ids,
    },
  });
};

export default Validation;
