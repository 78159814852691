import { useEffect, useState } from "react";
import globalStyles from "../../../../../../../assets/styles/base/globalPage.module.scss";
// import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import LoaderHelper from "../../../../../../../components/secondaryComponents/LoaderHelper/LoaderHelper";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { pathConstants } from "../../../../../../../routes/pathContants";

const CustomizationNavigation = () => {
  const {
    menuProperties,
    isFetchingMenuCategories,
    sortMenuCategories,
    setSortMenuCategories,
  } = useOutletContext();

  const navigate = useNavigate();
  const { menuId } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedMenuTab, setSelectedMenuTab] = useState("Checkout");
  const { pathname } = useLocation();

  const customizationTab = [
    {
      name: "Checkout",
      route: pathname.includes("create")
        ? pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_CHECKOUT({
            menuId: menuId ? menuId : searchParams.get("menu_id"),
          }) + `?store_id=${searchParams.get("store_id")}`
        : pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_CHECKOUT({
            menuId: menuId ? menuId : searchParams.get("menu_id"),
          }) + `?store_id=${menuProperties?.store?.id}`,
    },
    {
      name: "Payment",
      route: pathname.includes("create")
        ? pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_PAYMENT({
            menuId: menuId ? menuId : searchParams.get("menu_id"),
          }) + `?store_id=${searchParams.get("store_id")}`
        : pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_PAYMENT({
            menuId: menuId ? menuId : searchParams.get("menu_id"),
          }) + `?store_id=${menuProperties?.store?.id}`,
    },
    {
      name: "Branding",
      route: pathname.includes("create")
        ? pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_BRANDING({
            menuId: menuId ? menuId : searchParams.get("menu_id"),
          }) + `?store_id=${searchParams.get("store_id")}`
        : pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_BRANDING({
            menuId: menuId ? menuId : searchParams.get("menu_id"),
          }) + `?store_id=${menuProperties?.store?.id}`,
    },
    {
      name: "Sort Categories",
      route: pathname.includes("create")
        ? pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_SORT_CATEGORIES({
            menuId: menuId ? menuId : searchParams.get("menu_id"),
          }) + `?store_id=${searchParams.get("store_id")}`
        : pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_SORT_CATEGORIES({
            menuId: menuId ? menuId : searchParams.get("menu_id"),
          }) + `?store_id=${menuProperties?.store?.id}`,
    },
  ];

  useEffect(() => {
    if (pathname.includes("checkout")) {
      setSelectedMenuTab("Checkout");
    } else if (pathname.includes("payment")) {
      setSelectedMenuTab("Payment");
    } else if (pathname.includes("branding")) {
      setSelectedMenuTab("Branding");
    } else {
      setSelectedMenuTab("Sort Categories");
    }
  }, [pathname]);

  return (
    <div className={styles.container}>
      <div
        style={{
          justifyContent: "center",
        }}
        className={globalStyles.rowNavigations}
      >
        {customizationTab?.map((item, index) => (
          // eslint-disable-next-line
          <a
            key={index}
            className={`${globalStyles.rowNavigations__item} ${
              item.name === selectedMenuTab ? globalStyles.active : ""
            }`}
            onClick={() => {
              setSelectedMenuTab(item.name);
              navigate(item.route);
            }}
          >
            {item.name}
          </a>
        ))}
      </div>
      <LoaderHelper classNames={"mt-4"} isLoading={!menuProperties}>
        <div className={styles.tabContent}>
          <Outlet
            context={{
              menuProperties,
              isFetchingMenuCategories,
              sortMenuCategories,
              setSortMenuCategories,
            }}
          />
        </div>
      </LoaderHelper>
    </div>
  );
};

export default CustomizationNavigation;
