import { useOutletContext } from "react-router-dom";
import Icon from "../../../../../../../../../assets/icons";
import primaryComponents from "../../../../../../../../../components/primaryComponents";
import SortCategories from "../../../SortCategories";
import styles from "./styles.module.scss";
import { useSortCategories } from "./useSortCategories";

const SortStorefrontCategories = () => {
  const {
    menuProperties,
    isFetchingMenuCategories,
    sortMenuCategories,
    setSortMenuCategories,
  } = useOutletContext();
  const { isLoadingSortCategories, mutateSortCategories } = useSortCategories({
    menuProperties,
  });

  return (
    <div className={styles.container}>
      <div className={styles.sortHeading}>
        <div className={styles.sortItem}>
          <img src={Icon.sort} alt="" />
          <div>
            <h5>Sort Storefront Categories</h5>
            <p>
              Arrange categories by dragging and dropping them into your
              preferred order.
            </p>
          </div>
        </div>
        <primaryComponents.Button
          classNames="btn btn--primary"
          onClick={() =>
            mutateSortCategories({
              menu_category_ids: sortMenuCategories.map((item) => `${item.id}`),
            })
          }
          loadingText={"Saving..."}
          isLoading={isLoadingSortCategories}
          isDisabled={isLoadingSortCategories}
        >
          <span>Save</span>
        </primaryComponents.Button>
      </div>
      <div className={styles.sortContainer}>
        <SortCategories
          isFetchingMenuCategories={isFetchingMenuCategories}
          sortMenuCategories={sortMenuCategories}
          setSortMenuCategories={setSortMenuCategories}
        />
      </div>
    </div>
  );
};

export default SortStorefrontCategories;
