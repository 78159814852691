import React from "react";
import secondaryComponents from "..";
import styles from "./MainDashboardAnalyticsCard.module.scss";
import { COLORS } from "../../../helpers/constants";

const MainDashboardAnalyticsCard = ({
  title,
  value,
  text = null,
  color,
  isFetchingAnalytics,
  backgroundColor = null,
}) => {
  return (
    <div
      className={styles.analyticsCard}
      style={{ backgroundColor: backgroundColor ? backgroundColor : "#fff" }}
    >
      <div
        className={styles.analyticsCard__left}
        style={{ borderLeft: `4px solid ${COLORS[color % COLORS.length]}` }}
      >
        {title}
      </div>
      <secondaryComponents.LoaderHelper isLoading={isFetchingAnalytics}>
        <h6 className="mb-0">
          {value}
          <br />
          <p>{text === null ? "" : text}</p>
        </h6>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default MainDashboardAnalyticsCard;
