import styles from "./Bank.module.scss";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { AddIcon } from "../../../../assets/svgIcons";
import useAllAccounts from "./useBank";
import AddBankAccount from "../../../../components/secondaryComponents/AddBankAccount";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../../helpers";
const AccountingsBeta = () => {
  const {
    bankAccount,
    showAddAccounting,
    setShowAddAccounting,
    isLoadingBankAccountsList,
    handleFetchBankAccountsList,
    isFetchingBankInfo,
    bankInfo,
    setSelectedBank,
  } = useAllAccounts();
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Accountings"
      description="View all accountions"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={` ${globalStyles.pageColumnGaps}`}
        >
          <div className="mt-2">
            <h4 className="font-weight-semibold">Bank Accounts</h4>
            <p className="default-text-size">
              Create & manage all your bank accounts.
            </p>
          </div>
          <div className={`${styles.card} mt-3`}>
            <div className="d-flex flex-wrap justify-content-between w-100">
              <div className="w-50">
                <p className={`${styles.title} smallest-text-size p-1 mb-2`}>
                  {"Available Balance"}
                </p>
                <secondaryComponents.LoaderHelper
                  isLoading={isFetchingBankInfo}
                  classNames="mt-5"
                >
                  <h5>
                    {formatCurrency({
                      value: bankInfo?.balance || "0",
                      currencyCode: currency_code,
                    })}
                  </h5>{" "}
                </secondaryComponents.LoaderHelper>
              </div>
              <div style={{ width: "200px" }}>
                <secondaryComponents.LoaderHelper
                  isLoading={isLoadingBankAccountsList}
                  classNames="mt-1"
                >
                  <primaryComponents.SelectField
                    options={bankAccount?.map((bank) => ({
                      id: bank?.id,
                      name: bank?.account_name,
                    }))}
                    idKey="id"
                    nameKey="name"
                    value={
                      bankAccount ? bankAccount[0]?.account_name : "Select Bank"
                    }
                    onChange={(value) => {
                      setSelectedBank(value);
                    }}
                    floatError={false}
                    classNames="bank-name"
                    isSearchable={false}
                  />
                </secondaryComponents.LoaderHelper>
              </div>
            </div>
            <div className={styles.cards_container}>
              <div>
                <p className={`${styles.title} smallest-text-size p-1`}>
                  Bank Name
                </p>
                <secondaryComponents.LoaderHelper
                  isLoading={isFetchingBankInfo}
                >
                  <h6 className="default-text-size">
                    {bankInfo?.bank_name || "N/A"}
                  </h6>
                </secondaryComponents.LoaderHelper>
              </div>
              <div>
                <p className={`${styles.title} smallest-text-size p-1`}>
                  Bank Account Name
                </p>
                <secondaryComponents.LoaderHelper
                  isLoading={isFetchingBankInfo}
                >
                  <h6>{bankInfo?.account_name || "N/A"}</h6>
                </secondaryComponents.LoaderHelper>
              </div>
              <div>
                <p className={`${styles.title} smallest-text-size p-1`}>
                  Account Number
                </p>
                <h6>{bankInfo?.account_number || "N/A"}</h6>
              </div>
            </div>
          </div>
          {/* table session */}
          <div className={styles.tableBody}>
            <div
              className={`m-1 d-flex justify-content-end ${styles.tableBody__header}`}
            >
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Accounting"}
                permissionChildKey="can_create_ledger_account"
              >
                <primaryComponents.Button
                  classNames="btn btn--primary"
                  onClick={() => setShowAddAccounting(true)}
                >
                  <div className="d-flex align-items-center">
                    <AddIcon />
                    <span className="default-text-size pl-2">Bank Account</span>
                  </div>
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            </div>

            <secondaryComponents.LoaderHelper
              isLoading={isLoadingBankAccountsList}
              classNames="mt-5"
            >
              <secondaryComponents.AllAccountingsTable
                banks={bankAccount}
                refetch={handleFetchBankAccountsList}
              />
            </secondaryComponents.LoaderHelper>

            <secondaryComponents.SideModal
              isActive={showAddAccounting}
              closeModal={() => setShowAddAccounting(false)}
              width={500}
            >
              <AddBankAccount
                closeWidget={() => setShowAddAccounting(false)}
                bankInfo={bankAccount}
                isLoading={isLoadingBankAccountsList}
                handleFetchBanks={handleFetchBankAccountsList}
              />
            </secondaryComponents.SideModal>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AccountingsBeta;
