import { useEffect, useState } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";

const useMenuPage = () => {
  const [search] = useSearchParams();
  const { id } = useParams();
  const {
    menuSearchText,
    showCartItems,
    setShowCartItems,
    isActiveCart,
    setIsActiveCart,
    menuObject,
  } = useOutletContext();
  const orderId = search.get("orderId");
  const [menuCatagories, setMenuCatagories] = useState([]);

  useEffect(() => {
    setMenuCatagories(
      menuObject?.categories?.filter((obj) => {
        return obj.menu_items.some((item) =>
          item?.name.toLowerCase().includes(menuSearchText.toLowerCase()),
        );
      }),
    );
  }, [menuObject, menuSearchText]);

  return {
    orderId,
    menuId: id,
    menuCategories: menuCatagories,
    menuObject: menuObject || {},
    menuSearchText,
    showCartItems,
    setShowCartItems,
    isActiveCart,
    setIsActiveCart,
  };
};

export default useMenuPage;
