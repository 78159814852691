import { useState, useRef, useEffect, useContext } from "react";
import httpClient from "../../../axiosBase/axiosHandler";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getUploadedImage } from "../../../services/media";

const ImageUploadModalLogic = ({ setImage, setShowImageModal }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const hiddenFileInput = useRef(null);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    setIsLoading(true);
    getUploadedImage()
      .then((response) => {
        setIsLoading(false);
        setImageList(response.data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (uploadProgress >= 100) {
      setTimeout(() => {
        setUploadProgress(0);
        setShowImageModal(false);
      }, 1500);
    }
  }, [uploadProgress]);

  const setImageSelected = (event) => {
    const files = event.target.files;
    let imageNames = files;
    if (imageNames === null) return;
    const formData = new FormData();
    formData.set("file", imageNames[0]);
    uploadimage(formData);
  };

  const uploadimage = (formData) => {
    setRequestLoaderProgress(20);
    httpClient
      .post("/media/", formData, {
        onUploadProgress: (uploadEvent) => {
          let uploadProgress = String(
            Math.round((uploadEvent.loaded / uploadEvent.total) * 100),
          );
          setUploadProgress(uploadProgress);
          setRequestLoaderProgress(uploadProgress);
        },
      })
      .then((response) => {
        setRequestLoaderProgress(100);
        triggerToast("Image upload successful", "success");
        setImage(response.data.data.url);
      })
      .catch(() => {
        setUploadProgress(100);
        setRequestLoaderProgress(100);
      });
  };

  return {
    uploadProgress,
    setUploadProgress,
    setImageSelected,
    hiddenFileInput,
    isLoading,
    imageList,
  };
};

export default ImageUploadModalLogic;
