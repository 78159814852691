import { useContext, useEffect, useState } from "react";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../../../notifications/firebase";
import { useMutation, useQueryClient } from "react-query";
import { createDevice } from "../../../services/notifications";
import messageSound from "../../../assets/sounds/message.mp3";
import { useGetOrderDetails } from "../../../hooks/api/queries/useOrder";
import { ToastContext } from "../../../hooks/context";
import { useAcceptOrRejectOrdereMutation } from "../../../hooks/api/mutations/useOrder";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";

export const usePushNotifications = () => {
  const queryClient = useQueryClient();
  const triggerToast = useContext(ToastContext);
  const [orderId, setOrderId] = useState(null);
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const {
    data: orderDetails,
    isLoading: isLoadingOrderDetails,
    refetch: refetchOrderDetails,
  } = useGetOrderDetails({
    id: orderId,
    isEnabled: orderId ? true : false,
  });
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: (data) => createDevice(data),
  });

  const { isLoading: isProcessingOrder, mutate: mutateAcceptOrReject } =
    useAcceptOrRejectOrdereMutation();
  const acceptOrRejectOrder = ({ isAccept = true, rejectionReason = null }) => {
    mutateAcceptOrReject(
      {
        id: orderDetails?.id,
        isAccept,
        rejection_reason: rejectionReason,
      },
      {
        onSuccess: (res) => {
          if (!showRejectionReason)
            navigate(
              pathConstants.INVENTORY_SALES_TRANSACTIONS +
                `?orderId=${orderDetails?.id}`,
            );
          refetchOrderDetails();
          setOrderId(null);
          setShowRejectionReason(false);
          triggerToast(res?.data?.detail, "success");
        },
      },
    );
  };

  const generateToken = async () => {
    const token = await getToken(messaging);
    mutate({ registration_id: token, active: true, type: "web" });
  };

  const askForPermission = async () => {
    if (!("Notification" in window)) {
      alert(
        "This browser does not support notification, Please update or switch browser to the best user experience",
      );
    } else if (Notification.permission === "granted") {
      generateToken();
    } else if (Notification.permission !== "denied") {
      const permission = await Notification.requestPermission();
      if (permission === "granted") generateToken();
    }
  };

  useEffect(() => {
    askForPermission();
    onMessage(messaging, (payload) => {
      if (payload?.data?.type === "store_front") {
        const sound = new Audio(messageSound);
        sound.play();
        queryClient.invalidateQueries(["notifications"]);
        setOrderId(payload?.data?.data);
      }
    });
  }, []);

  return {
    orderDetails,
    orderId,
    isProcessingOrder,
    showRejectionReason,
    isLoadingOrderDetails,
    setShowRejectionReason,
    setOrderId,
    acceptOrRejectOrder,
  };
};
