import "./AddProductWidget.scss";
import primaryComponents from "../../primaryComponents";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../../assets/icons";
import { pathConstants } from "../../../routes/pathContants";
import { useSelector } from "react-redux";
import Images from "../../../assets/images";

const AddProductWidget = ({ closeModal }) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const navigate = useNavigate();
  return (
    <>
      {storeList?.length > 0 ? (
        <div className="add-product-widget">
          <div>
            <h4 className="font-weight-semibold text-center">
              Create new product
            </h4>
            <p className="text-center">
              Create a simple or multiple product at once
            </p>
          </div>
          <Link
            className="add-product-widget__item"
            to={pathConstants.INVENTORY_PRODUCTS_ADD_SINGLE}
            onClick={closeModal}
          >
            <div className="icon orange">
              <img src={Icon.product_box_1} alt="" />
            </div>
            <div>
              <p className="font-weight-semibold dark--text">
                Create Single Product
              </p>
              <p>
                Effortlessly generate individual products for each store, with
                customizable pricing options per product and store.
              </p>
            </div>
            <img className="ml-auto" src={Icon.chevron_right_arrow} alt="" />
          </Link>
          <Link
            className="add-product-widget__item"
            to={pathConstants.INVENTORY_PRODUCTS_ADD_COMPOSITE}
            onClick={() => closeModal()}
          >
            <div className="icon green">
              <img src={Icon.product_box_6} alt="" />
            </div>
            <div>
              <p className="font-weight-semibold dark--text">
                Create Composite product
              </p>
              <p>
                Create products made up of specified quantities of one or more
                different products. Ideal for gift packs or meals with multiples
                ingredients.
              </p>
            </div>
            <img className="ml-auto" src={Icon.chevron_right_arrow} alt="" />
          </Link>
          <Link
            className="add-product-widget__item"
            to={pathConstants.INVENTORY_PRODUCTS_ADD_MULTIPLE}
            onClick={closeModal}
          >
            <div className="icon green">
              <img src={Icon.product_box_2} alt="" />
            </div>
            <div>
              <p className="font-weight-semibold dark--text">
                Bulk Upload Products
              </p>
              <p>
                Streamline your process by swiftly uploading products to
                multiple stores with just one CSV file.
              </p>
            </div>
            <img className="ml-auto" src={Icon.chevron_right_arrow} alt="" />
          </Link>
          <div className="add-product-widget__action">
            <primaryComponents.Button
              classNames={"btn btn--outline"}
              onClick={closeModal}
            >
              Cancel
            </primaryComponents.Button>
          </div>
        </div>
      ) : (
        <div className="mt-5 text-center">
          <img
            src={Images.storeProductEmpty}
            width="250px"
            alt="no-store-product"
            className="mx-auto"
          />
          <h3 className="mt-3">You have no Store(s)</h3>
          <p>You have not created a store yet. Please create one to continue</p>
          <primaryComponents.Button
            onClick={() => navigate(pathConstants.INVENTORY_STORES)}
            classNames="btn btn--primary mx-auto mt-4"
          >
            Create Store
          </primaryComponents.Button>
        </div>
      )}
    </>
  );
};

export default AddProductWidget;
