import styles from "./ProductGridCard.module.scss";
import {
  findPricingViaIdHelper,
  formatCurrency,
  truncateString,
} from "../../../../helpers";
import primaryComponents from "../../../primaryComponents";
import { useState } from "react";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { ProductCardEmptyIcon } from "../../../../assets/svgIcons";
import secondaryComponents from "../..";
import useItemTracking from "../ItemTrackingSelection/useItemTracking";
import ItemTrackingSelection from "../ItemTrackingSelection";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

const ProductGridCardV2 = ({
  product,
  cartDetails = undefined,
  handleAddToCart,
  handleReduceCartItem,
  pricingType,
}) => {
  const { store_currency } = useStoreCurrency();
  const [isService] = useState(product?.is_service);
  const [isComposite] = useState(product?.is_composite);
  const productTypeName = isService
    ? "store_service_properties"
    : "store_product_properties";
  const pricingDetails = findPricingViaIdHelper(
    pricingType,
    product?.[productTypeName]?.[0]?.pricings,
  );
  const hasItemTracking = product?.[productTypeName]?.[0]?.has_inventory_items;
  const { productProperties, setProductProperties, closeModal } =
    useItemTracking();

  return (
    <>
      {pricingDetails ? (
        <secondaryComponents.ToolTipV2
          tipText={cartDetails ? "" : "Click to add to cart"}
        >
          <div
            className={styles.productGridCard}
            onClick={() => {
              if (!cartDetails) {
                if (!hasItemTracking)
                  return handleAddToCart({
                    quantity:
                      product?.[productTypeName]?.[0]?.stock_quantity > 0 &&
                      product?.[productTypeName]?.[0]?.stock_quantity < 1
                        ? product?.[productTypeName]?.[0]?.stock_quantity
                        : 1,
                    product,
                  });

                setProductProperties({
                  product,
                  store_properties: product?.[productTypeName]?.[0],
                });
              }
            }}
          >
            {/* product type */}
            {cartDetails && (
              <p className={globalStyles.prodouctTypeTag}>
                {isComposite ? "Composite" : "Single Product"}
              </p>
            )}
            <AnimatePresence>
              {!cartDetails && (
                <motion.div
                  className={styles.productImage}
                  initial={{ y: -50 }}
                  animate={{
                    y: 0,
                    transition: { duration: 0.1 },
                  }}
                  exit={{ y: -150, transition: { duration: 0.2 } }}
                  style={{
                    background: product?.media_files?.[0]?.url
                      ? "white"
                      : "#75ce9a",
                  }}
                >
                  {product?.media_files?.[0]?.url ? (
                    <img src={product?.media_files?.[0]?.url} alt="" />
                  ) : (
                    <ProductCardEmptyIcon />
                  )}
                </motion.div>
              )}
              {/* )} */}
            </AnimatePresence>

            <p className={"small-text-size dark--text mb-0"}>
              {truncateString(product?.name, 40)}
            </p>

            <div className="mt-auto d-flex flex-column" style={{ gap: "8px" }}>
              <div className={styles.rowItem}>
                {isService ? (
                  <p className={styles.stock}>N/A</p>
                ) : isComposite &&
                  product?.composite_product_type === "ON_DEMAND" ? (
                  <p className={styles.stock}>N/A</p>
                ) : (
                  <p
                    className={`${styles.stock} ${
                      product?.[productTypeName]?.[0]?.stock_quantity <=
                        product?.[productTypeName]?.[0]
                          ?.minimum_stock_quantity && styles.lowQuantity
                    }`}
                  >
                    <span>
                      {product?.[productTypeName]?.[0]?.stock_quantity}
                    </span>
                  </p>
                )}
                <p>
                  <span className="d-block font-weight-semibold purple--text">
                    {formatCurrency({
                      value: (
                        pricingDetails?.price -
                        (pricingDetails?.price *
                          pricingDetails?.discount_rate) /
                          100
                      ).toFixed(2),
                      currencyCode: store_currency,
                      decimal: 0,
                    })}
                  </span>
                  <span
                    className={"d-block error--text"}
                    style={{ fontSize: "10px" }}
                  >
                    {pricingDetails?.discount_rate > 0 ? (
                      <>
                        <span style={{ textDecoration: "line-through" }}>
                          {formatCurrency({
                            value: pricingDetails?.price,
                            currencyCode: store_currency,
                            decimal: 0,
                          })}
                        </span>{" "}
                        {pricingDetails?.discount_rate}% OFF
                      </>
                    ) : (
                      <span style={{ visibility: "hidden" }}>null</span>
                    )}
                  </span>
                </p>
              </div>

              {cartDetails !== undefined ? (
                <div
                  className={styles.product__actions}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <primaryComponents.Button
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!hasItemTracking)
                        return handleReduceCartItem({
                          quantity: Number(cartDetails?.quantity) - 1,
                          product,
                        });

                      setProductProperties({
                        product,
                        store_properties: product?.[productTypeName]?.[0],
                      });
                    }}
                    classNames={"btn btn--outline"}
                    style={{ width: "calc(33.3% - 5.33px)", height: "40px" }}
                  >
                    -
                  </primaryComponents.Button>

                  <primaryComponents.CartInputField
                    defaultValue={cartDetails?.quantity || 0}
                    updateCart={(val) => {
                      handleAddToCart({
                        quantity: val,
                        product,
                      });
                    }}
                    disabled={hasItemTracking}
                    isProductCard={true}
                    style={{ width: "calc(33.3% - 5.33px)", height: "40px" }}
                  />

                  <primaryComponents.Button
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!hasItemTracking)
                        return handleAddToCart({
                          quantity: Number(cartDetails?.quantity) + 1,
                          product,
                        });
                      setProductProperties({
                        product,
                        store_properties: product?.[productTypeName]?.[0],
                      });
                    }}
                    style={{ width: "calc(33.3% - 5.33px)", height: "40px" }}
                    classNames={"btn btn--primary"}
                  >
                    +
                  </primaryComponents.Button>
                </div>
              ) : null}
            </div>
          </div>
        </secondaryComponents.ToolTipV2>
      ) : null}

      <secondaryComponents.Modal
        isActive={productProperties !== null}
        width={550}
        closeModal={closeModal}
      >
        <ItemTrackingSelection
          closeModal={closeModal}
          productProperties={productProperties}
          cartDetails={cartDetails}
          handleAddToCart={handleAddToCart}
          handleReduceCartItem={handleReduceCartItem}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default ProductGridCardV2;
