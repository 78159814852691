import { useQuery } from "react-query";
import { getViewMenuList } from "../../../services/inventory";

export const useGetStorefrontMenus = (id) => {
  return useQuery(
    ["getStorefrontMenus", id],
    () => getViewMenuList({ id }).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      enabled: id ? true : false,
    },
  );
};
