import { useContext } from "react";
import { useSelector } from "react-redux";
import { ToastContext } from "../../../../hooks/context";

const ProfileLogic = () => {
  const { first_name, last_name, referral_code } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const { business_name } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const triggerToast = useContext(ToastContext);

  return { business_name, first_name, last_name, referral_code, triggerToast };
};
export default ProfileLogic;
