import AddCustomerSection from "../../StoreCheckout/AddCustomerSection";
import useStoreWalletFund from "./useStoreWalletFund";
import primaryComponents from "../../../primaryComponents";
import { Validation } from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const StoreWalletFund = ({ setIsFundWallet, customerDetails = {} }) => {
  const {
    fundDetails,
    storeList,
    isFundingWallet,
    setFundDetails,
    handleFundWallet,
  } = useStoreWalletFund({
    customerDetails,
    setIsFundWallet,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
    setValue,
    setError,
  } = Validation({ fundDetails });

  useEffect(() => {
    if (fundDetails?.customer) setError("customer", "");
    setValue("customer", fundDetails?.customer?.customerId);
  }, [fundDetails?.customer]);

  return (
    <div className={globalStyles.pageColumnGaps} style={{ minHeight: "100%" }}>
      <div>
        <primaryComponents.Button
          classNames={"btn btn--outline smallBtn"}
          onClick={() => setIsFundWallet(null)}
        >
          <FontAwesomeIcon icon="angle-left" style={{ fontSize: "15px" }} />
        </primaryComponents.Button>
      </div>
      <div>
        <h4 className="mb-0">Fund Wallet</h4>
        <p>
          Fund customer&apos;s wallet instantly for quick and convenient
          transactions
        </p>
      </div>

      <form
        className={globalStyles.pageColumnGaps}
        style={{ gap: "10px" }}
        onSubmit={handleSubmit(handleFundWallet)}
        id="fundWalletForm"
      >
        <div>
          <AddCustomerSection
            setCustomerDetails={(info) => {
              setFundDetails((prev) => ({ ...prev, customer: info }));
            }}
            customerDetails={fundDetails?.customer}
            showAddCustomer={false}
            showLoyalty={false}
          />

          <p
            className={`smallest-text-size error-message text-danger ${errors.customer?.message ? "show" : "invisible"}`}
          >
            <FontAwesomeIcon
              icon={["fas", "exclamation-circle"]}
              className="mr-2"
              style={{ fontSize: "13px" }}
            />
            <span>{errors.customer?.message}</span>
          </p>
        </div>

        <primaryComponents.SelectFieldV2
          control={control}
          name="store"
          label={"Which store is the customer making the payment to?"}
          options={storeList}
          idKey="id"
          nameKey="name"
          isRequired={true}
          onChange={(value) => {
            setFundDetails((prev) => ({ ...prev, store_id: value }));
          }}
          value={"Select store"}
          classNames="store-select"
          errorMessage={errors.store}
        />

        <primaryComponents.InputFieldV2
          name="amount"
          control={control}
          label={"Credit Amount"}
          isRequired={true}
          placeholder="Enter total credit amount"
          value={fundDetails?.amount}
          onChange={({ target: { value } }) =>
            setFundDetails((prev) => ({ ...prev, amount: value }))
          }
          classNames="white"
          errorMessage={
            errors.amount && {
              message: errors.amount.message,
            }
          }
          onKeyUp={() => errors.amount !== undefined && trigger("amount")}
          register={register}
        />

        <primaryComponents.SelectFieldV2
          control={control}
          name="paymentMethod"
          options={["transfer", "card", "cash"]}
          label={"Payment Method"}
          isRequired={true}
          value={fundDetails?.payment_method || "Select Payment Type"}
          onChange={(value) =>
            setFundDetails((prev) => ({ ...prev, payment_method: value }))
          }
          idKey="value"
          register={register}
          errorMessage={errors.paymentMethod}
        />

        <primaryComponents.TextAreaV2
          control={control}
          type="text"
          label={"Description"}
          name="note"
          placeholder="Add description for this fund wallet"
          value={fundDetails?.description}
          onChange={({ target: { value } }) =>
            setFundDetails((prev) => ({ ...prev, description: value }))
          }
          errorMessage={errors.note}
          onKeyUp={() => errors.note !== undefined && trigger("note")}
          register={register}
        />
      </form>

      <primaryComponents.Button
        classNames="btn btn--primary cover mt-auto"
        loadingText="Funding..."
        type={"submit"}
        form={"fundWalletForm"}
        isLoading={isFundingWallet}
        isDisabled={isFundingWallet}
      >
        Proceed
      </primaryComponents.Button>
    </div>
  );
};

export default StoreWalletFund;
