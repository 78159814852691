import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useFundStoreWalletMutation } from "../../../../hooks/api/mutations/useInventory";
import { cleanObject } from "../../../../helpers";
import { ToastContext } from "../../../../hooks/context";
import { useQueryClient } from "react-query";

const useStoreWalletFund = ({ customerDetails, setIsFundWallet }) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [fundDetails, setFundDetails] = useState({
    payment_method: "",
    description: "",
    amount: "",
    store_id: "",
    customer: customerDetails,
  });
  const currencyCode = storeList?.find(
    (item) => item?.id === fundDetails?.store_id,
  )?.currency_code;
  const fundStoreWallet = useFundStoreWalletMutation();

  const handleFundWallet = () => {
    fundStoreWallet.mutate(
      cleanObject({
        ...fundDetails,
        customer_id: fundDetails?.customer?.customerId,
      }),
      {
        onSuccess: () => {
          triggerToast(
            `A sum of ${currencyCode} ${fundDetails?.amount} has been credited to customer: (${fundDetails?.customer?.customerName}) wallet`,
            "success",
          );
          queryClient.invalidateQueries(["getStoreWalletDetails"]);
          queryClient.invalidateQueries(["getStoreWallets"]);
          setIsFundWallet(null);
        },
      },
    );
  };

  return {
    storeList,
    fundDetails,
    isFundingWallet: fundStoreWallet?.isLoading,
    setFundDetails,
    handleFundWallet,
  };
};

export default useStoreWalletFund;
