import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { getReconciliations } from "../../../../../services/inventory";
import { useStoreCurrency } from "../../../../../hooks/useStoreCurrency";
import { useSelector } from "react-redux";

const useAllReconciliations = () => {
  const queries = getUrlQuerysection();
  const [isOpenReconciliation, setIsOpenReconciliation] = useState(false);
  const [search, setSearch] = useSearchParams();
  const queryParams = new URLSearchParams(window.location.search);

  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { storeHasMultipleCurrency } = useStoreCurrency();

  const [currentStore, setCurrentStore] = useState(
    storeHasMultipleCurrency
      ? queryParams.get("store_id") || storeList[0]?.id
      : "",
  );

  useEffect(() => {
    if (storeList.length > 0 && storeHasMultipleCurrency) {
      search.set("store_id", storeList[0]?.id);
      setSearch(search, { replace: true });
    }
  }, [storeList, storeHasMultipleCurrency]);

  const {
    isLoading: isFetchingReconciliations,
    data: reconciliations,
    refetch: handleFetchReconciliations,
  } = useQuery(["getReconciliations", queries], () =>
    getReconciliations(queries).then((res) => res.data),
  );

  const filterByStoreId = (storeId) => {
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  return {
    isFetchingReconciliations,
    reconciliations: reconciliations?.data || [],
    metaDetails: reconciliations?.meta,
    filterByStoreId,
    storeList,
    storeHasMultipleCurrency,
    currentStore,
    setCurrentStore,
    isOpenReconciliation,
    handleFetchReconciliations,
    setIsOpenReconciliation,
    gotoPage,
  };
};
export default useAllReconciliations;
