import "./UpdateCreditWidget.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdateCreditWidgetLogic from "./UpdateCreditWidgetLogic";
import { formatCurrency } from "../../../helpers";
// import { Controller } from "react-hook-form";
import primaryComponents from "../../primaryComponents";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoaderHelper from "../LoaderHelper/LoaderHelper";

const UpdateCreditWidget = ({ closeWidget, customer, onSuccess }) => {
  const {
    store,
    setStore,
    storeList,
    amount,
    setAmount,
    isSubmitting,
    paymentMethod,
    setPaymentMethod,
    makeRepayment,
    bankAccount,
    isLoadingBankAccountsList,
    setAccount,
  } = UpdateCreditWidgetLogic({ closeWidget, customer, onSuccess });
  const [paymentType] = useState("partial");
  const { allow_ledger_account } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <div className="update-credit">
      <FontAwesomeIcon
        onClick={closeWidget}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "25px", cursor: "pointer" }}
      />
      <div className="update-credit__title">Repayment</div>
      <div className="update-credit__subtitle">
        Record payments made by your debtors
      </div>

      <dl className="update-credit__data mt-4">
        <dt className="outstanding">Outstanding Balance</dt>
        <dd className="outstanding">
          {formatCurrency({
            value: customer.outstanding_balance,
            currencyCode: customer.store?.currency_code,
          })}
        </dd>

        <dt>Customer Name</dt>
        <dd>{customer.full_name}</dd>

        <dt>Total Credit</dt>
        <dd>
          {formatCurrency({
            value: customer.total_credit,
            currencyCode: customer.store?.currency_code,
          })}
        </dd>

        <dt>Total Repayment</dt>
        <dd>
          {formatCurrency({
            value: customer.total_repayment,
            currencyCode: customer.store?.currency_code,
          })}
        </dd>

        <dt>Status</dt>
        <dd>
          <span style={{ fontSize: "10px" }} className="success--squared ml-3">
            Active
          </span>
        </dd>
      </dl>

      <div className="update-credit__payment-form">
        <primaryComponents.InputField
          label={paymentType === "partial" ? "Amount" : "Full payment"}
          isRequired={true}
          value={amount}
          onChange={({ target }) => setAmount(target.value)}
          isDisabled={paymentType === "partial" ? false : true}
        ></primaryComponents.InputField>

        <div className="py-3 px-3 mt-2 border rounded">
          <primaryComponents.SelectField
            label={"Which store is the customer making the payment to?"}
            options={storeList}
            idKey="id"
            nameKey="name"
            onChange={(value) => {
              setStore(value);
            }}
            value={store || "Select store"}
            classNames="store-select"
          />

          <primaryComponents.SelectField
            options={["transfer", "card", "cash"]}
            label={"Payment Method"}
            isRequired={true}
            value={paymentMethod || "Select Payment Type"}
            onChange={(value) => setPaymentMethod(value)}
          ></primaryComponents.SelectField>

          {allow_ledger_account ? (
            <div className="">
              <LoaderHelper
                isLoading={isLoadingBankAccountsList}
                className="mt-1"
              >
                <primaryComponents.SelectField
                  options={bankAccount?.data?.map((bank) => ({
                    id: bank?.id,
                    name: bank?.account_name,
                  }))}
                  label={"Receive Repayment on"}
                  idKey="id"
                  nameKey="name"
                  value={
                    bankAccount
                      ? bankAccount?.data[0]?.account_name
                      : "Select account to record this expense"
                  }
                  onChange={(value) => {
                    setAccount(value);
                  }}
                  floatError={false}
                  classNames="bank-name"
                  isSearchable={false}
                />{" "}
              </LoaderHelper>
            </div>
          ) : null}
        </div>
      </div>

      <div className="py-5">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isSubmitting}
          loadingText="Updating..."
          isDisabled={isSubmitting}
          onClick={() => makeRepayment()}
        >
          Update
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default UpdateCreditWidget;
