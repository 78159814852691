/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from "./SalesTransactions.module.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { useSalesTransactions } from "./useSalesTransactions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { ReturnIcon } from "../../../../assets/svgIcons";

const SalesTransactions = () => {
  const {
    isFetchingSalesTransactions,
    storeList,
    currentStore,
    metaDetails,
    salesTransactions,
    search,
    initiator,
    orderByStatus,
    userList,
    isLoadingUsers,
    isModal,
    setIsModal,
    setOrderByStatus,
    setInitiator,
    filterByInitiator,
    filterOrderByStatus,
    handleFetchSalesTransactions,
    filterHistoryByStore,
    setCurrentStore,
    gotoPage,
    searchSalesTransactions,
    filterByStatus,
  } = useSalesTransactions();
  const { role: business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const isWayBillUser = business_role === "WAYBILL_MANAGER";

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Sales Transactions"
      description="View all sales records"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${styles.salesTransactions} ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Sales Transaction</h4>
            <p>
              View, filter, and generate reports and invoices for your sales
              transactions.
            </p>
          </div>

          <div className={globalStyles.rowNavigations}>
            <a
              className={`${globalStyles.rowNavigations__item} ${
                search.get("order_source") === "INTERNAL"
                  ? globalStyles.active
                  : ""
              }`}
              onClick={() => filterByStatus("INTERNAL", true)}
            >
              Instore Sales
            </a>

            <a
              className={`${globalStyles.rowNavigations__item} ${
                search.get("order_source") === "MENU" ? globalStyles.active : ""
              }`}
              onClick={() => filterByStatus("MENU", true)}
            >
              Storefront Orders
            </a>
          </div>
          <div className={styles.salesTransactions__header}>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_generate_end_of_day"
            >
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() =>
                  setIsModal((prev) => ({ ...prev, isEndOfDay: true }))
                }
              >
                <FontAwesomeIcon icon="download" className="mr-2" />
                Export End Of Day
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_return_products"
            >
              <primaryComponents.Button
                classNames="btn btn--orange"
                onClick={() =>
                  setIsModal((prev) => ({ ...prev, isReturn: true }))
                }
              >
                <ReturnIcon />
                <span className="ml-2">Returns</span>
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          <div className={styles.salesTransactions__content}>
            <div className={globalStyles.tableHeader}>
              <primaryComponents.SearchField
                classNames={globalStyles.searchField}
                placeholder="Search for a transaction by (Order Number, Transaction Ref)"
                trigger={(value) => searchSalesTransactions(value)}
                value={search.get("query") !== null ? search.get("query") : ""}
                triggerOnInput={true}
                showsearchBtn={true}
              />
              <div style={{ width: "160px" }}>
                <primaryComponents.SelectField
                  options={[
                    {
                      name: "All stores",
                      id: "ALL_STORES",
                    },
                    ...storeList,
                  ]}
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    setCurrentStore(value);
                    filterHistoryByStore(value);
                  }}
                  value={
                    storeList?.find(
                      (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                    )?.name || "All stores"
                  }
                  floatError={false}
                  classNames="store-select"
                />
              </div>
              <div style={{ width: "160px" }}>
                <secondaryComponents.LoaderHelper isLoading={isLoadingUsers}>
                  <primaryComponents.SelectField
                    options={[
                      { first_name: "All Initiators", id: "ALL_INITIATORS" },
                      ...userList,
                    ]}
                    idKey="id"
                    nameKey="first_name"
                    onChange={(value) => {
                      setInitiator(value);
                      filterByInitiator(value);
                    }}
                    value={
                      userList?.find((item) => item?.id === initiator)
                        ?.first_name || "Select Initiator"
                    }
                    floatError={false}
                    classNames="store-select"
                  />
                </secondaryComponents.LoaderHelper>
              </div>
              <div style={{ width: "160px" }}>
                <primaryComponents.SelectField
                  value={orderByStatus || "Status"}
                  options={[
                    { label: "All", value: "" },
                    { label: "Abandoned cart", value: "PLACED" },
                    { label: "In Progress", value: "IN_PROGRESS" },
                    { label: "Pending", value: "PENDING" },
                    { label: "Completed", value: "COMPLETED" },
                    { label: "Shipped", value: "SHIPPED" },
                    { label: "Delivered", value: "DELIVERED" },
                    { label: "Returned", value: "RETURNED" },
                    { label: "Canceled", value: "CANCELED" },
                  ]}
                  onChange={(value, label) => {
                    if (value === "Status") return;
                    setOrderByStatus(label);
                    filterOrderByStatus(value);
                  }}
                  nameKey="label"
                  idKey="value"
                  floatError={false}
                />
              </div>
            </div>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingSalesTransactions}
              classNames="mt-5"
            >
              <secondaryComponents.SalesTransactionsTable
                metaDetails={metaDetails}
                salesTransactions={salesTransactions}
                handleFetchSalesTransactions={handleFetchSalesTransactions}
                gotoPage={gotoPage}
                showpricing={!isWayBillUser}
              />
            </secondaryComponents.LoaderHelper>
          </div>

          {/* export end of day widget */}
          <secondaryComponents.SideModal
            isActive={isModal?.isEndOfDay}
            closeModal={() =>
              setIsModal((prev) => ({ ...prev, isEndOfDay: false }))
            }
          >
            <secondaryComponents.SalesEndOfDay
              closeWidget={() =>
                setIsModal((prev) => ({ ...prev, isEndOfDay: false }))
              }
            />
          </secondaryComponents.SideModal>
          {/* return widget */}
          <secondaryComponents.SideModal
            isActive={isModal?.isReturn}
            closeModal={() =>
              setIsModal((prev) => ({ ...prev, isReturn: false }))
            }
          >
            <secondaryComponents.ReturnStockWidget
              closeWidget={() =>
                setIsModal((prev) => ({ ...prev, isReturn: false }))
              }
            />
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default SalesTransactions;
