import { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  useSearchParams,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { ToastContext, platformHandler } from "../../../../../hooks/context";
import {
  getMenuProducts,
  addMenu,
  getMenuDetails,
  editMenu,
  getMenuCategoriesList,
  orderMenuCategoryList,
  getPaymentGateways,
} from "../../../../../services/inventory";
import { getPlatform } from "../../../../../helpers";
import { pathConstants } from "../../../../../routes/pathContants";

const menuProductRestructure = (productList) => {
  const newProductList = [];
  productList?.forEach((productItem) => {
    if (productItem?.variants?.length > 0) {
      productItem?.variants?.forEach((productVariantItem) => {
        newProductList?.push({
          name: `${productItem?.name} -- (${productVariantItem?.option}: ${productVariantItem?.value})`,
          product_id: productItem?.id,
          variant_id: productVariantItem?.id,
          category_name: productItem?.category?.name,
          pricings: productVariantItem?.store_variant_properties?.[0]?.pricings,
          selling_price:
            productVariantItem?.store_variant_properties?.[0]?.pricings[0]
              ?.price,
          stock_quantity:
            productVariantItem?.store_variant_properties?.[0]?.stock_quantity,
          minimum_stock_quantity:
            productVariantItem?.store_variant_properties?.[0]
              ?.minimum_stock_quantity,
          is_service: productItem?.is_service,
          isSelected: false,
        });
      });
    } else {
      const productType = productItem?.is_service
        ? "store_service_properties"
        : "store_product_properties";
      newProductList?.push({
        name: productItem?.name,
        product_id: productItem?.id,
        category_name: productItem?.category?.name,
        pricings: productItem?.[productType]?.[0]?.pricings,
        selling_price: productItem?.[productType]?.[0]?.pricings[0]?.price,
        stock_quantity: productItem?.[productType]?.[0]?.stock_quantity,
        minimum_stock_quantity:
          productItem?.[productType]?.[0]?.minimum_stock_quantity,
        is_service: productItem?.is_service,
        isSelected: false,
      });
    }
  });
  return newProductList;
};

const prevMenuProductRestructure = (menuList) => {
  const newProductList = [];
  menuList?.forEach((productItem) => {
    newProductList?.push({
      name: productItem?.product_name,
      product_id:
        productItem?.product_type === "product"
          ? productItem?.product?.id
          : productItem?.service?.id,
      variant_id: productItem?.variant?.id || undefined,
      pricings: productItem?.store_properties?.pricings,
      category_name:
        productItem?.product_type === "product"
          ? productItem?.product?.category?.name
          : productItem?.service?.category?.name,
      pricing_type_id: productItem?.pricing_type
        ? productItem?.pricing_type.id
        : undefined,
      selling_price: productItem?.pricing_type
        ? productItem?.store_properties?.pricings.find(
            (item) => item.pricing_type_id === productItem?.pricing_type.id,
          )?.price
        : productItem?.store_properties?.pricings[0]?.price,
      stock_quantity: productItem?.store_properties?.stock_quantity,
      minimum_stock_quantity:
        productItem?.store_properties?.minimum_stock_quantity,
      is_service: productItem?.product_type === "product" ? false : true,
      isSelected: true,
    });
  });
  return newProductList;
};

export const useCreateFoodMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const [menuStage, setMenuStage] = useState("Storefront Details");
  const [createdMenuId, setCreatedMenuId] = useState(null);
  const [sortMenuCategories, setSortMenuCategories] = useState([]);
  const [menuDetails, setMenuDetails] = useState({
    menuName: "",
    store: searchParams.get("store_id") || "",
  });
  const [selectedMenuItems, setSelectedMenuItems] = useState({});
  const [selectedPricingType, setSelectedPricingType] = useState("");

  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const { ref: autoScrollRef, inView } = useInView();
  const store_id = searchParams.get("store_id");
  const editMenuTab = searchParams.get("edit");
  const sortMenuTab = searchParams.get("sort");
  const paymentMenuTab = searchParams.get("payment");
  const logisticsMenuTab = searchParams.get("logistics");
  const { menuId } = useParams();

  const { data: dataPaymentMethods } = useQuery(
    ["payment-gateways"],
    () => getPaymentGateways().then((res) => res.data?.data),
    { staleTime: Infinity },
  );

  const { isLoading: isFetchingMenuProperties, data: menuProperties } =
    useQuery(
      ["getMenusDetails", [menuId, createdMenuId]],
      () =>
        getMenuDetails(menuId ? menuId : createdMenuId).then((res) => {
          return {
            ...res?.data?.data,
            menu_items: prevMenuProductRestructure(
              res?.data?.data?.menu_items.filter(
                (item) => item.store_properties,
              ),
            ),
          };
        }),
      {
        enabled: menuId || createdMenuId ? true : false,
      },
    );

  const {
    isLoading,
    data,
    isFetchingNextPage,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ["getMenuProducts", [store_id, menuId, searchParams.get("query")]],
    ({ pageParam = 1 }) =>
      getMenuProducts({
        store_id: store_id,
        page: pageParam,
        query: searchParams.get("query"),
      }).then((res) => {
        return {
          data: menuProductRestructure(res?.data?.data),
          meta: res?.data?.meta,
        };
      }),
    {
      enabled: store_id !== null,
      // staleTime: Infinity,
      getNextPageParam: ({ meta: { next_page } }) =>
        next_page === null ? undefined : next_page,
    },
  );

  const products = new Map();
  // eslint-disable-next-line array-callback-return
  data?.pages?.map((page) => {
    products.set(`${page.meta.current_page}`, page?.data);
  });
  const productsList = [].concat(...products.values());

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (menuId) {
      refetch();
    }
  }, [menuId]);

  const sucessResponseAction = (data) => {
    triggerToast(data?.detail, "success");
    queryClient.invalidateQueries(["getMenuProducts"]);
    queryClient.invalidateQueries(["getMenusDetails"]);
    queryClient.invalidateQueries(["getViewMenuList"]);
    queryClient.invalidateQueries(["getMenus"]);
    setCreatedMenuId(data?.data?.id);
    if (pathname.includes("create")) {
      navigate(
        pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_CHECKOUT({
          menuId: data?.data?.id,
        }) + `?store_id=${store_id}`,
      );
    } else {
      navigate(-1);
    }
  };

  const { isLoading: isLoadingSortCategories, mutate: mutateSortCategories } =
    useMutation({
      mutationFn: (data) =>
        orderMenuCategoryList({ id: createdMenuId || menuId, data }),
      onSuccess: () => {
        refetchMenuCategories();
        triggerToast("Categories sorted successfully", "success");
        if (menuId) {
          navigate(-1);
        } else {
          if (dataPaymentMethods && dataPaymentMethods.length > 0) {
            navigate(getPlatform(platform) + "/inventory/storefront");
          } else {
            setMenuStage("Payment Setup");
          }
        }
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  const createMenuMutation = useMutation({
    mutationKey: ["createMenu"],
    mutationFn: (data) => addMenu(data),
    onSuccess: (res) => sucessResponseAction(res?.data),
  });

  const editMenuMutation = useMutation({
    mutationKey: ["editMenu"],
    mutationFn: (data) => editMenu({ data, menuId: menuId || createdMenuId }),
    onSuccess: (res) => sucessResponseAction(res?.data),
  });

  const {
    isLoading: isFetchingMenuCategories,
    refetch: refetchMenuCategories,
  } = useQuery(
    ["getMenuCategoriesList", [createdMenuId || menuId, editMenuMutation.data]],
    () =>
      getMenuCategoriesList({ id: createdMenuId || menuId }).then((res) =>
        setSortMenuCategories(res?.data?.data),
      ),
    {
      enabled: createdMenuId || menuId ? true : false,
    },
  );

  console.log(createdMenuId);
  console.log(menuId);

  const onSubmit = () => {
    if (Object.keys(selectedMenuItems)?.length === 0)
      return triggerToast(
        "You haven't selected any item(s). Please select at least one item to continue",
      );

    if (menuId === undefined && createdMenuId === null) {
      createMenuMutation.mutate({
        name: menuDetails?.menuName,
        store: menuDetails?.store,
        menu_items: serializeMenuItems(selectedMenuItems),
      });
    } else {
      editMenuMutation.mutate({
        name: menuDetails?.menuName,
        store: menuDetails?.store,
        menu_items: serializeMenuItems(selectedMenuItems),
      });
    }
  };

  const filterProductByStore = (storeId) => {
    searchParams.set("store_id", storeId);
    setSearchParams(searchParams, { replace: true });
  };

  const handleSearchMenuItems = (query) => {
    if (query !== "") searchParams.set("query", query);
    else searchParams.delete("query");
    setSearchParams(searchParams, { replace: true });
  };

  const serializeMenuItems = (items) => {
    const newData = [];
    for (const key in items) {
      if (items?.[key]?.is_service) {
        newData.push({
          service_id: items?.[key]?.product_id,
          variant_id: items?.[key]?.variant_id || undefined,
          pricing_type_id:
            selectedPricingType &&
            items?.[key]?.pricings?.find(
              (item) => item?.pricing_type.id === selectedPricingType.id,
            )
              ? selectedPricingType.id
              : items?.[key]?.pricing_type_id
                ? items?.[key]?.pricing_type_id
                : 1,
        });
      } else {
        newData.push({
          product_id: items?.[key]?.product_id,
          variant_id: items?.[key]?.variant_id || undefined,
          pricing_type_id:
            selectedPricingType &&
            items?.[key]?.pricings?.find(
              (item) => item?.pricing_type.id === selectedPricingType.id,
            )
              ? selectedPricingType.id
              : items?.[key]?.pricing_type_id
                ? items?.[key]?.pricing_type_id
                : 1,
        });
      }
    }
    return newData;
  };

  return {
    store_id,
    storeList,
    data,
    selectedMenuItems,
    isCreatingMenu:
      createMenuMutation.isLoading ||
      editMenuMutation?.isLoading ||
      isLoadingSortCategories,
    searchParams,
    isFetchingProducts: isLoading || isFetchingMenuProperties,
    isFetchingNextPage,
    isFetchingMenuCategories,
    isLoadingSortCategories,
    menuDetails,
    productsList: productsList || [],
    menuProperties,
    menuId,
    editMenuTab,
    sortMenuTab,
    paymentMenuTab,
    logisticsMenuTab,
    menuStage,
    sortMenuCategories,
    selectedPricingType,
    setSelectedPricingType,
    autoScrollRef,
    setSelectedMenuItems,
    onSubmit,
    filterProductByStore,
    handleSearchMenuItems,
    setMenuDetails,
    setMenuStage,
    setSortMenuCategories,
    mutateSortCategories,
  };
};
