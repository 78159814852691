import secondaryComponents from "..";
import useShippingsListLogic from "./useShippingsListLogic";
import { EmptyShippingIcon } from "../../../assets/svgIcons";

const AllShippingsList = ({
  shippings,
  metaDetails,
  gotoPage,
  handleFetchShippings,
}) => {
  const {
    instance,
    selectedShipping,
    setSelectedShipping,
    shippingInfo,
    isFetchingShippingInfo,
    selectedDeleteShipping,
    setSelectedDeleteShipping,
  } = useShippingsListLogic({
    shippings,
    handleFetchShippings,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5">
          <EmptyShippingIcon />
          <h5>You have no Shipping zone/groups</h5>
          <p>
            Use the button above to add your shipping zone/groups. When you do,
            they will appear here.
          </p>
        </div>
      </secondaryComponents.TableHelper>
      {/* <secondaryComponents.Pagination
        totalPageNumber={metaDetails?.last_page}
        handlePageClick={(value) => gotoPage(value?.selected + 1)}
        currentPage={metaDetails?.current_page}
      /> */}

      {/* update shipping zone */}
      <secondaryComponents.LoaderHelper isLoading={isFetchingShippingInfo}>
        <secondaryComponents.Modal
          isActive={!!selectedShipping}
          closeModal={() => setSelectedShipping(null)}
          width={850}
        >
          <secondaryComponents.CreateShippingForm
            closeWidget={() => setSelectedShipping(null)}
            refetch={handleFetchShippings}
            isEditMode={true}
            shippingInfo={shippingInfo}
          />
        </secondaryComponents.Modal>
      </secondaryComponents.LoaderHelper>

      {/* delete modal */}
      <secondaryComponents.LoaderHelper isLoading={isFetchingShippingInfo}>
        <secondaryComponents.Modal
          isActive={selectedDeleteShipping !== null}
          closeModal={() => setSelectedDeleteShipping(null)}
          width={500}
        >
          <secondaryComponents.CustomerDeletion
            closeWidget={() => setSelectedDeleteShipping(null)}
            customerDetails={shippingInfo}
            handleFetchCustomers={handleFetchShippings}
            type="shipping"
          />
        </secondaryComponents.Modal>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default AllShippingsList;
