import secondaryComponents from "..";
import { BankEmptyIcon } from "../../../assets/svgIcons";
import useAccountingsListLogic from "./useAllBankTransactionLogic";

const AllBankTransactionTable = ({ banks, handleFetchbanks, type }) => {
  const { instance } = useAccountingsListLogic({
    banks,
    handleFetchbanks,
    type,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5">
          <BankEmptyIcon />
          {type === "cash" ? (
            <h5>You have no transactions</h5>
          ) : (
            <h5>You have no transactions</h5>
          )}
          <p>When you do, they would appear here.</p>
        </div>
      </secondaryComponents.TableHelper>
      {/* <secondaryComponents.Pagination
        totalPageNumber={metaDetails?.last_page}
        handlePageClick={(value) => gotoPage(value?.selected + 1)}
        currentPage={metaDetails?.current_page}
      /> */}
      {/* delete modal */}
    </div>
  );
};

export default AllBankTransactionTable;
