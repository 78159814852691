import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPaymentMethods,
  menuPayInstoreDelivery,
  menuProcessPayment,
} from "../../../../../services/inventory";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../../../../hooks/context";
import { clearAllMenuCartItems } from "../../../../../store/modules/menuCart";
import { pathConstants } from "../../../../../routes/pathContants";

const usePayForOrder = ({
  // selectedOrderType,
  setShowPayForOrder,
  setShowCartItems,
  menuObject,
}) => {
  const dispatch = useDispatch();
  const triggerToast = useContext(ToastContext);
  const { menuCartItems } = useSelector((state) => state.menuCartReducer);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [preferredPaymentMethod, setPreferredPaymentMethod] = useState(null);
  const navigate = useNavigate();

  const {
    isLoading: isLoadingPayInstoreDelivery,
    mutate: mutatePayInstoreDelivery,
  } = useMutation({
    mutationFn: (data) => menuPayInstoreDelivery(data),
    onSuccess: (data) => {
      triggerToast(data.data.detail, "success");
      if (window.innerWidth > 850) {
        setShowCartItems(false);
      }
      dispatch(clearAllMenuCartItems());
      const orderId = localStorage.getItem("order_id");
      localStorage.removeItem("order_id");
      setShowPayForOrder(false);

      navigate(
        pathConstants.INVENTORY_STOREFRONT_ORDER_STATUS({
          menuId: menuObject?.id,
          id: orderId,
        }),
      );
    },
  });

  const { isLoading: isLoadingProcessPayment, mutate: mutateProcessPayment } =
    useMutation({
      mutationFn: (data) => menuProcessPayment(data),
      onSuccess: (data) => {
        window.location.href = data?.data?.data?.init_data?.url;
      },
    });

  const {
    isLoading: isLoadingPaymentGateways,
    mutate: mutatePaymentGateway,
    data: dataPaymentGateways,
  } = useMutation({
    mutationFn: (data) => fetchPaymentMethods(data),
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const handleProcessPayment = () => {
    // if (!paymentMethod && selectedOrderType !== "DELIVERY") {
    //   return triggerToast(
    //     "Choose your preferred payment method to make payment",
    //     "warning",
    //   );
    // }

    if (menuObject?.id) {
      const guest_token = localStorage.getItem("guest_token");
      const order_id = localStorage.getItem("order_id");

      if (preferredPaymentMethod === "Pay Online") {
        mutateProcessPayment({
          guest_token,
          order_id,
          menu_id: menuObject.id,
          callback_url:
            window.location.origin +
            pathConstants.INVENTORY_STOREFRONT_ORDER_STATUS({
              menuId: menuObject.id,
              id: order_id,
            }),
          merchant_token: paymentMethod.merchant_token,
          payment_method: paymentMethod.type,
        });
      } else if (preferredPaymentMethod === "Pay In-store") {
        mutatePayInstoreDelivery({
          guest_token,
          order_id,
          menu_id: menuObject.id,
          payment_point: "IN_STORE",
        });
      } else {
        mutatePayInstoreDelivery({
          guest_token,
          order_id,
          menu_id: menuObject.id,
          payment_point: "ON_DELIVERY",
        });
      }
    }
  };

  useEffect(() => {
    if (dataPaymentGateways?.data?.data?.length === 1) {
      setPaymentMethod(dataPaymentGateways?.data?.data?.[0]);
    }
  }, [dataPaymentGateways]);

  useEffect(() => {
    if (menuObject?.id) {
      mutatePaymentGateway({ menu_id: menuObject.id });
    }
  }, [menuObject]);

  return {
    isLoadingPayInstoreDelivery,
    isLoadingProcessPayment,
    isLoadingPaymentGateways,
    dataPaymentGateways,
    menuCartItems,
    paymentMethod,
    preferredPaymentMethod,
    store: menuObject?.store,
    menuObject,
    setPaymentMethod,
    setPreferredPaymentMethod,
    handleProcessPayment,
  };
};

export default usePayForOrder;
