import "./InputField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolTipV2 from "../../secondaryComponents/ToolTipV2";
import { ExclamationCirlce } from "../../../assets/svgIcons";

const InputField = ({
  placeholder,
  type = "text",
  name = "",
  isRequired = false,
  label = null,
  labelIcon = <ExclamationCirlce />,
  labelTootipText = "",
  labelStyles = {},
  labelClassNames = "",
  showLabelIcon = false,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  register = () => {},
  onKeyUp = () => {},
  classNames = "",
  floatError = true,
  inputIcon = null,
  ...props
}) => {
  return (
    <div className="input-field" htmlFor={name}>
      {label !== null && (
        <label
          htmlFor={name}
          data-testid="label"
          className={`${labelClassNames} d-flex`}
          style={{ ...labelStyles }}
        >
          {isRequired && <span className="text-danger">*</span>}
          <span>
            <span>{label}</span>
          </span>
          <ToolTipV2 tipText={labelTootipText}>
            {showLabelIcon ? <span className="ml-2">{labelIcon}</span> : null}
          </ToolTipV2>
        </label>
      )}
      <div
        className={` ${inputIcon === null ? "" : classNames} ${
          inputIcon !== null ? "inputCover" : ""
        } ${isDisabled ? "isDisabled" : ""}  ${
          errorMessage !== undefined ? "error-border" : ""
        }`}
      >
        {inputIcon !== null ? <img src={inputIcon} alt="input-icon" /> : null}
        <input
          data-testid="input"
          className={`${inputIcon === null ? classNames : ""} ${
            errorMessage !== undefined && "error-border"
          }`}
          type={type}
          name={name}
          placeholder={placeholder}
          id={name}
          {...register(name, { required: isRequired })}
          onChange={(e) => {
            onChange(e);
          }}
          disabled={isDisabled}
          onKeyUp={onKeyUp}
          {...props}
        />
      </div>
      {floatError || errorMessage !== undefined ? (
        <p
          className={`error-message text-danger ${
            errorMessage !== undefined ? "show" : ""
          }`}
          data-testid="error-message"
        >
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            className="mr-2"
            style={{ fontSize: "13px" }}
          />
          {errorMessage !== undefined && <span>{errorMessage?.message}</span>}
        </p>
      ) : null}
    </div>
  );
};

export default InputField;
