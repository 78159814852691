import { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { formatCurrency, truncateString } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import styles from "./ReconcileStockEditView.module.scss";

const useReconcileStockEditView = ({
  store_currency,
  products,
  setReconciliedData,
  discrepancyReasons,
  isReviewMode,
}) => {
  const [productsList, setProductsList] = useState(products);

  useEffect(() => {
    const newProductList = [...productsList];
    for (let index = newProductList?.length; index < products.length; index++)
      newProductList.push(products[index]);
    setProductsList(newProductList);
  }, [products?.length]);

  const updateChanges = (position, newData) => {
    setReconciliedData((prevState) => ({
      ...prevState,
      [position]: newData,
    }));
  };

  const removeReconciledKey = (position) => {
    setReconciliedData((prevState) => {
      const copy = { ...prevState };
      delete copy[position];
      return copy;
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product_name",
        filterable: true,
        Cell: ({ cell: { value } }) =>
          truncateString(value, 500) ? value : "N/A",
      },
      {
        Header: "Variant",
        accessor: "variant_name",
        Cell: ({ cell: { value } }) =>
          truncateString(value, 200) ? value : "N/A",
      },
      {
        Header: "System count",
        accessor: "system_count",
      },
      {
        Header: "Physical Count",
        accessor: "physical_count",
        Cell: ({ row, cell: { value } }) => (
          <primaryComponents.InputField
            classNames={`white ${styles.inputField}`}
            placeholder="Enter value"
            floatError={false}
            type="number"
            min="0"
            value={value === null ? "" : value}
            name={`physicalCount${row?.index}`}
            isDisabled={isReviewMode}
            onChange={(e) => {
              const newProductList = [...instance?.data];
              newProductList[row?.index].physical_count =
                e?.target?.value === "" || Math.sign(e?.target?.value) === -1
                  ? null
                  : e?.target?.value;
              setProductsList(newProductList);
              e?.target?.value !== ""
                ? updateChanges(row?.index, newProductList?.[row?.index])
                : removeReconciledKey(row?.index);
            }}
          />
        ),
      },
      {
        Header: "Discrepancies",
        accessor: "discrepancy_count",
        Cell: ({ row }) =>
          row?.original?.system_count === row?.original?.physical_count ||
          row?.original?.physical_count === null ? (
            <span className="success--squared">0</span>
          ) : (
            <span
              className={`${
                row?.original?.physical_count - row?.original?.system_count < 0
                  ? "failed--text"
                  : "success--squared"
              }`}
            >
              {row?.original?.physical_count - row?.original?.system_count}
            </span>
          ),
      },
      {
        Header: "Estimated Impact",
        accessor: "discrepancy_impact",
        Cell: ({ row }) =>
          row?.original?.system_count === row?.original?.physical_count ||
          row?.original?.physical_count === null ||
          row?.original?.physical_count === "" ? (
            <span className="success--squared">
              {formatCurrency({ value: 0, currencyCode: store_currency })}
            </span>
          ) : (
            <span
              className={`${
                row?.original?.physical_count - row?.original?.system_count < 0
                  ? "failed--text"
                  : "success--squared"
              }`}
            >
              {formatCurrency({
                value:
                  (row?.original?.physical_count -
                    row?.original?.system_count) *
                  row?.original?.cost_price,
                currencyCode: store_currency,
              })}
            </span>
          ),
      },
      {
        Header: "Discrepancy Reason",
        accessor: "discrepancy_reason_label",
        Cell: ({ row, cell: { value } }) => (
          <primaryComponents.SelectField
            classNames={`white ${styles.inputField}`}
            floatError={false}
            options={discrepancyReasons}
            isSearchable={false}
            value={value ? value : "Select"}
            nameKey="title"
            idKey="id"
            isDisabled={
              row?.original?.physical_count === null ||
              row?.original?.physical_count === "" ||
              isReviewMode
            }
            onChange={(value) => {
              const newProductList = [...instance?.data];
              newProductList[row?.index].discrepancy_reason_id = value;
              setProductsList(newProductList);
              updateChanges(row?.index, newProductList?.[row?.index]);
            }}
          />
        ),
      },
      {
        Header: "Last Upadted By",
        accessor: "last_updated_by_label",
        Cell: ({ cell: { value } }) =>
          value ? truncateString(value, 50) : "N/A",
      },
    ],
    [store_currency],
  );

  const instance = useTable({ columns, data: productsList });

  return {
    instance,
  };
};
export default useReconcileStockEditView;
